import React, { Component } from "react"
import { Modal, Button, Form } from "antd"
import AddUserForm from "./AddUserForm"


const CollectionCreateForm = Form.create({ name: 'create_update_user' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { showModal, isSubmit, handleCancel, handleOk, form, isUpdate, onChangeEmailClick, onChangeUserNameClick, onChangeUserPermissionClick } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={showModal}
          maskClosable={false}
          title={isUpdate ? "Update User" : "Add New User"}
          onCancel={handleCancel}
          onOk={handleOk}
          footer={[
            <Button id="btn-user-cancel" key="back" onClick={handleCancel}>Cancel</Button>,
            <Button id="btn-user-save" key="submit" type="primary" loading={isSubmit} onClick={handleOk}>
              {isUpdate ? "Update User" : "Add User"}
            </Button>
          ]}
        >
          <AddUserForm
            getFieldDecorator={getFieldDecorator}
            onChangeEmailClick={onChangeEmailClick}
            onChangeUserNameClick={onChangeUserNameClick}
            onChangeUserPermissionClick={onChangeUserPermissionClick}
            {...this.props}
          />
        </Modal>
      )
    }
  }
)

export default class AddUserModal extends Component {
  render() {
    const { saveFormRef } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        {...this.props}
      />
    );
  }
}
