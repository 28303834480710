import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import App from './App'
import client from './apollo'
import * as serviceWorker from './serviceWorker'
import { AppContextProvider } from './AppContext'
import { Auth0Provider } from './modules/auth/Auth0'
import './index.css'
import { version } from '../package.json'
import { PusherProvider, pusher } from './pusher'

console.log(`Version: ${version}`)

if (process.env.REACT_APP_NODE_ENV !== "dev") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_NODE_ENV,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.']          //react-virtuso gives this error in chrome, firefox 
  })
}

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_CMS_DOMAIN_URL}
    client_id={process.env.REACT_APP_CMS_CLIENT_ID}
    audience={process.env.REACT_APP_CMS_AUDIENCE_URL}
    redirect_uri={`${process.env.REACT_APP_CMS_URL}/callback`}
    onRedirectCallback={onRedirectCallback}
  >
    <ApolloProvider client={client}>
      <AppContextProvider>
        <PusherProvider>
          <App />
        </PusherProvider>
      </AppContextProvider>
    </ApolloProvider>
  </Auth0Provider>,
  document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
