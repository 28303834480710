import React from 'react'
import { Tag } from 'antd'

export function covertToPercentage(value) {
  return (value * 100).toFixed(2)
}

export function RenderModerationTags(props) {
  const { data: { score, class: tagText } } = props

  const displayText = `${covertToPercentage(score)}% ${tagText}`

  if ((score * 100) > 90) {
    return <div><Tag color="#F5222D">{displayText}</Tag></div>
  }
  else if ((score * 100) > 80) {
    return <div><Tag color="#FC8D17">{displayText}</Tag></div>
  } else if ((score * 100) > 70) {
    return <div><Tag color="#DAAC52">{displayText}</Tag></div>
  } else {
    return <div><Tag color="#595959">{displayText}</Tag></div>
  }
}