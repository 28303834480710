import React, { useRef, useState, useEffect } from 'react'
import { Row, Col, notification, Spin, Icon, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { LIST_COMMENTS_MODERATION } from '../graphql/Queries'
import { chatClient } from '../../../../apollo'
import { debounce, cloneDeep } from 'lodash'
import * as Sentry from '@sentry/browser'
import { usePusher } from '../../../../pusher'
import { UPDATE_COMMENT_MODERATION } from '../graphql/Mutations'
import Comment from './Comment'
import { withRouter } from 'react-router-dom'
import { handleRequestFailWithNotification, openNotification } from '../../../../common/utility'
import { get } from 'lodash'
import Spinner from '../../../../components/loaders/Spinner'
import { Virtuoso } from 'react-virtuoso'

const limit = 10
const { confirm } = Modal
let scrollCommentDebounceJob;

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

function CommentList(props) {
  const { match: { params: { id } = {} } = {}, searchInput, acceptFilter, highlightFilter, hiddenFilter, dateRangeSearch, sortBy, selectComment, setSelectComment, canMutate, startTime, hasFilterApplied } = props
  const pusher = usePusher()
  const [comments, _setComments] = useState([])
  const commentsRef = useRef(comments)
  const [commentsEndReached, setCommentsEndReached] = useState(false)
  const [loadingMoreComments, setLoadingMoreComments] = useState(false)
  const [skipComment, setSkipComment] = useState(0)
  const [initialVariable, setInitialVariable] = useState({
    first: limit,
    where: {
      ...(id && { id: id }),
      ...(!id && startTime && { createdAt_lte: startTime }),
      ...(!id && searchInput !== '' && { searchTerm: searchInput }),
      ...(!id && acceptFilter && { isAccepted: acceptFilter }),
      ...(!id && highlightFilter && { isHighlighted: highlightFilter }),
      ...(!id && hiddenFilter && { isHidden: hiddenFilter }),
      ...(!id && dateRangeSearch && { createdAt_gte: dateRangeSearch[0], createdAt_lte: dateRangeSearch[1] }),
    },
    ...(!id && sortBy && { orderBy: sortBy })
  })

  useEffect(() => {        // stop call api twice
    setInitialVariable({
      first: limit,
      where: {
        ...(id && { id: id }),
        ...(!id && startTime && { createdAt_lte: startTime }),
        ...(!id && searchInput !== '' && { searchTerm: searchInput }),
        ...(!id && acceptFilter && { isAccepted: acceptFilter }),
        ...(!id && highlightFilter && { isHighlighted: highlightFilter }),
        ...(!id && hiddenFilter && { isHidden: hiddenFilter }),
        ...(!id && dateRangeSearch && { createdAt_gte: dateRangeSearch[0], createdAt_lte: dateRangeSearch[1] }),
      },
      ...(!id && sortBy && { orderBy: sortBy })
    })

  }, [id, acceptFilter, highlightFilter, hiddenFilter, dateRangeSearch, searchInput, sortBy])

  const setComments = data => {
    commentsRef.current = data;
    _setComments(data);
  }

  const {
    data,
    loading: isCommentLoading,
    error: commentsError,
    fetchMore: fetchMoreComments,
    networkStatus: commentsNetworkStatus,
  } = useQuery(LIST_COMMENTS_MODERATION, {
    client: chatClient,
    variables: initialVariable,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isCommentLoading && data?.listCommentsModeration) {
      setComments(data?.listCommentsModeration)
      if (data?.listCommentsModeration?.length > 0) {
        setSelectComment(data?.listCommentsModeration[0])
      } else {
        setSelectComment(null)
      }
    }
  }, [isCommentLoading])

  useEffect(() => {
    setCommentsEndReached(false)
    setSkipComment(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, searchInput, acceptFilter, highlightFilter, hiddenFilter, dateRangeSearch, sortBy])

  function handleCommentListScroll(e) {
    if (scrollCommentDebounceJob) {
      scrollCommentDebounceJob.cancel()
    }

    const { target } = e
    const { scrollTop, offsetHeight, scrollHeight } = target || {}

    scrollCommentDebounceJob = debounce(() => {
      let scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5

      if (scrolledToBottom && !commentsEndReached && scrollTop !== 0) {
        setSkipComment(skipComment + 1)
      }
    }, 300)

    scrollCommentDebounceJob()
  }

  useEffect(() => {
    if (skipComment !== 0) {
      loadMoreComments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipComment])

  async function loadMoreComments() {
    try {
      setLoadingMoreComments(true)
      const res = await fetchMoreComments({
        variables: {
          skip: skipComment * limit,
          first: 10,
          where: {
            ...(startTime && { createdAt_lte: startTime }),
            ...(searchInput && { searchTerm: searchInput }),
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(hiddenFilter && { isHidden: hiddenFilter }),
            ...(dateRangeSearch && { createdAt_gte: dateRangeSearch[0], createdAt_lte: dateRangeSearch[1] }),
          },
          ...(sortBy && { orderBy: sortBy })
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            const { listCommentsModeration: newCommentsData } = fetchMoreResult
            if (newCommentsData?.length < limit) {
              setCommentsEndReached(true)
            }
          }
        },

      })

      if (res?.data?.listCommentsModeration) {
        const { data: { listCommentsModeration: newCommentsData } = {} } = res
        if (newCommentsData?.length > 0) {
          setComments([...comments, ...newCommentsData])
        }
      }

    } catch (error) {
      handleCatchError(error)
    } finally {
      setLoadingMoreComments(false)
    }

  }

  useEffect(() => {
    const commentChannel = pusher.subscribe('COMMENT_MODERATION_EVENT');
    commentChannel.bind('UPDATED', commentUpdateHandler);
    commentChannel.bind('CREATED', commentCreateHandler);

    return () => {
      commentChannel.unbind('UPDATED', commentUpdateHandler);
      commentChannel.unbind('CREATED', commentCreateHandler);
      pusher.unsubscribe('COMMENT_MODERATION_EVENT')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const commentUpdateHandler = async (data, metaData) => {
    if (data?.comment?.node) {
      const id = commentsRef?.current?.findIndex((comment) => comment?.id === data?.comment?.node?.id)

      if (id !== -1) {
        const newComments = cloneDeep(commentsRef?.current)
        newComments[id] = { ...newComments[id], ...data?.comment?.node }
        setComments([...newComments])
      }
    }
  }

  const commentCreateHandler = async (data, metaData) => {
    if (!id && !hasFilterApplied?.current && data?.comment?.node) {
      setComments([data?.comment?.node, ...commentsRef?.current])
    }
  }

  function handleCommentActions(e, comment, fieldName) {
    e.stopPropagation()
    let value
    let title
    let okText
    let okType
    let notificationMessage
    let queryVariables = { where: { id: get(comment, 'id') } }

    switch (fieldName) {
      case 'isHighlighted':
        value = get(comment, 'isHighlighted')
        title = `Are you sure, you want to ${value ? 'unhighlight' : 'highlight'} this comment?`
        okText = value ? 'Unhighlight' : 'Highlight'
        okType = value ? 'danger' : 'primary'
        queryVariables = { ...queryVariables, data: { isHighlighted: !value } }
        notificationMessage = `Comment has been ${value ? 'unhighlighted' : 'highlighted'}`
        break;
      case 'isHidden':
        value = get(comment, 'isHidden')
        title = `Are you sure, you want to ${value ? 'unhide' : 'hide'} this comment?`
        okText = value ? 'Unhide' : 'Hide'
        okType = value ? 'primary' : 'danger'
        queryVariables = { ...queryVariables, data: { isHidden: !value } }
        notificationMessage = `Comment has been ${value ? 'unhidden' : 'hidden'}`
        break;
      case 'isAccepted':
        value = get(comment, 'isAccepted')
        title = `Are you sure, you want to ${value ? 'unaccept' : 'accept'} this comment ? `
        okText = value ? 'Unaccept' : 'Accept'
        okType = value ? 'danger' : 'primary'
        queryVariables = { ...queryVariables, data: { isAccepted: !value } }
        notificationMessage = `Comment has been ${value ? 'unaccepted' : 'accepted'} `
        break;
      default:
        break;
    }

    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          const res = await chatClient.mutate({ mutation: UPDATE_COMMENT_MODERATION, variables: queryVariables })
          if (res && get(res, 'data.updateCommentModeration')) {
            let newComments = cloneDeep(comments)
            let id = newComments?.findIndex((post) => post?.id === get(res, 'data.updateCommentModeration.id'))
            if (id !== -1) {
              if (fieldName === 'isHighlighted') {
                newComments[id].isHighlighted = !value
              } else if (fieldName === 'isHidden') {
                newComments[id].isHidden = !value
              } else if (fieldName === 'isAccepted') {
                newComments[id].isAccepted = !value
              }
            }
            setComments(newComments)
          }
          openNotification('success', notificationMessage)
        }
        catch (error) {
          handleCatchError(error)
        }
      }

    })
  }

  if (commentsError) return `Error! ${commentsError.message} `

  return (
    <Col lg={16} className="comment-list" onScroll={handleCommentListScroll}>
      {isCommentLoading
        ? <Spinner />
        : data?.listCommentsModeration && comments &&
        <Virtuoso
          className="virtuoso"
          overscan={100}
          totalCount={comments?.length}
          item={index => {
            const comment = comments?.[index]
            if (!comment) {
              return null
            }
            return <Comment
              index={index}
              key={comment?.id}
              comment={comment}
              handleCommentActions={handleCommentActions}
              selectComment={selectComment}
              setSelectComment={setSelectComment}
              canMutate={canMutate}
            />
          }}
          footer={() => {
            if (loadingMoreComments) {
              return <Row className="d-flex justify-content-center">
                <Col className="bottom-fetch-more-loader">
                  <Spin indicator={<Icon type="loading" className="moderation-spinner-size" spin />} />
                </Col>
              </Row>
            }
          }}
        />}
    </Col>
  )

}

export default withRouter(CommentList)