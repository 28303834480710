import React, { useState, useContext, useEffect, useRef } from 'react'
import { Row, Switch, DatePicker, Select, Icon, Col, Divider } from 'antd'
import Filter from '../component/Filter'
import CommentList from './components/CommentList';
import { debounce, } from 'lodash'
import Post from './components/Post'
import ParentCommentWrapper from './components/ParentCommentWrapper';
import SideCommentList from './components/SideCommentList';
import { usePusher } from '../../../pusher';
import { find } from 'lodash'
import { PERMISSION_KEYS } from '../../../common/constants'
import { AppContext } from '../../../AppContext'
import moment from 'moment';

const dateFormat = 'MM/DD/YYYY';
const { RangePicker } = DatePicker
const { Option } = Select

const SortByOptions = [
  { name: 'Most Flagged', value: 'flagCount_DESC' },
  { name: 'Moderation Score', value: 'moderationScore_DESC' },
  { name: 'Newest First', value: 'createdAt_DESC' },
  { name: 'Oldest First', value: 'createdAt_ASC' }
]

let debounceSearchJob;

export default function Comments(props) {
  const pusher = usePusher()
  const [searchInput, setSearchInput] = useState('')
  const [acceptFilter, setAcceptFilter] = useState(null)
  const [highlightFilter, setHighlightFilter] = useState(null)
  const [hiddenFilter, setHiddenFilter] = useState(null)
  const [dateRangeSearch, setDateRangeSearch] = useState(null)
  const [sortBy, setSortBy] = useState(null)
  const [selectComment, setSelectComment] = useState(null)
  const { state } = useContext(AppContext)
  const { currentPersonPermission = [] } = state
  const moderationPermission = find(currentPersonPermission, ['key', PERMISSION_KEYS.MODERATION])
  const { canMutate = false } = moderationPermission || {}
  const [startTime, setStartTime] = useState(moment())
  const hasFilterApplied = useRef(false)

  useEffect(() => {
    if(searchInput === '' && !acceptFilter && !hiddenFilter && !dateRangeSearch && !sortBy ){
      hasFilterApplied.current = false
    } else {
      hasFilterApplied.current = true
    }
  }, [searchInput, acceptFilter, hiddenFilter, dateRangeSearch, sortBy])

  const searchInputChange = (e) => {
    if (debounceSearchJob) {
      debounceSearchJob.cancel()
      debounceSearchJob = null
    }

    debounceSearchJob = debounce((searchValue) => {
      setStartTime(moment())
      setSearchInput(searchValue?.trim())
    }, 1000)

    debounceSearchJob(e?.target?.value)
  }

  const dateRangeChange = (data, dateString) => {
    setStartTime(moment())
    if (data && data.length > 1) {
      let day1 = data[0].startOf('day')
      let day2 = data[1].isSame(new Date(), 'day') ? data[1] : data[1].endOf('day')
      setDateRangeSearch([day1, day2])
    } else {
      setDateRangeSearch(null)
    }
  }

  const handleAcceptSwitch = (checked) => {
    setStartTime(moment())
    if (checked) {
      setAcceptFilter(checked)
    } else {
      setAcceptFilter(undefined)
    }
  }

  const handleHighlightSwitch = (checked) => {
    setStartTime(moment())
    if (checked) {
      setHighlightFilter(checked)
    } else {
      setHighlightFilter(undefined)
    }
  }

  const handleHiddenSwitch = (checked) => {
    setStartTime(moment())
    if (checked) {
      setHiddenFilter(checked)
    } else {
      setHiddenFilter(undefined)
    }
  }

  const sortByChange = (data) => {
    setStartTime(moment())
    setSortBy(data)
  }

  function disabledDate(current) {
    return current && current > moment()?.endOf('day');
  }

  return (
    <Row className="comment-page d-flex flex-direction-column height-100">
      <Filter searchInputChange={searchInputChange}>
        <div className="ml-auto d-flex align-items-center">
          <span><Switch id="accept-filter" size="small" onChange={handleAcceptSwitch} /> <Icon type="check" /> <span >Accept</span></span>
        </div>
        <div className="d-flex align-items-center">
          <span><Switch id="highlight-filter" size="small" onChange={handleHighlightSwitch} /> <Icon type="highlight" /> <span>Highlight</span></span>
        </div>
        <div className="d-flex align-items-center">
          <span><Switch id="hidden-filter" size="small" onChange={handleHiddenSwitch} /> <Icon type="eye-invisible" /> <span> Hidden</span></span>
        </div>
        <div>
          <RangePicker onChange={dateRangeChange} format={dateFormat} disabledDate={disabledDate} />
        </div>
        <div>
          <Select allowClear onChange={sortByChange} placeholder="Sort by">
            {SortByOptions.map((option) => <Option key={option.name} value={option.value}>{option.name}</Option>)}
          </Select>
        </div>
      </Filter>
      <Divider className="moderation-filter-divider" />
      <Col className="flex-1">
        <Row className="height-100">
          <CommentList
            searchInput={searchInput}
            acceptFilter={acceptFilter}
            highlightFilter={highlightFilter}
            hiddenFilter={hiddenFilter}
            dateRangeSearch={dateRangeSearch}
            sortBy={sortBy}
            selectComment={selectComment}
            setSelectComment={setSelectComment}
            canMutate={canMutate}
            startTime={startTime}
            hasFilterApplied={hasFilterApplied}
          />
          <Col lg={8} className="height-100">
            <div className="post-wrapper height-100">
              {selectComment && <Post selectComment={selectComment} />}
              {selectComment && <ParentCommentWrapper selectComment={selectComment} canMutate={canMutate} />}
              {selectComment && <SideCommentList selectComment={selectComment} canMutate={canMutate} />}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
