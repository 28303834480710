import React from 'react'
import { Row, Col, Avatar, Icon, Button, Divider, Tooltip, Tag, Popover } from 'antd'
import moment from 'moment'
import { capitalizeWord } from '../../../../common/utility'
import { RenderModerationTags, covertToPercentage } from '../../component/RenderModerationTags'

function LiveComment(props) {
  const { comment, handleCommentHide, handleHideAllComment, handleBlockUser, canMutate, index } = props
  let filterModeration =  comment?.moderationResponse?.classes?.filter(response => covertToPercentage(response?.score) >= 50)

  const renderActionButtons = (comment) => {
    return (
      <div className="d-flex flex-direction-column justify-content-between">
        <Button
          id={`btn-user-block-${index}`}
          className="mb-2"
          onClick={(e) => handleBlockUser(e, comment)}
        >
          Block
        </Button>
        <Button
          id={`btn-hideall-usercomments-${index}`}
          onClick={(e) => handleHideAllComment(e, comment)}
        >
          Hide All Comments
        </Button>
      </div >
    );
  };

  return (
    <Row>
      <Col className={`comment ${comment?.isDeleted ? 'is-deleted' : ''}`}>
        <Row type="flex" gutter={8}>
          <Col>
            {comment?.createdBy && <Avatar src={comment?.createdBy?.profileImage ??  ''}>{comment?.createdBy?.firstName?.charAt(0) ?? ''}</Avatar>}
          </Col>
          <Col className="align-self-center flex-1">
            {comment?.createdBy && <span className="comment-user-name">{comment?.createdBy?.firstName ?? ''} {comment?.createdBy?.lastName ?? ''}</span>}
            <span className="comment-create-date">
              {comment?.createdAt &&
                <Tooltip
                  title={
                    `${moment(comment?.createdAt).tz('America/Los_Angeles').format('Do MMMM, hh:mm A')} PST`
                  }
                >
                  {moment(comment?.createdAt).tz('America/Los_Angeles').fromNow()}
                </Tooltip>
              }
            </span>
          </Col>
          <Col className="ml-auto align-self-center">
            {canMutate &&
              <Popover id={`livecomment-action-option-${index}`} placement="bottom" content={renderActionButtons(comment)}>
                <Icon type="more" />
              </Popover>
            }
          </Col>
          <Col className="align-self-center">
            {canMutate && <Button id={`btn-livecomment-hidden-${index}`} className={comment?.isHidden ? 'moderation-action-btn' : ''} onClick={e => handleCommentHide(e, comment)} ><span ><Icon type="eye-invisible" /> {comment?.isHidden ? 'Unhide' : 'Hide'}</span></Button>}
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="comment-text live-comment-text">{comment?.comment}</p>
          </Col>
          {filterModeration?.length > 0 && <Col className="d-flex justify-space-between moderation-tag-wrapper" >
            {filterModeration?.map(response => <RenderModerationTags key={response.class} data={response} />)}
          </Col>}
          {comment?.mediaURL &&
            <Col>
              <img src={comment?.mediaURL} alt={comment?.mediaURL} className="comment-media" />
            </Col>
          }
          {comment?.giphyId &&
            <Col>
              <img src={`https://media1.giphy.com/media/${comment?.giphyId}/200.gif`} alt={comment?.giphyId} className="comment-media" />
            </Col>
          }
          <Divider className="post-page-divider" />
          <Col>
            <Row className="moderator-action-wrapper">
              <Col>
                <ul className="moderator-action-list">
                  {comment?.activityLogs?.map((log, i) => 
                    <li key={log.id}>
                      {`${log?.updatedBy && capitalizeWord(log?.updatedBy?.role)} ${log?.updatedBy?.firstName ?? ''} ${log?.updatedBy?.lastName ?? ''} ${log?.type && capitalizeWord(log?.type)} ${log?.createdAt && moment(log?.createdAt).tz('America/Los_Angeles').format('MM/DD/YYYY, h:mm a')} `}
                    </li>
                  )}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default LiveComment