import React, { useState, useEffect } from "react"
import {
  Form,
  Input,
  Skeleton,
  Select,
  Switch,
  Table,
  notification,
  Row,
  Col
} from "antd"
import { chatClient } from "../../../apollo"
import { GET_PERSON_PERMISSION, TEMPLATES } from "../graphql/Queries"
import * as Sentry from '@sentry/browser'
import { get, findIndex } from 'lodash'
import { useQuery } from "@apollo/react-hooks"
import { PERMISSION_KEYS } from "../../../common/constants"

const { Option } = Select

function openNotification(type, message) {
  notification[type]({
    message,
  })
}

function handleRequestFail(e) {
  let message = e?.graphQLErrors?.[0]?.message ?? e?.message
  if (!message) {
    message = 'Something Went Wrong'
  } 
  openNotification('error', message)  
  Sentry.captureException(e)
}

export default function (props) {
  const { person, getFieldDecorator, personPermissionData, form } = props
  const { setFieldsValue } = form
  const [templates, setTemplates] = useState([])
  const [templatesLoading, setTemplatesLoading] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(undefined)
  const [selectedPermissions, setSelectedPermissions] = useState(null)
  const [templateId, setTemplateId] = useState(undefined)
  const [permissions, setPermissions] = useState([])
  const [permissionsLoading, setPermissionsLoading] = useState(false)

  const { loading, error: templateError } = useQuery(
    TEMPLATES,
    {
      fetchPolicy: 'network-only',
      client: chatClient,
      onCompleted(data) {
        setTemplatesLoading(true)
        if (get(data, 'templates')) {
          setTemplates(get(data, 'templates'))
        }
        setTemplatesLoading(false)
        if (personPermissionData) {
          setTemplateId(personPermissionData?.templateId)
          setSelectedTemplate(personPermissionData?.templateId)
          setSelectedPermissions(personPermissionData?.permission)
        }
      },
      onError(error) {
        handleRequestFail(error)
        Sentry.captureException(error)
      }
    }
  )

  useEffect(() => {
    const index = templates?.findIndex(template => template?.id === templateId)
    if (index !== -1) {
      if (templates?.[index]?.permissions) {
        setPermissionsLoading(true)
        setPermissions([])
        setTimeout(() => {
          setPermissions(templates?.[index]?.permissions)
          setPermissionsLoading(false)
        }, 1000);
      }
    }
  }, [templateId])


  const { loading: loadingPermission, error: permissionError } = useQuery(
    GET_PERSON_PERMISSION,
    {
      variables: { where: { personId: person?.id } },
      fetchPolicy: 'network-only',
      skip: !person,
      client: chatClient,
      onCompleted(data) {
        if (get(data, 'getPersonPermission')) {
          if (get(data, 'getPersonPermission.permissionTemplate')) {
            setTemplateId(get(data, 'getPersonPermission.permissionTemplate.id'))
            setSelectedTemplate(get(data, 'getPersonPermission.permissionTemplate.id'))
          }
          if (get(data, 'getPersonPermission.permissions')?.length > 0) {
            setSelectedPermissions(get(data, 'getPersonPermission.permissions'))
          }
        }
      },
      onError(error) {
        handleRequestFail(error)
        Sentry.captureException(error)
      }
    })

  const handleTemplateChange = (value) => {
    setTemplateId(value)
  }

  const columns = [
    {
      title: 'Permission',
      key: 'id',
      render: (data, record, index) => {
        return <>
          <Form.Item className="d-none">
            {getFieldDecorator(`permission.${index}.permissionId`, {
              initialValue: get(data, 'permission.id', ''),
              rules: [{ required: true, message: "Please select the template!" }],
            })(
              <Input type="text" />
            )}
          </Form.Item>
          {get(data, 'permission.name')}
        </>
      }
    },
    {
      title: 'READ',
      key: 'canRead',
      render: (data, record, index) => {
        let selectedPermission

        if (templateId === selectedTemplate) {
          selectedPermission = selectedPermissions?.find(permission => permission?.id === data?.permission?.id)
        }

        let initialCanRead = selectedPermission ? selectedPermission?.canRead : data?.canRead

        const handleSwitchChange = (checked, e) => {
          if (!checked) {
            setFieldsValue({ [`permission.${index}.canMutate`]: false })
          }

          let key = record?.permission?.key

          if (key === PERMISSION_KEYS.SUBSCRIBERS || key === PERMISSION_KEYS.USER_MANAGMENT) {   //bind subscription and user management
            let otherKey = (key === PERMISSION_KEYS.SUBSCRIBERS) ? PERMISSION_KEYS.USER_MANAGMENT
              : (key === PERMISSION_KEYS.USER_MANAGMENT) ? PERMISSION_KEYS.SUBSCRIBERS : false
            const permissionIndex = findIndex(permissions,
              (permissionObj) => {
                return permissionObj?.permission?.key === otherKey
              }
            )
            if (permissionIndex !== -1) {
              setFieldsValue({ [`permission.${permissionIndex}.canRead`]: checked })
              if (!checked) {
                setFieldsValue({ [`permission.${permissionIndex}.canMutate`]: false })
              }
            }
          }
        }

        return <Form.Item >
          {getFieldDecorator(`permission.${index}.canRead`, {
            initialValue: initialCanRead,
            valuePropName: 'checked',
          })(<Switch onChange={handleSwitchChange} />)}
        </Form.Item>
      }
    },
    {
      title: 'CREATE/UPDATE/DELETE',
      key: 'canMutate',
      render: (data, record, index) => {
        let selectedPermission

        if (templateId === selectedTemplate) {
          selectedPermission = selectedPermissions?.find(permission => permission?.id === data?.permission?.id)
        }

        let initialCanMutate = selectedPermission ? selectedPermission?.canMutate : data?.canMutate

        const handleSwitchChange = (checked, e) => {
          if (checked) {
            setFieldsValue({ [`permission.${index}.canRead`]: true })
          }

          let key = record?.permission?.key

          if (key === PERMISSION_KEYS.SUBSCRIBERS || key === PERMISSION_KEYS.USER_MANAGMENT) {   //bind subscription and user management
            let otherKey = (key === PERMISSION_KEYS.SUBSCRIBERS) ? PERMISSION_KEYS.USER_MANAGMENT
              : (key === PERMISSION_KEYS.USER_MANAGMENT) ? PERMISSION_KEYS.SUBSCRIBERS : false
            const permissionIndex = findIndex(permissions,
              (permissionObj) => {
                return permissionObj?.permission?.key === otherKey
              }
            )
            if (permissionIndex !== -1) {
              setFieldsValue({ [`permission.${permissionIndex}.canMutate`]: checked })
              if (checked) {
                setFieldsValue({ [`permission.${permissionIndex}.canRead`]: true })
              }
            }
          }
        }

        return <div className="d-flex justify-content-center"><Form.Item>
          {getFieldDecorator(`permission.${index}.canMutate`, {
            initialValue: initialCanMutate,
            valuePropName: 'checked',
          })(<Switch onChange={handleSwitchChange} />)}
        </Form.Item>
        </div>

      }
    },
  ];

  return (
    <Form layout="vertical" className="user-permission-form">
      <Form.Item>
        {getFieldDecorator("templateId", {
          initialValue: templateId,
          rules: [{ required: true, message: "Please select the template!" }],
        })(
          <Select placeholder="Select template" loading={templatesLoading || loading} onChange={handleTemplateChange}>
            {templates?.map(template => <Option key={template?.id} value={template?.id}>{template?.name}</Option>)}
          </Select>,
        )}
      </Form.Item>
      <Table
        className="permission-table"
        columns={columns}
        dataSource={permissions}
        pagination={false}
        rowKey={(record) => record?.id}
        loading={permissionsLoading} />
    </Form >
  )
}
