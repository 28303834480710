import React, { useState, useEffect } from 'react'
import { Divider } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { chatClient } from '../../../../apollo'
import { LIST_COMMENTS_MODERATION } from '../graphql/Queries'
import { get, } from 'lodash'
import SideComment from './SideComment'
import ParentComment from './ParentComment'

function ParentCommentWrapper(props) {
  const { selectComment, canMutate } = props
  const parentId = get(selectComment, 'parent.id', null)
  const id = get(selectComment, 'id', null)

  const ref = React.useCallback(
    (node) => {
      if (node) {
        const userAgent = navigator?.userAgent
        if (userAgent?.indexOf('Edge') > 0 || userAgent?.indexOf('Chrome') > 0) {
          node.scrollIntoView(true)        //  passing  ({ behavior: 'smooth' }) is not working in chrome,edge
        } else if (userAgent?.indexOf("Firefox") > 0 || userAgent?.indexOf("MSIE") > 0 || userAgent?.indexOf('Trident')) {
          node.scrollIntoView(true, { behavior: 'smooth' })
        } else {
          node.scrollIntoViewIfNeeded()
        }
      }
    }, [id, parentId, selectComment])

  return (<>
    <div className="parent-comment-wrapper">
      {parentId && <ParentComment id={parentId} canMutate={canMutate} />}
      {id && <ParentComment id={id} canMutate={canMutate} ref={ref} />}
    </div>
    <Divider className="post-page-divider" />
  </>
  )
}

export default ParentCommentWrapper