import React from 'react'
import { Row, Icon, Col, Button, Tag, Avatar, Tooltip } from 'antd'
import moment from 'moment-timezone'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { RenderModerationTags, covertToPercentage } from '../../component/RenderModerationTags'

function Comment(props) {
  const { comment, handleCommentActions, selectComment, setSelectComment, canMutate, index } = props

  let filterModeration = comment?.moderationResponse?.classes?.filter(response => covertToPercentage(response?.score) >= 50)

  let filterMediaModeration = comment?.mediaModerationResponse?.classes?.filter(response => covertToPercentage(response?.score) >= 50)

  return (
    <Row>
      <Col>
        <Row gutter={8} id={`comment-${index}`} type="flex" className={`comment-box ${comment?.isDeleted ? 'is-deleted' : ''} ${(selectComment && (selectComment?.id === comment?.id)) ? 'selected' : ''}`} onClick={(e) => setSelectComment(comment)}>
          <Col sm={24} md={16} lg={18} className="d-flex flex-direction-column">
            <Row className="comment-details">
              {comment?.comment && <Col>
                <p className="comment-text">{comment?.comment}</p>
              </Col>}
              {filterModeration?.length > 0 && <Col className="d-flex justify-space-between moderation-tag-wrapper" >
                {filterModeration.map(response => <RenderModerationTags key={response.class} data={response} />)}
              </Col>}
              {comment?.mediaURL && <Col>
                <div className="comment-media-wrapper">
                  <img src={comment?.mediaURL} alt={comment?.mediaURL} className="comment-media" />
                </div>
              </Col>}
              {filterMediaModeration?.length > 0 && <Col className="d-flex justify-space-between moderation-tag-wrapper">
                {filterMediaModeration.map(response => <RenderModerationTags key={response.class} data={response} />)}
              </Col>}
              {comment?.giphyId && !comment.mediaURL && <Col>
                <div className="comment-media-wrapper">
                  <img src={`https://media1.giphy.com/media/${comment?.giphyId}/200.gif`} alt={comment?.giphyId} className="comment-media" />
                </div>
              </Col>}
            </Row>
            <Row type="flex" gutter={8} align="middle" className="comment-user-details" >
              <Col>
                <Row type="flex" gutter={4} align="middle">
                  {comment?.createdBy && <Col className="align-self-flexstart"><Avatar size="small" src={comment?.createdBy?.profileImage ?? ''}>{comment?.createdBy?.firstName?.charAt(0) ?? ''}</Avatar></Col>}
                  <Col className="flex-1">
                    <Row type="flex" gutter={16} align="middle">
                      <Col>
                        <Row type="flex" gutter={4} align="middle">
                          {comment?.createdBy && <Col className="comment-user-name">
                            {`${comment?.createdBy?.firstName ?? ''} ${comment?.createdBy?.lastName ?? ''}`}
                          </Col>}
                          {comment?.createdAt && <Col className="comment-create-date">
                            <Tooltip
                              title={
                                `${moment(comment?.createdAt).tz('America/Los_Angeles').format('Do MMMM, hh:mm A')} PST`
                              }
                            >
                              <span>{moment(comment?.createdAt).tz('America/Los_Angeles').fromNow()}</span>
                            </Tooltip>
                          </Col>
                          }
                        </Row>
                      </Col>
                      <Col>
                        <Row type="flex" gutter={4} align="middle" className="comment-actions-icons">
                          <Col>
                            <Icon type="flag" /> <span>{comment?.flagCount}</span>
                          </Col>
                          <Col>
                            <Icon type="like" /> <span> {comment?.likeCount}</span>
                          </Col>
                          <Col>
                            <Icon type="star" theme="filled" className="star-icon-gray" /><span> {comment?.starCount}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="comment-actions-icons">
                        <CopyToClipboard text={`${window?.location?.href}/${comment?.id}`}>
                          <Tooltip title="Copied!" trigger={"click"} onClick={(e)=> e?.stopPropagation()}>
                            <span id={`comment-copylink-${index}`}>
                              <Tooltip title="Click to copy!" placement="bottom">
                                <Icon type="link" /> <span>Copy Link</span>
                              </Tooltip>
                            </span>
                          </Tooltip>
                        </CopyToClipboard>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {canMutate &&
            <Col sm={24} md={8} lg={6} className="d-flex flex-direction-column justify-content-center comment-action-btn-container">
              <Button id={`btn-comment-highlight-${index}`} className={comment?.isHighlighted ? 'moderation-action-btn' : ''} onClick={(e) => handleCommentActions(e, comment, 'isHighlighted')}><span ><Icon type="highlight" /> Highlight</span></Button>
              <Button id={`btn-comment-hidden-${index}`} className={comment?.isHidden ? 'moderation-action-btn' : ''} onClick={e => handleCommentActions(e, comment, 'isHidden')} ><span ><Icon type="eye-invisible" /> Hidden</span></Button>
              <Button id={`btn-comment-accept-${index}`} className={comment?.isAccepted ? 'moderation-action-btn' : ''} onClick={e => handleCommentActions(e, comment, 'isAccepted')}><span ><Icon type="check" /> Accept</span></Button>
            </Col>
          }
        </Row>
      </Col>
    </Row>
  )
}

export default Comment