import gql from "graphql-tag";

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $role: Role!
  ) {
    createUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        role: $role
      }
    ) {
      id
      personId
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $firstName: String
    $lastName: String
    $role: Role
    $isActive: Boolean
    $profileImage: String
    $email: String!
  ) {
    updateUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        role: $role
        isActive: $isActive
        profileImage: $profileImage
      }
      where: { email: $email }
    ) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(where: { id: $userId }) {
      id
    }
  }
`;

export const UPDATE_PERSON = gql`
  mutation updatePerson(
    $firstName: String
    $lastName: String
    $role: Role
    $isActive: Boolean
    $profileImage: String
    $badgeId: ID
    $recurlyId: String
    $recurlyPlan: Plans
    $promoPlan: Plans
    $promoPlanExpiration: DateTime
    $email: String!
  ) {
    updatePerson(
      data: {
        firstName: $firstName
        lastName: $lastName
        role: $role
        isActive: $isActive
        profileImage: $profileImage
        badgeId: $badgeId
        recurlyId: $recurlyId
        recurlyPlan: $recurlyPlan
        promoPlan: $promoPlan
        promoPlanExpiration: $promoPlanExpiration
      }
      where: { email: $email }
    ) {
      id
      badgeId
    }
  }
`;

export const DELETE_PERSON = gql`
  mutation deletePerson($personId: ID!) {
    deletePerson(where: { id: $personId }) {
      id
    }
  }
`;

export const RESET_PASSWORD = gql`
mutation resetPassword($authId: ID!) {
  resetPassword(where:{auth0_id:$authId}){
    message
  }
}
`;

export const UPDATE_USER_EMAIL = gql`
  mutation updateEmail(
    $currentEmail: String!
    $newEmail: String!   
  ) {
    updateEmail(
      currentEmail: $currentEmail,
      newEmail: $newEmail             
    ) {
      message
    }
  }
`;

export const UPDATE_USER_NAME = gql`
  mutation updateUserName(
    $personID: ID!
    $username: String!   
  ) {
    updateUserName(
      personID: $personID,
      username: $username             
    ) {
      message
    }
  }  
`;

export const ASSIGN_PERMISSION = gql`
  mutation assignPermissions($data: assignPermissionsInputData!, $where: assignPermissionsWhereInput!) {
    assignPermissions(data: $data, where: $where){
      message
    }
  }
`;