import React, { useState } from "react"
import {
  Form,
  Input,
  Upload,
  Select,
  Icon,
  Button,
  message,
} from "antd"

const { Option } = Select

function beforeImageUpload(file) {
  if (
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg"
  ) {
    return true
  }
  message.error("You can only upload image file!")
  return false
}

export default function (props) {
  const [removeFile, setRemoveFile] = useState(false)
  const uploadProps = {
    customRequest() {
      return false
    },
  }

  function handleRemove(e) {
    setRemoveFile(true)
  }

  function normFile(e) {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  const {
    getFieldDecorator,
    email,
    firstName,
    lastName,
    userName,
    role,
    isUpdate,
    onChangeEmailClick,
    onChangeUserNameClick,
    onChangeUserPermissionClick,
  } = props
  return (
    <Form layout="vertical" className="add-show-form">
      <Form.Item label="First Name">
        {getFieldDecorator("firstName", {
          initialValue: isUpdate ? firstName : "",
          rules: [{ required: true, message: "Please input the First Name!" }],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Last Name">
        {getFieldDecorator("lastName", {
          initialValue: isUpdate ? lastName : "",
          rules: [{ required: true, message: "Please input the Last Name!" }],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator("email", {
          initialValue: isUpdate ? email : "",
          rules: [
            {
              type: "email",
              message: "The input is not valid Email!",
            },
            {
              required: !isUpdate,
              message: "Please input your Email!",
            },
          ],
        })(<Input disabled={isUpdate} />)}
        {isUpdate && <Button id="btn-change-email" type="link" className="email-link-button"
          onClick={() => onChangeEmailClick(false)}>
          Change Email
        </Button>}
      </Form.Item>
      {isUpdate && <Form.Item label="User Name">
        {getFieldDecorator("userName", {
          initialValue: isUpdate ? userName : "",
        })(<Input disabled={isUpdate} />)}
        <Button id="btn-change-user-name" type="link" className="email-link-button"
          onClick={() => onChangeUserNameClick(false)}>
          Change Username
        </Button>
      </Form.Item>}
      <Form.Item label="Role">
        {getFieldDecorator("role", {
          initialValue: isUpdate ? role : "",
          rules: [{ required: true, message: "Please select the role!" }],
        })(
          <Select placeholder="Select Role">
            <Option value="AUTHOR">AUTHOR</Option>
            <Option value="ADMIN">ADMIN</Option>
            <Option value="GUEST_AUTHOR">GUEST AUTHOR</Option>
            <Option value="MODERATOR">MODERATOR</Option>
          </Select>,
        )}
        <Button
          id="btn-user-permission"
          type="link"
          className="permission-link-button"
          onClick={() => onChangeUserPermissionClick(isUpdate)}
        >
          {isUpdate ? 'Edit Permission' : 'Set Permission'}
        </Button>
      </Form.Item>
      <Form.Item label="Profile Image">
        {getFieldDecorator("profileImage", {
          valuePropName: "profileImage",
          getValueFromEvent: normFile,
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id="btn-user-upload-logo">
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
      </Form.Item>
    </Form>
  )
}
