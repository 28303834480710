import gql from "graphql-tag"

export const UPDATE_COMMENT_MODERATION = gql`
  mutation updateCommentModeration($where: CommentWhereUniqueInput!,$data: updateCommentModerationDataInput!){
    updateCommentModeration(where: $where, data: $data) {
      id
      isHidden
      isDeleted
      isHighlighted
      isAccepted
    }
  }
`