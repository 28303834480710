export const SECRET = '89Rsk5l'

export const PERMISSION_KEYS = {
  USER_MANAGMENT: 'USER_MANAGMENT',
  AUTHOR_POSTS: 'AUTHOR_POSTS',
  MODERATION: 'MODERATION',
}

export const TEMPLATES_NAME = {
  AUTHOR: 'Author',
  GUEST_AUTHOR: 'Guest Author',
  MODERATOR: 'Moderator',
  ADMIN: 'Admin'
}
