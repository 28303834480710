import React, { useState, useEffect } from 'react'
import { Row, Form, Col, Typography, Divider, Input, Button, Icon, Avatar, Upload, message, notification, Modal, Progress } from 'antd'
import { UPDATE_POST } from '../graphql/Mutations'
import { chatClient } from '../../../../apollo'
import { GET_POST, GET_POST_IMAGE_SIGNED_URL, GET_POST_VIDEO_SIGNED_URL, GET_VIDEO, GET_EPISODE } from '../graphql/Queries'
import { fileUpload } from '../../../../common/fileUpload'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { useQuery } from '@apollo/react-hooks'
import client from '../../../../apollo'
import axios from 'axios'
import { formatWpPost } from '../FetchNewsArticle'
import { handleRequestFailWithNotification, openNotification } from '../../../../common/utility'
import { get, cloneDeep } from 'lodash'
import PlayerModal from './PlayerModal'
import Spinner from '../../../../components/loaders/Spinner'
import ReferenceDataRender from './ReferenceDataRender'

const { confirm } = Modal

const referenceTypeQuery = {
  VIDEO: GET_VIDEO,
  EPISODE: GET_EPISODE,
  POST: GET_POST,
}

const setFileNameWithDate = (fileName) =>
  `${fileName.substr(0, fileName.lastIndexOf('.'))}_${moment(Date.now()).format(
    'YYYY-MM-DDTHH:mm:ss',
  )}${fileName.substr(fileName.lastIndexOf('.'))}`;

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

function checkImageVideoWH(file, type) {
  return new Promise(function (resolve, reject) {
    let filereader = new FileReader();
    filereader.onload = e => {
      let src = e.target.result;
      if (type === 'image') {
        const image = new Image();
        image.onload = function () {
          if (this.width || this.height) {
            resolve({ width: this.width, height: this.height });
          } else {
            message.error('Could not identify width and height')
            reject();
          }
        };
        image.onerror = reject;
        image.src = src;
      } else if (type === 'video') {
        let video = document.createElement("video");
        video.onloadedmetadata = function () {
          if (this.videoWidth || this.videoHeight) {
            resolve({ width: this.videoWidth, height: this.videoHeight });
          } else {
            message.error('Could not identify width and height')
            reject();
          }
        };
        video.onerror = reject;
        video.setAttribute("src", src);
      }
    };
    filereader.readAsDataURL(file);
  });
}

function EditPost(props) {
  const { postForEdit, form, handleShowPostComments } = props
  const [postEditDataLoading, setPostEditDataLoading] = useState(false)
  const [postEditData, setPostEditData] = useState(null)
  const [removeFile, setRemoveFile] = useState(false)
  const { getFieldDecorator, resetFields } = form
  const [hasMediaRemove, setHasMediaRemove] = useState(false)
  const [isUploadMedia, setIsUploadMedia] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [imageVideoProgress, setImageVideoProgress] = useState(undefined)
  const [showPlayerModal, setShowPlayerModal] = useState(false)

  function handleOk(e) {
    setShowPlayerModal(false)
  }

  function handleModal() {
    setShowPlayerModal(true)
  }

  const {
    loading: isEditPostLoading,
    data: editData,
    error: editPostError
  } = useQuery(GET_POST, { variables: { id: postForEdit }, client: chatClient, fetchPolicy: 'network-only', skip: !postForEdit })

  useEffect(() => {
    if (!isEditPostLoading && editData?.getPostModeration) {
      setPostEditDataLoading(true)
      async function setPostData(params) {
        try {
          const post = editData?.getPostModeration
          await fetchReferenceData(post)
          setPostEditData(post)

        } catch (error) {
          handleCatchError(error)
        } finally {
          setPostEditDataLoading(false)
        }

      }
      setPostData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditPostLoading])

  useEffect(() => {
    if (postEditData && !postEditData?.image && !postEditData?.video && !postEditData?.referenceType) {
      setHasMediaRemove(true)
    } else {
      setHasMediaRemove(false)
    }
    resetFields()
    setIsUploadMedia(false)
  }, [postEditData])

  async function fetchReferenceData(post) {
    if (post?.referenceType) {

      if (post?.referenceType !== 'NEWS_ARTICLE') {
        let query = referenceTypeQuery[post?.referenceType]
        let apolloClient = post?.referenceType === 'POST' ? chatClient : client

        try {
          const res = await apolloClient.query({
            query: query,
            fetchPolicy: 'network-only',
            variables: { id: post?.referenceId },
          })

          if (res?.data) {
            if (post?.referenceType === 'POST' && res?.data?.getPostModeration) {
              let newPostModerationData = cloneDeep(res?.data?.getPostModeration)
              await fetchReferenceData(newPostModerationData)
              post.referenceData = newPostModerationData
            } else {
              post.referenceData = res?.data?.[post?.referenceType?.toLowerCase()]
            }
          }

        } catch (e) {
          handleCatchError(e)
        }
      }

      if (post?.referenceType === 'NEWS_ARTICLE' && post?.referenceSlug) {
        // NEWS_ARTICLE is not the case for now.
        // try {
        //   const response = await axios.get(`${process?.env?.REACT_APP_NEWS_ARTICLE_API_URL}?slug=${post?.referenceSlug}&_embed=1`)
        //   if (response?.status === 200) {
        //     if (response?.data?.[0]) {
        //       post.referenceData = formatWpPost(response?.data?.[0])
        //     }
        //   }

        // } catch (error) {
        //   handleCatchError(error)
        // }
      }
    }
  }

  function beforeImageUpload(file) {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg'
    ) {
      setIsUploadMedia(true)
      return true
    }
    message.error('You can only upload image file!')
    return false
  }

  function beforeVideoUpload(file) {
    const isMP4 = file.type === 'video/mp4'
    if (!isMP4) {
      message.error('You can only upload MP4 file!')
    } else {
      setIsUploadMedia(true)
    }
    return isMP4
  }

  const normFile = (e) => {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  const normVideoFile = (e) => {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeVideoUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  const uploadProps = {
    customRequest() {
      return false
    },
  }

  const handleRemoveFile = (e) => {
    setIsUploadMedia(false)
    setRemoveFile(true)
  }

  function handleSubmit(e) {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (!err) {
        if (!values?.image && !values?.video && (!values?.text || values?.text === '') && hasMediaRemove) {
          message.error('No data found to edit post')
          return
        }
        setIsSubmit(true)
        const { image, video } = values
        try {

          let data = {
            text: values.text,
          }

          let remove = null

          if (hasMediaRemove) {
            if (postEditData?.image) {
              remove = { image: null }
            } else if (postEditData?.video) {
              remove = { video: { disconnect: true } }
            } else if (postEditData?.referenceType) {
              remove = { referenceType: null, referenceId: null }
            }
          }

          let imageUrl = null
          let videoId = null
          let mediaDimensions = null

          if (image?.length > 0) {
            setImageVideoProgress(0)
            const fileName = setFileNameWithDate(
              image?.[0]?.name?.toLowerCase(),
            );
            const getSignedPutUrlResult = await chatClient.query({
              query: GET_POST_IMAGE_SIGNED_URL,
              variables: { fileName },
              fetchPolicy: 'network-only'
            })
            if (getSignedPutUrlResult?.data?.getPostImageSignedPutUrl) {
              await fileUpload(
                getSignedPutUrlResult?.data?.getPostImageSignedPutUrl?.signedUrl,
                image?.[0]?.originFileObj,
                setImageVideoProgress
              )
              imageUrl = getSignedPutUrlResult?.data?.getPostImageSignedPutUrl?.getUrl
            }
            mediaDimensions = await checkImageVideoWH(image[0]?.originFileObj, 'image')
          }
          if (video?.length > 0) {
            setImageVideoProgress(0)
            const fileName = setFileNameWithDate(
              video?.[0]?.name?.toLowerCase(),
            );
            const getSignedPutUrlResult = await chatClient.query({
              query: GET_POST_VIDEO_SIGNED_URL,
              fetchPolicy: 'network-only'
            })
            if (getSignedPutUrlResult?.data?.getPostVideoUploadSignedUrl) {
              await fileUpload(
                getSignedPutUrlResult?.data?.getPostVideoUploadSignedUrl?.signedUrl,
                video?.[0]?.originFileObj,
                setImageVideoProgress
              )
              videoId = getSignedPutUrlResult?.data?.getPostVideoUploadSignedUrl?.postVideoID
            }
            mediaDimensions = await checkImageVideoWH(video?.[0]?.originFileObj, 'video')
          }

          await chatClient.mutate({
            mutation: UPDATE_POST,
            variables: {
              where: { id: postEditData?.id },
              data: {
                ...data,
                ...(remove && { ...remove }),
                ...(imageUrl && { image: imageUrl }),
                ...(videoId && { video: { connect: { id: videoId } } }),
                ...(mediaDimensions && { mediaDimensions })
              }
            },
          })

          openNotification('success', `Post has been updated successfully`)
          resetFields()
          setIsSubmit(false)
          setImageVideoProgress(undefined)
          setTimeout(() => {
            handleShowPostComments(postEditData?.id)
          }, 500);

        } catch (error) {
          handleCatchError(error)
          setIsSubmit(false)
          setImageVideoProgress(undefined)
        }
      }
    });
  }

  function handleRemoveUpload() {
    confirm({
      title: `Are you sure, you want to remove ${postEditData?.image ? 'image' : postEditData?.video ? 'video' : postEditData?.referenceType ? 'shared content' : ''}?`,
      okText: 'Remove',
      okType: 'danger',
      onOk() { setHasMediaRemove(true) },
    })
  }

  if (postEditDataLoading || isEditPostLoading) return <Spinner />;

  if (editPostError) return `Error! ${editPostError.message}`

  return (
    <Form onSubmit={handleSubmit} id="edit-post-form">
      <Row>
        <Col><Typography.Text strong={true}> Edit Post</Typography.Text></Col>
        <Divider />
      </Row>
      <Row className="flex-1">
        <Col>
          <div className="post">
            <Row type="flex" gutter={8} className="flex-nowrap">
              {postEditData?.createdBy && <Col> <Avatar src={postEditData?.createdBy?.profileImage ?? ''}>{postEditData?.createdBy?.firstName?.charAt(0) ?? ''}</Avatar></Col>}
              <Col>
                {postEditData?.createdBy && <div className="post-user-name" >{postEditData?.createdBy?.firstName ?? ''} {postEditData?.createdBy?.lastName ?? ''}</div>}
              </Col>
            </Row>
            <Row>
              <Form.Item className="post-text">
                {getFieldDecorator('text', { initialValue: postEditData ? postEditData?.text : '' })(
                  <Input.TextArea rows={5} placeholder="post description" />,
                )}
              </Form.Item>
              {!hasMediaRemove && postEditData && (postEditData?.video || postEditData?.image) && <div className="edit-media-wrapper"> <div className="post-page-media-container">
                <PlayerModal
                  showPlayerModal={showPlayerModal}
                  handleOk={handleOk}
                  post={postEditData}
                  isAudioPlay={false}
                />
                {postEditData?.video?.videoURL && <img src={postEditData?.video?.videoGIF} alt={postEditData?.video?.id} className="video-gif" />}
                {postEditData?.image && <img src={postEditData?.image} alt={postEditData?.image} />}
              </div>
                <span
                  className="btn-media-remove"
                  onClick={() => { handleRemoveUpload() }}
                >
                  <Icon type="close-circle" />
                </span>
                {postEditData?.video && (postEditData?.video?.videoGIF || postEditData?.video?.thumbnail) && <Button id="btn-editpost-video-play" icon="play-circle" shape="circle" className="video-play-button" size="large" onClick={handleModal} ></Button>}
              </div>}
              {!hasMediaRemove &&  postEditData?.referenceType &&
                <Row>
                  <Col>
                    <div className="post-edit-reference-data">
                      <ReferenceDataRender type={postEditData?.referenceType} data={postEditData?.referenceData} />
                      <span
                        className="post-edit-reference-remove-upload"
                        onClick={() => { handleRemoveUpload() }}
                      >
                        <Icon type="close-circle" />
                      </span>
                    </div>
                  </Col>
                </Row>
              }
              <Form.Item label="Image">
                {getFieldDecorator('image', {
                  valuePropName: 'fileList',
                  getValueFromEvent: normFile,
                })(
                  <Upload
                    name="logo"
                    {...uploadProps}
                    listType="picture"
                    multiple={false}
                    accept=".jpg,.jpeg,.png"
                    onRemove={(e) => handleRemoveFile(e)}
                  >
                    <Button id='btn-image-thumbnail-img' disabled={!hasMediaRemove || isUploadMedia}>
                      <Icon type="upload" /> Click to upload
                    </Button>
                  </Upload>,
                )}
              </Form.Item>
              <Form.Item label="Video">
                {getFieldDecorator('video', {
                  valuePropName: 'fileList',
                  getValueFromEvent: normVideoFile,
                })(
                  <Upload
                    name="logo"
                    {...uploadProps}
                    listType="picture"
                    multiple={false}
                    accept=".mp4"
                    onRemove={(e) => handleRemoveFile(e)}
                  >
                    <Button id='btn-video-thumbnail-img' disabled={!hasMediaRemove || isUploadMedia}>
                      <Icon type="upload" /> Click to upload
                    </Button>
                  </Upload>,
                )}
              </Form.Item>
              <Row>
                <Col>
                  {imageVideoProgress >= 0 && (
                    <Progress
                      percent={imageVideoProgress}
                      size="small"
                      status="active"
                    />
                  )}
                </Col>
              </Row>
            </Row>
          </div>
        </Col>
      </Row>
      <Row type="flex">
        <Col className="ml-auto">
          <Row type="flex" gutter={8}>
            <Col><Button id="btn-post-edit-cancel" onClick={() => handleShowPostComments(postEditData?.id)}>Cancel</Button></Col>
            <Col><Button id="btn-post-edit-submit" type="danger" htmlType="submit" loading={isSubmit}>Save</Button></Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

const WrappedEditPost = Form.create({ name: 'edit_post' })(EditPost);

export default WrappedEditPost