import React, { Component } from "react"
import { Modal, Button, Form } from "antd"
import EditUserNameForm from "./EditUserNameForm"

const CollectionCreateForm = Form.create({ name: 'edit_update_user_name' })(
  // eslint-disable-next-line
  class extends React.Component {
    isChangeUserName = false

    changeUserName = (flag) => {
      this.isChangeUserName = flag
    }

    render() {
      const { showModal, isSubmit, handleCancelUserName, handleOkUserName, form } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={showModal}
          maskClosable={false}
          title={"Change User Name"}
          onCancel={handleCancelUserName}
          onOk={handleOkUserName}
          footer={[
            <Button id="btn-user-name-cancel" key="back" onClick={handleCancelUserName}>Cancel</Button>,
            <Button id="btn-user-name-save" disabled={!this.isChangeUserName} key="submit" type="primary"
              loading={isSubmit} onClick={handleOkUserName}>
              Update User Name
            </Button>
          ]}
        >
          <EditUserNameForm
            getFieldDecorator={getFieldDecorator}
            onGetNewUserName={this.changeUserName}
            {...this.props}
          />
        </Modal>
      )
    }
  }
)

export default class EditUserNameModal extends Component {
  render() {
    const { saveFormRef } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        {...this.props}
      />
    );
  }
}
