import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Divider, notification, Spin, Icon, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { chatClient } from '../../../../apollo'
import { COMMENT_THREAD_MODERATION, LIST_COMMENTS_MODERATION } from '../graphql/Queries'
import { get, debounce, cloneDeep, filter } from 'lodash'
import * as Sentry from '@sentry/browser'
import { handleRequestFailWithNotification, openNotification } from '../../../../common/utility'
import SideComment from './SideComment'
import { UPDATE_COMMENT_MODERATION } from '../graphql/Mutations'
import { usePusher } from '../../../../pusher'
import Spinner from '../../../../components/loaders/Spinner'
import { Virtuoso } from 'react-virtuoso'

const limit = 10
let scrollCommentDebounceJob;
const { confirm } = Modal

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

function SideCommentList(props) {
  const pusher = usePusher()
  const { selectComment, canMutate } = props
  const id = useRef(null)
  const postId = useRef(null)
  const [comments, _setComments] = useState([])
  const [commentsLoading, setcommentsLoading] = useState(false)
  const [loadingMoreComments, setLoadingMoreComments] = useState(false)
  const [skipComment, setSkipComment] = useState(0)
  const [commentsEndReached, setCommentsEndReached] = useState(false)
  const commentsRef = useRef(comments)
  const [lastCommentId, setLastCommentId] = useState(null)

  const setComments = (value) => {
    commentsRef.current = value
    _setComments(value)
  }

  const fetchCommentList = (loadingMore = false) => {
    loadingMore ? setLoadingMoreComments(true) : setcommentsLoading(true)
    const queryConfig = {
      query: COMMENT_THREAD_MODERATION,
      variables: {
        where: {
          post: { id: postId?.current },
          ...(id?.current && { parent: { id: id?.current } })
        },
        ...(lastCommentId && loadingMore && { after: lastCommentId }),
        first: limit,
        orderBy: 'RECENT',
      },
      fetchPolicy: 'no-cache',
    }

    chatClient.query(queryConfig).then(response => {
      if (get(response, 'data.commentThreadModeration')) {
        let newComments = filter(get(response, 'data.commentThreadModeration'), (comment) => comment?.id !== get(selectComment, 'id'))
        if (!loadingMore) {
          setComments(newComments)
        } else {
          setComments([...comments, ...newComments])
        }
        let length = get(response, 'data.commentThreadModeration')?.length
        if (length > 0) {
          setLastCommentId(get(response, `data.commentThreadModeration.${length - 1}.id`))
        }
        if (response?.data?.commentThreadModeration?.length < limit) {
          setCommentsEndReached(true)
        }
      }
    }).catch(e => {
      handleCatchError(e)
    }).finally(() => {
      setcommentsLoading(false)
      setLoadingMoreComments(false)
    })
  }

  useEffect(() => {
    id.current = get(selectComment, 'parent.id', null)
    postId.current = get(selectComment, 'post.id')
  }, [selectComment])

  useEffect(() => {
    setLastCommentId(null)
    setComments([])
    setCommentsEndReached(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectComment])

  useEffect(() => {
    if (postId?.current) {
      fetchCommentList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id?.current, postId?.current, selectComment])

  useEffect(() => {
    const commentChannel = pusher.subscribe('COMMENT_MODERATION_EVENT');
    commentChannel.bind('UPDATED', commentUpdateHandler);
    commentChannel.bind('CREATED', commentCreateHandler);

    return () => {
      commentChannel.unbind('UPDATED', commentUpdateHandler);
      commentChannel.unbind('CREATED', commentCreateHandler);
      pusher.unsubscribe('COMMENT_MODERATION_EVENT')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const commentUpdateHandler = async (data, metaData) => {
    if (data?.comment?.node) {
      const id = commentsRef?.current?.findIndex((comment) => comment?.id === data?.comment?.node?.id)

      if (id !== -1) {
        const newComments = cloneDeep(commentsRef?.current)
        newComments[id] = { ...newComments[id], ...data?.comment?.node }
        setComments([...newComments])
      }
    }
  }

  const commentCreateHandler = async (data, metaData) => {
    if (data?.comment?.node?.post) {
      if (postId?.current === data?.comment?.node?.post?.id) {
        if (
          (!id?.current && !get(data, 'comment.node.parent.id'))
          || id?.current === get(data, 'comment.node.parent.id')
        ) {
          setComments([data?.comment?.node, ...commentsRef?.current])
        }
      }
    }
  }

  function handleCommentListScroll(e) {

    if (scrollCommentDebounceJob) {
      scrollCommentDebounceJob.cancel()
    }

    const { target } = e
    const { scrollTop, offsetHeight, scrollHeight } = target || {}

    scrollCommentDebounceJob = debounce(() => {
      let scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5

      if (scrolledToBottom && !commentsEndReached && scrollTop !== 0) {
        fetchCommentList(true)
      }
    }, 300)

    scrollCommentDebounceJob()
  }

  function handleCommentActions(e, comment, fieldName) {
    e.stopPropagation()
    let value
    let title
    let okText
    let okType
    let notificationMessage
    let queryVariables = { where: { id: get(comment, 'id') } }

    switch (fieldName) {
      case 'isHighlighted':
        value = get(comment, 'isHighlighted')
        title = `Are you sure, you want to ${value ? 'unhighlight' : 'highlight'} this comment?`
        okText = value ? 'Unhighlight' : 'Highlight'
        okType = value ? 'danger' : 'primary'
        queryVariables = { ...queryVariables, data: { isHighlighted: !value } }
        notificationMessage = `Comment has been ${value ? 'unhighlighted' : 'highlighted'}`
        break;
      case 'isHidden':
        value = get(comment, 'isHidden')
        title = `Are you sure, you want to ${value ? 'unhide' : 'hide'} this comment?`
        okText = value ? 'Unhide' : 'Hide'
        okType = value ? 'primary' : 'danger'
        queryVariables = { ...queryVariables, data: { isHidden: !value } }
        notificationMessage = `Comment has been ${value ? 'unhidden' : 'hidden'}`
        break;
      case 'isAccepted':
        value = get(comment, 'isAccepted')
        title = `Are you sure, you want to ${value ? 'unaccept' : 'accept'} this comment ? `
        okText = value ? 'Unaccept' : 'Accept'
        okType = value ? 'danger' : 'primary'
        queryVariables = { ...queryVariables, data: { isAccepted: !value } }
        notificationMessage = `Comment has been ${value ? 'accepted' : 'unaccepted'} `
        break;
      default:
        break;
    }

    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          const res = await chatClient.mutate({ mutation: UPDATE_COMMENT_MODERATION, variables: queryVariables })
          if (res && get(res, 'data.updateCommentModeration')) {
            let newComments = cloneDeep(comments)
            let id = newComments?.findIndex((post) => post?.id === get(res, 'data.updateCommentModeration.id'))
            if (id !== -1) {
              if (fieldName === 'isHighlighted') {
                newComments[id].isHighlighted = !value
              } else if (fieldName === 'isHidden') {
                newComments[id].isHidden = !value
              } else if (fieldName === 'isAccepted') {
                newComments[id].isAccepted = !value
              }
            }
            setComments(newComments)
          }
          openNotification('success', notificationMessage)
        }
        catch (error) {
          handleCatchError(error)
        }
      }

    })
  }

  return (
    <div onScroll={handleCommentListScroll} className="side-comment-list-wrapper">
      {commentsLoading
        ? <Spinner />
        : comments &&
        <Virtuoso
          className={comments.length === 0 ? 'height-0' : ''}
          overscan={100}
          totalCount={comments?.length}
          item={index => {
            const comment = comments?.[index]
            if (!comment) {
              return null
            }
            return <SideComment
              key={comment?.id}
              index={index}
              comment={comment}
              handleCommentActions={handleCommentActions}
              showActions={false} canMutate={canMutate} />
          }}
          footer={() => {
            if (loadingMoreComments) {
              return <Row className="d-flex justify-content-center">
                <Col className="bottom-fetch-more-loader">
                  <Spin indicator={<Icon type="loading" className="moderation-spinner-size" spin />} />
                </Col>
              </Row>
            }
          }}
        />}
    </div>
  )
}

export default SideCommentList