import React, { useState, useEffect } from "react"
import {
  Form,
  Input
} from "antd"

export default function (props) {
  const {
    getFieldDecorator,
    userName,
    onGetNewUserName
  } = props
  const [currentUserName, setCurrentUserName] = useState()

  useEffect(() => {
    setCurrentUserName(userName)
  })

  function onChangeUserName(e) {
    if (e.target.value !== currentUserName) {
      onGetNewUserName(true)
    } else {
      onGetNewUserName(false)
    }
  }

  return (
    <Form layout="vertical" className="add-show-form">
      <Form.Item label="User Name">
        {getFieldDecorator("userName", {
          initialValue: userName ? userName : "",
          rules: [            
            {
              required: true,
              message: "Please input your user name!",
            },
          ],
        })(<Input onChange={onChangeUserName} />)}
      </Form.Item>
    </Form>
  )
}
