import React from 'react'
import { Modal } from 'antd'
import { Player, BigPlayButton } from 'video-react'
import HLSSource from '../../../../HLSSource'
import ReactAudioPlayer from 'react-audio-player'

export default function PlayerModal(props) {
  const { post, showPlayerModal, handleOk } = props
  return (
    showPlayerModal &&
    <Modal
      width="60%"
      height="40vh"
      visible={showPlayerModal}
      footer={null}
      onOk={() => handleOk()}
      onCancel={() => handleOk()}
      wrapClassName="post-video-modal"
    >
      <div className="w-100 text-center">
        <Player>
          <BigPlayButton position="center" />
          {post?.video?.videoURL && (
            <HLSSource
              isVideoChild
              src={post?.video?.videoURL}
            />)
          } 
        </Player>
      </div>
    </Modal >
  )
}
