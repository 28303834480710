import gql from "graphql-tag"

export const GET_USERS = gql`
query users($skip: Int, $first: Int) {
  users(skip: $skip, first: $first) {
    id
    firstName
    lastName
    email
    role
    isActive
    profileImage
  }
}
`

export const GET_USER_BY_EMAIL = gql`
query user($email: String) {
  user(where: {email: $email}) {
    id
  }
}
`

export const GET_USER_PROFILE_SIGNED_PUT_URL = gql`
query getUserProfileSignedPutUrl($fileName: String!, $contentType: String!){
  getUserProfileSignedPutUrl(data: {fileName: $fileName, contentType: $contentType }) {
    signedUrl
    getUrl
  }
}
`

export const GET_PERSONS = gql`
query persons($skip: Int, $first: Int, $filters: PersonWhereInput, $orderByFilter: PersonOrderByInput,$after: String ) {
  persons(where: $filters, skip: $skip, first: $first, orderBy: $orderByFilter, after: $after) {
    id
    email
    firstName
    profileImage
    lastName
    userName
    authZeroID
    recurlyId
    recurlyPlan
    badgeId
    promoPlan
    promoPlanExpiration
    role
    isActive
    createdAt
    updatedAt
    lastActiveOn
    details {
      commentCount
      likeCount
      starCount
      postCount
      lastPostedOn
    }
  }
}
`

export const TEMPLATES = gql`
  query templates($where: TemplateWhereInput) {
    templates(where:$where){
      id
      name
      permissions(orderBy:createdAt_ASC){
        id
        permission {
          id
          name
          key
        }
        canRead
        canMutate 
      }
    }
  }
`

export const GET_PERSON_PERMISSION = gql`
  query getPersonPermission($where: getPersonPermissionWhereInput!) {
    getPersonPermission(where: $where) {
      permissionTemplate {
        id
        name
        permissions {
          id
        }
      }
      permissions {
        id
        name
        canRead
        canMutate
      }
    }
  }
`