import React, { useState, useRef, useContext } from 'react'
import {
  Row,
  Col,
  Table,
  Typography,
  Button,
  Popconfirm,
  Input,
  Icon,
  notification,
  Tooltip,
  Select,
  Divider,
  Modal
} from 'antd'
import { get, clone, startCase, upperCase, debounce } from 'lodash'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import moment from 'moment-timezone'
import { AppContext } from '../../AppContext'
import client, { chatClient } from '../../apollo'
import {
  GET_PERSONS,
  GET_USER_BY_EMAIL,
  GET_USER_PROFILE_SIGNED_PUT_URL,
  TEMPLATES,
} from './graphql/Queries'
import { ASSIGN_PERMISSION, CREATE_USER, RESET_PASSWORD, UPDATE_PERSON, UPDATE_USER, UPDATE_USER_EMAIL, UPDATE_USER_NAME } from './graphql/Mutations'
import { GET_PERSONS_SUBSCRIPTION } from './graphql/Subscription'
import AddUserModal from './components/AddUserModal'
import EditEmailModal from './components/EditEmailModal'
import EditUserNameModal from './components/EditUserNameModal'
import UserPermissionModal from './components/UserPermissionModal'
import { fileUpload } from '../../common/fileUpload'
import './userManagement.css'
import UserCard from './components/UserCard'
import * as Sentry from '@sentry/browser'
import { PERMISSION_KEYS, TEMPLATES_NAME } from '../../common/constants'
import { find } from 'lodash'
import Spinner from '../../components/loaders/Spinner'
import { capitalizeWord } from '../../common/utility'

const { Title } = Typography
const { Option } = Select
const { confirm } = Modal
const limit = 10
let scrollDebounceJob;
let debounceJob

const FilterByOptions = [
  { name: 'Admin', value: 'ADMIN' },
  { name: 'Author', value: 'AUTHOR' },
  { name: 'Guest', value: 'GUEST_AUTHOR' },
  { name: 'Moderator', value: 'MODERATOR' }
]

const nameSearchQueryParams = (value) => {
  if (value?.includes(' ')) {
    let [first, last] = value?.split(' ')
    return [
      { firstName_contains: first },
      { firstName_contains: first?.toLowerCase() },
      { firstName_contains: first?.toUpperCase() },
      { firstName_contains: capitalizeWord(first) },
      { lastName_contains: last },
      { lastName_contains: last?.toLowerCase() },
      { lastName_contains: last?.toUpperCase() },
      { lastName_contains: capitalizeWord(last) }
    ]
  }
  return [
    { firstName_contains: value },
    { firstName_contains: value?.toLowerCase() },
    { firstName_contains: value?.toUpperCase() },
    { firstName_contains: capitalizeWord(value) },
    { lastName_contains: value },
    { lastName_contains: value?.toLowerCase() },
    { lastName_contains: value?.toUpperCase() },
    { lastName_contains: capitalizeWord(value) }
  ]
}

const userNameSearchQueryParams = (value) => {
  return [
    { userName_contains: value },
    { userName_contains: value?.toLowerCase() },
    { userName_contains: value?.toUpperCase() },
    { userName_contains: capitalizeWord(value) }
  ]
}

export default function UserManagement() {
  const { state } = useContext(AppContext)
  const [showModal, setShowModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [showUserNameModal, setShowUserNameModal] = useState(false)
  const [showPermissionModal, setShowPermissionModal] = useState(false)
  const [personEditableData, setPersonEditableData] = useState('')
  const [isSubmit, setSubmit] = useState(false)
  const [persons, setPersons] = useState([])
  const [personsLoading, setPersonsLoading] = useState(false)
  const [loadingMorePersons, setLoadingMorePersons] = useState(false)
  const [lastPersonId, setLastPersonId] = useState(null)
  const [personsEndReached, setPersonsEndReached] = useState(false)
  const [nameSearch, setNameSearch] = useState('')
  const [userNameSearch, setUserNameSearch] = useState('')
  const [emailSearch, setEmailSearch] = useState('')
  const [roleSearch, setRoleSearch] = useState(undefined)
  const [personPermissionData, setPersonPermissionData] = useState(null)
  const { currentPersonPermission = [] } = state
  const permission = find(currentPersonPermission, ['key', PERMISSION_KEYS.USER_MANAGMENT])
  const { canMutate = false } = permission || {}

  const filterQueryParams = {
    role_not: 'USER',
    ...(roleSearch && { role: roleSearch }),
    ...((nameSearch !== '' || userNameSearch !== '' || emailSearch !== '') &&
    {
      AND: [
        ...(nameSearch !== '' ? [{ OR: nameSearchQueryParams(nameSearch) }] : []),
        ...(userNameSearch !== '' ? [{ OR: userNameSearchQueryParams(userNameSearch) }] : []),
        ...(emailSearch !== '' ? [{ OR: [{ email_starts_with: emailSearch.toLowerCase() }] }] : []),
      ]
    }
    )
  }

  const { data: personData, loading, error: personError, fetchMore, networkStatus } = useQuery(
    GET_PERSONS,
    {
      variables: {
        first: 10,
        filters: filterQueryParams,
        orderByFilter: 'email_ASC'
      },
      fetchPolicy: 'network-only',
      client: chatClient,
      onCompleted() {
        setPersonsLoading(true)
        if (get(personData, 'persons')) {
          setPersons(personData?.persons)
          let length = get(personData, 'persons')?.length
          setLastPersonId(get(personData, `persons[${length - 1}].id`))
          setPersonsEndReached(false)
        }
        setPersonsLoading(false)
      }
    },
  )

  const {
    data: personSubscriptionData,
  } = useSubscription(GET_PERSONS_SUBSCRIPTION, { client: chatClient })

  const saveFormRef = useRef()
  const permissionFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleCancel() {
    saveFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowModal(false)
    setPersonEditableData('')
    setPersonPermissionData(null)
  }

  function handleCancelEmail() {
    saveFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowEmailModal(false)
    setPersonEditableData('')
  }

  function handleUpdateEmail() {
    const form = saveFormRef?.current?.props?.form
    if (form) {
      form.validateFields(async (error, values) => {
        if (error) {
          return
        }
        setSubmit(true)
        const { email } = values
        if (email && personEditableData?.email) {
          chatClient.mutate({
            mutation: UPDATE_USER_EMAIL,
            variables: {
              currentEmail: personEditableData?.email,
              newEmail: email?.toLowerCase()
            }
          })
            .then(() => {
              setShowEmailModal(false)
              handleSuccess('updated')
            }).catch((error) => {
              console.log('updateUserEmailResult: ', error)
              handleRequestFail(error)
            })
        }
      })
    }
  }

  function handleUpdateUserName() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (error, values) => {
      if (error) {
        return
      }
      setSubmit(true)
      const { userName } = values
      if (userName && personEditableData && personEditableData.id) {
        chatClient.mutate({
          mutation: UPDATE_USER_NAME,
          variables: {
            personID: personEditableData.id,
            username: userName
          }
        })
          .then(() => {
            setShowUserNameModal(false)
            handleSuccess('updated')
          }).catch((error) => {
            console.log('updateUserNameResult: ', error)
            handleRequestFail(error)
          })
      }
    })
  }

  function handleCancelUserName() {
    saveFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowUserNameModal(false)
    setPersonEditableData('')
  }

  function handleCancelUserPermission(isUpdate) {
    if (isUpdate) {
      setPersonEditableData('')
      setPersonPermissionData(null)
    }
    if (permissionFormRef?.current?.props?.form) {
      permissionFormRef.current.props.form.resetFields()
    }
    setSubmit(false)
    setShowPermissionModal(false)
  }

  function onChangeEmailClick() {
    setShowModal(false)
    setShowEmailModal(true)
  }

  function onChangeUserNameClick() {
    setShowModal(false)
    setShowUserNameModal(true)
  }

  function onChangeUserPermissionClick(isUpdate) {
    if (isUpdate) {
      setShowModal(false)
    }
    setShowPermissionModal(true)
  }

  function handleRequestFail(e) {
    setSubmit(false)
    if (e && e.message) {
      const message =
        (e && e.graphQLErrors && Array.isArray(e.graphQLErrors) && e.graphQLErrors?.[0] && e.graphQLErrors?.[0].message) || e.message
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
    Sentry.captureException(e)
  }

  async function uploadProfileImage(profileImage, email, operation) {
    if (profileImage && profileImage.length > 0) {
      const userId = await client.query({
        query: GET_USER_BY_EMAIL,
        variables: { email },
      })
      const tempFilenameArray = profileImage?.[0].name.split('.')
      const fileExt = tempFilenameArray[tempFilenameArray.length - 1]
      const fileName = `${userId.data.user.id
        }-${new Date().getTime()}.${fileExt}`
      const contentType = profileImage?.[0].type
      const getSignedPutUrlResult = await client.query({
        query: GET_USER_PROFILE_SIGNED_PUT_URL,
        variables: { fileName, contentType },
      })
      await fileUpload(
        getSignedPutUrlResult.data.getUserProfileSignedPutUrl.signedUrl,
        profileImage[0].originFileObj,
      )
      await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          profileImage:
            getSignedPutUrlResult.data.getUserProfileSignedPutUrl.getUrl,
          email,
        },
      })
      if (operation === 'update') {
        await chatClient.mutate({
          mutation: UPDATE_PERSON,
          variables: {
            profileImage:
              getSignedPutUrlResult.data.getUserProfileSignedPutUrl.getUrl,
            email,
          },
        })
      }
    }
  }

  function handleCreateOrUpdatePermission() {
    if (permissionFormRef?.current?.props?.form) {
      permissionFormRef.current.props.form.validateFields((err, values) => {
        if (err) {
          return
        }
        if (personEditableData === '') {
          setPersonPermissionData(values)
          setShowPermissionModal(false)
          return
        }
        setSubmit(true)
        const { templateId, permission } = values
        if (personEditableData && personEditableData.id) {
          chatClient.mutate({
            mutation: ASSIGN_PERMISSION,
            variables: {
              data: { templateId: templateId, permissions: permission },
              where: { personId: get(personEditableData, 'id') }
            }
          })
            .then(() => {
              setShowPermissionModal(false)
              handleSuccess('updated')
            }).catch((error) => {
              console.log('updateUserPermissionResult: ', error)
              handleRequestFail(error)
            })
        }
      });
    }
  }

  async function assignPermissionUsingRole(role, personId) {
    try {
      const templateResult = await chatClient.query({ query: TEMPLATES, fetchPolicy: 'network-only' })
      const templateName = TEMPLATES_NAME[role]

      const templateData = get(templateResult, 'data.templates')

      if (templateName && templateData?.length > 0) {
        const template = find(templateData, (template) => template?.name === templateName)
        if (template) {
          let permissions = template?.permissions?.map(permissionTemp => ({
            permissionId: permissionTemp?.permission?.id,
            canMutate: permissionTemp?.canMutate,
            canRead: permissionTemp?.canRead
          }))
          await chatClient.mutate({
            mutation: ASSIGN_PERMISSION,
            variables: {
              data: { templateId: template?.id, permissions: permissions },
              where: { personId }
            },
          })
        }
      }
    } catch (error) {
      handleRequestFail(error)
    }
  }

  function handleSuccess(operation) {
    openNotification('success', `User ${operation} successfully`)
    setShowModal(false)
    setSubmit(false)
    setPersonEditableData('')
    setPersonPermissionData(null)
    const isFormObjFields = get(saveFormRef, 'current.props.form.resetFields')
    if (isFormObjFields) {
      saveFormRef.current.props.form.resetFields()
    }
  }

  function handleCreateOrUpdate() {
    const form = saveFormRef?.current?.props?.form
    form.validateFields(async (error, values) => {
      if (error) {
        return
      }
      setSubmit(true)
      const {
        email,
        firstName,
        lastName,
        role,
        profileImage
      } = values
      console.log('values', values)
      if (personEditableData && personEditableData.email) {
        client
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              email: personEditableData?.email?.toLowerCase(),
              firstName,
              lastName,
              role,
            },
          })
          .then((updateUserResult) => {
            return chatClient.mutate({
              mutation: UPDATE_PERSON,
              variables: {
                email: personEditableData?.email?.toLowerCase(),
                firstName,
                lastName,
                role
              },
            })
          })
          .then(async (updatePersonResult) => {
            await uploadProfileImage(
              profileImage,
              personEditableData?.email?.toLowerCase(),
              'update',
            )
            handleSuccess('updated')
          })
          .catch((e) => {
            handleRequestFail(e)
          })
      } else {
        client
          .mutate({
            mutation: CREATE_USER,
            variables: { firstName, lastName, role, email: email?.toLowerCase() },
          }).then(async (createUserResult) => {
            if (personPermissionData) {
              await chatClient.mutate({
                mutation: ASSIGN_PERMISSION,
                variables: {
                  data: { templateId: personPermissionData?.templateId, permissions: personPermissionData?.permission },
                  where: { personId: get(createUserResult, 'data.createUser.personId') }
                },
              })
            } else {
              await assignPermissionUsingRole(role, get(createUserResult, 'data.createUser.personId'))
              return createUserResult
            }
          })
          .then(async (createUserResult) => {
            await uploadProfileImage(profileImage, email?.toLowerCase(), 'create')
            handleSuccess('added')
          })
          .catch((e) => {
            handleRequestFail(e)
          })
      }
    })
  }

  function handleActive(email, isActive) {
    confirm({
      title: `Are you sure you want to ${isActive ? 'deactivate' : 'activate'} this user?`,
      okText: isActive ? 'Deactivate' : 'Activate',
      okType: isActive ? 'danger' : 'primary',
      async onOk() {
        try {
          await client.mutate({ mutation: UPDATE_USER, variables: { email, isActive: !isActive } })
          await chatClient.mutate({ mutation: UPDATE_PERSON, variables: { email, isActive: !isActive } })
          setTimeout(() => {
            openNotification('success', 'User updated successfully')
          }, 500)
        }
        catch (error) {
          handleRequestFail(error)
        }
      },
    })
  }

  function handleResetPassword(authZeroID) {
    confirm({
      title: `Are you sure you want to initiate a password reset for this user?`,
      okText: 'Reset',
      okType: 'primary',
      async onOk() {
        try {
          const response = await chatClient.mutate({ mutation: RESET_PASSWORD, variables: { authId: authZeroID } })
          if (get(response, 'data.resetPassword.message')) {
            setTimeout(() => {
              openNotification('success', get(response, 'data.resetPassword.message'))
            }, 500)
          }
        }
        catch (error) {
          handleRequestFail(error)
        }
      },
    })
  }

  function handleEditButton(data) {
    setPersonEditableData(data)
    setShowModal(true)
  }

  if (
    personSubscriptionData &&
    personSubscriptionData.person &&
    personSubscriptionData.person.mutation === 'CREATED'
  ) {
    let checkInPersons = persons.findIndex(
      (person) => person.id === personSubscriptionData.person.node.id,
    )
    if (checkInPersons === -1) {
      const {
        person: { node },
      } = personSubscriptionData
      setPersons([node, ...persons])
    }
  } else if (
    personSubscriptionData &&
    personSubscriptionData.person &&
    personSubscriptionData.person.mutation === 'UPDATED'
  ) {
    const checkInPersons = persons.findIndex(
      (person) => person.id === personSubscriptionData.person.node.id,
    )
    if (checkInPersons !== -1) {
      persons[checkInPersons] = {
        ...persons[checkInPersons],
        ...personSubscriptionData.person.node,
      }
    }
  }

  async function loadMorePersons() {
    try {
      setLoadingMorePersons(true)
      const response = await fetchMore({
        variables: {
          after: lastPersonId,
          first: limit,
          filters: filterQueryParams,
          orderByFilter: 'email_ASC'
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            const { persons } = fetchMoreResult
            if (persons?.length < limit) {
              setPersonsEndReached(true)
            }
          }
        },

      })

      if (response && get(response, 'data.persons')) {
        if (get(response, 'data.persons').length > 0) {
          let newPersons = get(response, 'data.persons', [])
          setPersons([...persons, ...newPersons])
          setLastPersonId(get(response, `data.persons[${newPersons.length - 1}].id`))
        }
      }

    } catch (error) {
      handleRequestFail(error)
    } finally {
      setLoadingMorePersons(false)
    }

  }

  function handleScroll(e) {

    if (scrollDebounceJob) {
      scrollDebounceJob.cancel()
    }

    const { target } = e
    const { scrollTop, offsetHeight, scrollHeight } = target || {}

    scrollDebounceJob = debounce(async () => {
      let scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5

      if (scrolledToBottom && !personsEndReached && scrollTop !== 0) {
        await loadMorePersons()
      }
    }, 300)

    scrollDebounceJob()

  }

  const searchInputChange = (e, setValue) => {
    if (debounceJob) {
      debounceJob.cancel()
      debounceJob = null
    }

    debounceJob = debounce((searchValue) => {
      setValue(searchValue?.trim())
    }, 2000)

    debounceJob(e?.target?.value)
  }

  const filterByChange = (data) => {
    setRoleSearch(data)
  }

  return (
    <Row className="user-management-wrapper d-flex">
      <Col>
        <Row type="flex" gutter={8} className="user-filter">
          {canMutate && (
            <Col>
              <Button
                id="btn-user-create-modal"
                type="primary"
                shape="circle"
                icon="plus"
                size="default"
                onClick={() => setShowModal(true)}
              />
            </Col>
          )}
          <Col>
            <Select allowClear onChange={filterByChange} placeholder="Filter by">
              {FilterByOptions?.map((option) => <Option key={option?.name} value={option?.value}>{option?.name}</Option>)}
            </Select>
          </Col>
          <Col>
            <Input
              allowClear
              placeholder="UserName"
              prefix={<Icon type="search" />}
              className="search-input"
              onChange={(e) => searchInputChange(e, setUserNameSearch)} />
          </Col>
          <Col>
            <Input
              allowClear
              placeholder="Email"
              prefix={<Icon type="search" />}
              className="search-input"
              onChange={(e) => searchInputChange(e, setEmailSearch)} />
          </Col>
          <Col>
            <Input
              allowClear
              placeholder="Name"
              prefix={<Icon type="search" />}
              className="search-input"
              onChange={(e) => searchInputChange(e, setNameSearch)} />
          </Col>
        </Row>
      </Col>
      <Divider className="filter-divider" />
      <Col className="user-list" onScroll={handleScroll}>
        {
          (loading || personsLoading)
            ? <Spinner /> : personError
              ? `Error${personError.message}`
              : <Row type="flex" gutter={24}>
                {persons?.map((person, index) => (
                  <Col md={12} lg={8} key={person?.id} className="user-card-wrapper">
                    <UserCard
                      person={person}
                      index={index}
                      handleEditButton={handleEditButton}
                      handleActive={handleActive}
                      handleResetPassword={handleResetPassword}
                      canMutate={canMutate} />
                  </Col>
                ))}
                {(loadingMorePersons) && (
                  <div className="user-more-loader-wrapper">
                    <Spinner />
                  </div>
                )}
              </Row>
        }
        {showModal && <AddUserModal
          saveFormRef={saveFormRef}
          showModal={showModal}
          isSubmit={isSubmit}
          handleOk={handleCreateOrUpdate}
          handleCancel={handleCancel}
          onChangeEmailClick={onChangeEmailClick}
          onChangeUserNameClick={onChangeUserNameClick}
          onChangeUserPermissionClick={onChangeUserPermissionClick}
          isUpdate={personEditableData ? true : false}
          {...personEditableData}
        />}
        {showEmailModal && <EditEmailModal
          saveFormRef={saveFormRef}
          showModal={showEmailModal}
          isSubmit={isSubmit}
          handleOkEmail={handleUpdateEmail}
          handleCancelEmail={handleCancelEmail}
          {...personEditableData}
        />}
        {showUserNameModal && <EditUserNameModal
          saveFormRef={saveFormRef}
          showModal={showUserNameModal}
          isSubmit={isSubmit}
          handleOkUserName={handleUpdateUserName}
          handleCancelUserName={handleCancelUserName}
          {...personEditableData}
        />}
        {showPermissionModal && <UserPermissionModal
          saveFormRef={permissionFormRef}
          showModal={showPermissionModal}
          isSubmit={isSubmit}
          handleOk={handleCreateOrUpdatePermission}
          handleCancelUserPermission={handleCancelUserPermission}
          isUpdate={personEditableData ? true : false}
          person={personEditableData}
          personPermissionData={personPermissionData}
        />}
      </Col>
    </Row>
  )
}
