import React, { useEffect, useState, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { chatClient } from '../../../../apollo'
import { GET_COMMENTS } from '../graphql/Queries'
import { usePusher } from '../../../../pusher'
import { cloneDeep, debounce } from 'lodash'
import { PageHeader, Col, Row, Icon, Spin, Modal } from 'antd'
import { UPDATE_COMMENT_MODERATION } from '../graphql/Mutations'
import Comment from './Comment'
import * as Sentry from '@sentry/browser'
import { handleRequestFailWithNotification, openNotification } from '../../../../common/utility'
import { get, uniqBy } from 'lodash'
import Spinner from '../../../../components/loaders/Spinner'
import { Virtuoso } from 'react-virtuoso'

const limit = 10
let scrollCommentDebounceJob;
const { confirm } = Modal

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

function CommentList(props) {
  const { selectPost, selectPostRef, canMutate } = props
  const pusher = usePusher()
  const [comments, _setComments] = useState([])
  const [commentsEndReached, setCommentsEndReached] = useState(false)
  const [loadingMoreComments, setLoadingMoreComments] = useState(false)
  const [showCommentReply, _setShowCommentReply] = useState(null)
  const [commentReplyParent, setCommentReplyParent] = useState([])
  const [commentReplayLevel, setCommentReplayLevel] = useState(0)
  const commentsRef = useRef(comments);     // not able to access state in pusher event listner
  const [lastCommentId, setLastCommentId] = useState(null)
  const showCommentReplyRef = useRef(showCommentReply)    //for accessing in pusher event

  const setComments = data => {
    commentsRef.current = data;
    _setComments(data);
  }

  const setShowCommentReply = data => {
    showCommentReplyRef.current = data
    _setShowCommentReply(data)
  }

  useEffect(() => {
    setShowCommentReply(null)
  }, [selectPost])

  const {
    loading: isCommentLoading,
    data: commentsData,
    error: commentsError,
    fetchMore: fetchMoreComments
  } = useQuery(GET_COMMENTS, {
    variables: {
      where: {
        post: { id: selectPost },
        ...(showCommentReply && { parent: { id: showCommentReply } })
      },
      orderBy: 'RECENT',
      first: limit,
    }, client: chatClient,
    fetchPolicy: 'network-only',
    skip: !selectPost
  })

  useEffect(() => {
    if (!isCommentLoading && commentsData?.commentThreadModeration) {
      setComments(commentsData?.commentThreadModeration)
      setCommentsEndReached(false)
      setLastCommentId(null)
      let length = commentsData?.commentThreadModeration?.length
      if (length > 0) {
        setLastCommentId(get(commentsData, `commentThreadModeration.${length - 1}.id`))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentLoading])

  async function loadMoreComments() {
    try {
      setLoadingMoreComments(true)
      const res = await fetchMoreComments({
        variables: {
          ...(lastCommentId && { after: lastCommentId }),
          first: 10,
          where: {
            post: { id: selectPost },
            ...(showCommentReply && { parent: { id: showCommentReply } })
          },
          orderBy: 'RECENT'
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            const { commentThreadModeration: newCommentsData } = fetchMoreResult
            if (newCommentsData?.length < limit) {
              setCommentsEndReached(true)
            }
          }
        },

      })

      if (res?.data?.commentThreadModeration) {
        const { data: { commentThreadModeration: newCommentsData } = {} } = res
        if (newCommentsData?.length > 0) {
          setComments([...comments, ...newCommentsData])
          let length = newCommentsData?.length
          setLastCommentId(get(newCommentsData, `${length - 1}.id`))
        }
      }

    } catch (error) {
      handleCatchError(error)
    } finally {
      setLoadingMoreComments(false)
    }

  }

  useEffect(() => {
    const commentChannel = pusher.subscribe('COMMENT_MODERATION_EVENT');
    commentChannel.bind('UPDATED', commentUpdateHandler);
    commentChannel.bind('CREATED', commentCreateHandler);

    return () => {
      commentChannel.unbind('UPDATED', commentUpdateHandler);
      commentChannel.unbind('CREATED', commentCreateHandler);
      pusher.unsubscribe('COMMENT_MODERATION_EVENT')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const commentUpdateHandler = async (data, metaData) => {
    if (data?.comment?.node) {
      const id = commentsRef?.current?.findIndex((comment) => comment?.id === data?.comment?.node?.id)

      if (id !== -1) {
        const newComments = cloneDeep(commentsRef?.current)
        let activityLogs = [...newComments[id]?.activityLogs, ...data?.comment?.node?.activityLogs]
        activityLogs = uniqBy(activityLogs, 'id') //removing log if it is already in the list
        newComments[id] = { ...newComments[id], ...data?.comment?.node, activityLogs: activityLogs }
        setComments([...newComments])
      }
    }
  }

  const commentCreateHandler = async (data, metaData) => {
    if (data?.comment?.node?.post) {
      if (selectPostRef && selectPostRef.current === data.comment.node.post.id) {
        if (
          (!showCommentReplyRef.current && !get(data, 'comment.node.parent.id'))
          || showCommentReplyRef.current === get(data, 'comment.node.parent.id')
        ) {
          setComments([data?.comment?.node, ...commentsRef?.current])
        }

        //for updating replay count
        const id = commentsRef?.current?.findIndex((comment) => {
          if (get(data, 'comment.node.parent.id')) {
            return comment?.id === get(data, 'comment.node.parent.id')
          }
          return false
        })
        if (id !== -1) {
          const newComments = cloneDeep(commentsRef.current)
          let newReplyCount = newComments[id]?.replyCount + 1
          newComments[id] = { ...newComments[id], replyCount: newReplyCount }
          setComments(newComments)
        }
      }
    }
  }

  function handleCommentListScroll(e) {

    if (scrollCommentDebounceJob) {
      scrollCommentDebounceJob.cancel()
    }

    const { target } = e
    const { scrollTop, offsetHeight, scrollHeight } = target || {}

    scrollCommentDebounceJob = debounce(() => {
      let scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5

      if (scrolledToBottom && !commentsEndReached && scrollTop !== 0) {
        loadMoreComments()
      }
    }, 300)

    scrollCommentDebounceJob()
  }

  function handleCommentReplayShow(comment) {
    if (comment?.replyCount > 0) {
      if (comment?.parent?.id) {
        let newCommentReplyParent = [...commentReplyParent, comment?.parent?.id]
        setCommentReplyParent(newCommentReplyParent)
      } else {
        let newCommentReplyParent = [...commentReplyParent, null]
        setCommentReplyParent(newCommentReplyParent)
      }
      setShowCommentReply(comment?.id)
      setCommentReplayLevel(commentReplayLevel + 1)
    }
  }

  function handlePreviousCommentReplayShow() {
    if (commentReplayLevel === 1) {
      setCommentReplyParent([])
      setShowCommentReply(null)
    } else {
      let newCommentReplyParent = [...commentReplyParent]
      let parentId = newCommentReplyParent?.pop()
      setCommentReplyParent(newCommentReplyParent)
      setShowCommentReply(parentId)
    }
    setCommentReplayLevel(commentReplayLevel - 1)
  }

  function handleCommentActions(e, comment, fieldName) {
    e.stopPropagation()
    let value
    let title
    let okText
    let okType
    let notificationMessage
    let queryVariables = { where: { id: get(comment, 'id') } }

    switch (fieldName) {
      case 'isHighlighted':
        value = get(comment, 'isHighlighted')
        title = `Are you sure, you want to ${value ? 'unhighlight' : 'highlight'} this comment?`
        okText = value ? 'Unhighlight' : 'Highlight'
        okType = value ? 'danger' : 'primary'
        queryVariables = { ...queryVariables, data: { isHighlighted: !value } }
        notificationMessage = `Comment has been ${value ? 'unhighlighted' : 'highlighted'}`
        break;
      case 'isHidden':
        value = get(comment, 'isHidden')
        title = `Are you sure, you want to ${value ? 'unhide' : 'hide'} this comment?`
        okText = value ? 'Unhide' : 'Hide'
        okType = value ? 'primary' : 'danger'
        queryVariables = { ...queryVariables, data: { isHidden: !value } }
        notificationMessage = `Comment has been ${value ? 'unhidden' : 'hidden'}`
        break;
      case 'isAccepted':
        value = get(comment, 'isAccepted')
        title = `Are you sure, you want to ${value ? 'unaccept' : 'accept'} this comment ? `
        okText = value ? 'Unaccept' : 'Accept'
        okType = value ? 'danger' : 'primary'
        queryVariables = { ...queryVariables, data: { isAccepted: !value } }
        notificationMessage = `Comment has been ${value ? 'accepted' : 'unaccepted'} `
        break;
      default:
        break;
    }

    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          const res = await chatClient.mutate({ mutation: UPDATE_COMMENT_MODERATION, variables: queryVariables })
          if (res && get(res, 'data.updateCommentModeration')) {
            let newComments = cloneDeep(comments)
            let id = newComments?.findIndex((post) => post?.id === get(res, 'data.updateCommentModeration.id'))
            if (id !== -1) {
              if (fieldName === 'isHighlighted') {
                newComments[id].isHighlighted = !value
              } else if (fieldName === 'isHidden') {
                newComments[id].isHidden = !value
              } else if (fieldName === 'isAccepted') {
                newComments[id].isAccepted = !value
              }
            }
            setComments(newComments)
          }
          openNotification('success', notificationMessage)
        }
        catch (error) {
          handleCatchError(error)
        }
      }

    })
  }

  if (isCommentLoading) return <Spinner />;

  if (commentsError) return `Error! ${commentsError.message}`

  return (
    <div className="comment-list-wrapper height-100">
      {!isCommentLoading && showCommentReply && <PageHeader
        onBack={() => handlePreviousCommentReplayShow()}
        title={''}
        className='box page-header replay-header'
      />}
      <div className={showCommentReply ? 'replay-wrapper' : 'comment-wrapper'} onScroll={handleCommentListScroll}>
        {comments &&
          <Virtuoso
            className="virtuoso"
            overscan={100}
            totalCount={comments?.length}
            item={index => {
              const comment = comments?.[index]
              if (!comment) {
                return null
              }
              return <Comment
                key={comment?.id}
                index={index}
                comment={comment}
                handleCommentReplayShow={handleCommentReplayShow}
                handleCommentActions={handleCommentActions}
                canMutate={canMutate}
              />
            }}
            footer={() => {
              if (loadingMoreComments) {
                return <Row className="d-flex justify-content-center">
                  <Col className="bottom-fetch-more-loader">
                    <Spin indicator={<Icon type="loading" className="moderation-spinner-size" spin />} />
                  </Col>
                </Row>
              }
            }}
          />}
      </div>
    </div>
  )
}

export default CommentList