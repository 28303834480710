import gql from 'graphql-tag'

export const LIST_POST_MODERATION = gql`
  query listPostModeration (
    $where: listPostModerationWhereInput,
    $orderBy: listPostModerationOrder,
    $first:Int,$skip:Int,
    $after:String,$before:String ){
    listPostModeration(
      where: $where, 
      orderBy: $orderBy,
      first: $first,
      skip: $skip,
      after: $after,
      before: $before){
      id
      isLive
      isDeleted
      referenceType
      referenceId
      referenceSlug
      likesCount
      commentsCount
      viewsCount
      playsCount
      text
      image
      retentionPeriod
      video {
        id
        thumbnail
        status
        videoState
        videoURL
        duration
        videoGIF
      }
      createdBy {
        id
        userName
        firstName
        lastName
        profileImage
      }
      createdAt
      updatedAt
      type
      totalFlagCount
    }
  }
`
export const GET_VIDEO = gql`
  query video($id: ID!) {
    video(where: { id: $id }) {
      id
      name
      description
      videoNumber
      image
      logoImage
      thumbnail
      videoURL
      createdBy {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`
export const GET_EPISODE = gql`
  query episode($id: ID!) {
    episode(where: { id: $id }) {
      id
      title
      image
      show {
        id
        name
      }
      createdBy {
        id
        firstName
        lastName
      }
      scheduleAt
      createdAt
    }
  }
`
export const GET_POST = gql`
  query getPostModeration($id: ID!) {
    getPostModeration(where: { id: $id }) {
      id
      image
      video {
        id
        thumbnail
        status
        videoState
        videoURL
        duration
        videoGIF
      }
      text
      referenceType
      referenceId
      referenceSlug
      createdBy {
        id
        firstName
        lastName
        profileImage
      }
      createdAt
    }
  }
`
export const GET_LIST_AUTHOR = gql`
  query listAuthor {
    listAuthor {
      details {
        id
        firstName
        userName
        lastName
      }
    }
  }
`
export const GET_COMMENTS = gql`
  query commentThreadModeration(
    $where: commentThreadModerationWhereInput!,
    $orderBy: commentThreadModerationOrderBy,
    $skip: Int,
    $first: Int,
    $after: String,
    $before: String) {
    commentThreadModeration(
      where: $where,
      orderBy: $orderBy,
      skip: $skip,
      first: $first,
      after: $after,
      before: $before) {
      id
      comment
      isHidden
      isDeleted
      isAccepted
      isHighlighted
      deletedBy {
        id
        userName
        firstName
        lastName
      }
      likeCount
      starCount
      flagCount
      replyCount
      stars {
        id
        firstName
        lastName
        profileImage
      }
      createdBy {
        id
        userName
        firstName
        lastName
        profileImage
      }
      moderationResponse
      moderationScore
      moderationLabel
      activityLogs {
        id
        referenceId
        referenceType
        action
        title
        content
        type
        createdAt
        updatedBy {
          id
          userName
          firstName
          lastName
          role
        }
      }
      createdAt
      parent {
        id
      }
      mediaType
      mediaURL
      giphyId
    }
  }
`

export const GET_POST_IMAGE_SIGNED_URL = gql`
  query getPostImageSignedPutUrl($fileName: String!) {
    getPostImageSignedPutUrl(data: { fileName: $fileName }) {
      fileName
      signedUrl
      getUrl
    }
  }
`;

export const GET_POST_VIDEO_SIGNED_URL = gql`
  query getPostVideoUploadSignedUrl {
    getPostVideoUploadSignedUrl {
      postVideoID
      signedUrl
    }
  }
`;