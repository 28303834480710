import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col, notification, Icon, Spin, Modal } from 'antd'
import axios from 'axios'
import { useQuery } from '@apollo/react-hooks'
import client, { chatClient } from '../../../../apollo'
import { GET_EPISODE, GET_VIDEO, LIST_POST_MODERATION, GET_POST } from '../graphql/Queries'
import * as Sentry from '@sentry/browser'
import { formatWpPost } from '../FetchNewsArticle'
import { get, debounce, cloneDeep, isEqual, truncate } from 'lodash'
import Post from './Post'
import { UPDATE_POST } from '../graphql/Mutations'
import { usePusher } from '../../../../pusher'
import { handleRequestFailWithNotification, openNotification } from '../../../../common/utility'
import Spinner from '../../../../components/loaders/Spinner'
import { Virtuoso } from 'react-virtuoso'

const limit = 10
let scrollPostDebounceJob;

const { confirm } = Modal

const referenceTypeQuery = {
  VIDEO: GET_VIDEO,
  EPISODE: GET_EPISODE,
  POST: GET_POST,
}

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

const orderByString = (category, type) => {
  let orderBy = ''
  switch (category) {
    case 'likes':
      orderBy = 'likesCount_'
      break;
    case 'comments':
      orderBy = 'commentsCount_'
      break;
    case 'views':
      orderBy = 'viewsCount_'
      break;
    case 'plays':
      orderBy = 'playsCount_'
      break;
    default:
      break;
  }

  if (orderBy !== '') {
    if (!type || type === 'Highest') {
      orderBy = `${orderBy}DESC`
    } else {
      orderBy = `${orderBy}ASC`
    }
  }
  return orderBy
}

function PostList(props) {
  const {
    match: { params: { id } = {} } = {},
    searchInput,
    authorSearch,
    dateRangeSearch,
    sortBy,
    filterBy,
    handleShowPostComments,
    openEditPost,
    selectPost,
    setSelectPost,
    postForEdit,
    canMutate,
    authorPostCanMutate,
    startTime,
    hasFilterApplied } = props
  const pusher = usePusher()
  const [posts, _setPosts] = useState([])
  const [postsDataLoading, setPostsDataLoading] = useState(false)
  const [loadingMorePosts, setLoadingMorePosts] = useState(false)
  const [postsEndReached, setPostsEndReached] = useState(false)
  const postsRef = React.useRef(posts)     // not able to access state in pusher event listner
  const [skipPost, setSkipPost] = useState(0)
  const [initialVariable, setInitialVariable] = useState({
    first: limit,
    where: {
      isLive: false,
      ...(!id && startTime && { createdAt_lte: startTime }),
      ...(!id && searchInput && { searchTerm: searchInput }),
      ...(!id && authorSearch && { author: { id: authorSearch } }),
      ...(!id && dateRangeSearch && { createdAt_gte: dateRangeSearch[0], createdAt_lte: dateRangeSearch[1] }),
      ...(id && { id: id })
    },
    orderBy: (!id && filterBy) ? orderByString(filterBy, sortBy) : 'createdAt_DESC'
  })

  useEffect(() => {        // stop call api twice
    if (sortBy && !filterBy) {
      return
    }
    setInitialVariable({
      first: limit,
      where: {
        isLive: false,
        ...(!id && startTime && { createdAt_lte: startTime }),
        ...(!id && searchInput !== '' && { searchTerm: searchInput }),
        ...(!id && authorSearch && { author: { id: authorSearch } }),
        ...(!id && dateRangeSearch && { createdAt_gte: dateRangeSearch[0], createdAt_lte: dateRangeSearch[1] }),
        ...(id && { id: id })
      },
      orderBy: (!id && filterBy) ? orderByString(filterBy, sortBy) : 'createdAt_DESC'
    })

  }, [id, searchInput, authorSearch, dateRangeSearch, filterBy, sortBy])

  const setPosts = data => {
    postsRef.current = data
    _setPosts(data)
  };

  useEffect(() => {
    const postChannel = pusher.subscribe('POST_EVENT');
    postChannel.bind('UPDATED', postUpdateHandler);
    postChannel.bind('CREATED', postCreateHandler);
    return () => {
      postChannel.unbind('CREATED', postCreateHandler);
      postChannel.unbind('UPDATED', postUpdateHandler);
      pusher.unsubscribe('POST_EVENT')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSkipPost(0)
    setPostsEndReached(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, filterBy, dateRangeSearch, sortBy, authorSearch, searchInput])

  const postCreateHandler = async (data, metaData) => {
    if (!id && !hasFilterApplied?.current && data?.post?.node && !data?.post?.node?.isLive) {
      if (data?.post?.node?.referenceType) {
        const newPosts = await fetchPostsReferenceData([data?.post?.node])
        setPosts([newPosts?.[0], ...postsRef?.current])
      } else {
        setPosts([data?.post?.node, ...postsRef?.current])
      }
    }
  }

  const postUpdateHandler = async (data, metaData) => {
    if (data?.post?.node) {
      const id = postsRef?.current?.findIndex((post) => post?.id === data?.post?.node?.id)

      if (id !== -1) {
        let newData = null
        const newPosts = cloneDeep(postsRef?.current)
        if (data?.post?.node?.referenceType) {
          newData = await fetchPostsReferenceData([data?.post?.node])
          newPosts[id] = newData?.[0]
        } else if (
          get(newPosts, `${id}.video.id`) && get(data, 'post.node.video.id') &&
          get(newPosts, `${id}.video.id`) === get(data, 'post.node.video.id') &&
          get(newPosts, `${id}.video.videoState`) && get(data, 'post.node.video.videoState') &&
          get(newPosts, `${id}.video.videoState`) === get(data, 'post.node.video.videoState')) {
          //checking for same video
          let video = newPosts[id]?.video
          newPosts[id] = { ...data?.post?.node, video: video }
        } else {
          newPosts[id] = data?.post?.node
        }

        setPosts([...newPosts])
      }
    }
  }

  useEffect(() => {
    const commentChannel = pusher.subscribe('COMMENT_MODERATION_EVENT');
    commentChannel.bind('CREATED', commentCreateHandler);

    return () => {
      commentChannel.unbind('CREATED', commentCreateHandler);
      pusher.unsubscribe('COMMENT_MODERATION_EVENT')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const commentCreateHandler = (data, metaData) => {
    if (data?.comment?.node?.post) {
      let index = postsRef?.current?.findIndex(post => post?.id === data?.comment?.node?.post?.id)
      if (index !== -1) {
        const newPosts = cloneDeep(postsRef?.current)
        let newCommentsCount = newPosts[index]?.commentsCount + 1
        newPosts[index] = { ...newPosts[index], commentsCount: newCommentsCount }
        setPosts(newPosts)
      }
    }
  }

  const {
    data,
    loading: isPostLoading,
    error: postsError,
    fetchMore: fetchMorePosts,
    networkStatus: postsNetworkStatus,
  } = useQuery(LIST_POST_MODERATION, {
    client: chatClient,
    variables: initialVariable,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isPostLoading && data?.listPostModeration) {
      setPostsDataLoading(true)
      async function setPostsData() {
        let posts = data?.listPostModeration

        posts = await fetchPostsReferenceData(posts)

        setPosts(posts)
        if (posts?.length > 0) {
          setSelectPost(posts?.[0]?.id)
        } else {
          setSelectPost(null)
        }
        setPostsDataLoading(false)
      }
      setPostsData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostLoading])

  async function fetchPostsReferenceData(posts) {

    for (let index = 0; index < posts?.length; index++) {
      const post = posts?.[index];
      await fetchReferenceData(post)
    }

    return posts

  }

  async function fetchReferenceData(post) {
    if (post?.referenceType) {

      if (post?.referenceType !== 'NEWS_ARTICLE') {
        let query = referenceTypeQuery[post?.referenceType]
        let apolloClient = post?.referenceType === 'POST' ? chatClient : client

        try {
          const res = await apolloClient.query({
            query: query,
            fetchPolicy: 'network-only',
            variables: { id: post?.referenceId },
          })

          if (res?.data) {
            if (post?.referenceType === 'POST' && res?.data?.getPostModeration) {
              let newPostModerationData = cloneDeep(res?.data?.getPostModeration)
              await fetchReferenceData(newPostModerationData)
              post.referenceData = newPostModerationData
            } else {
              post.referenceData = res?.data?.[post?.referenceType?.toLowerCase()]
            }
          }

        } catch (e) {
          handleCatchError(e)
          setPostsDataLoading(false)
        }
      }

      if (post?.referenceType === 'NEWS_ARTICLE' && post?.referenceSlug) {
        // NEWS_ARTICLE is not the case for now.
        // try {
        //   const response = await axios.get(`${process?.env?.REACT_APP_NEWS_ARTICLE_API_URL}?slug=${post?.referenceSlug}&_embed=1`)
        //   if (response?.status === 200) {
        //     if (response?.data?.[0]) {
        //       post.referenceData = formatWpPost(response.data[0])
        //     }
        //   }

        // } catch (error) {
        //   handleCatchError(error)
        //   setPostsDataLoading(false)
        // }
      }
    }
  }

  async function loadMorePosts() {
    try {
      setLoadingMorePosts(true)
      const res = await fetchMorePosts({
        variables: {
          skip: skipPost * limit,
          first: limit,
          where: {
            isLive: false,
            ...(startTime && { createdAt_lte: startTime }),
            ...(searchInput && { searchTerm: searchInput }),
            ...(authorSearch && { author: { id: authorSearch } }),
            ...(dateRangeSearch && { createdAt_gte: dateRangeSearch[0], createdAt_lte: dateRangeSearch[1] }),
            ...(id && { id: id })
          },
          orderBy: filterBy ? orderByString(filterBy, sortBy) : 'createdAt_DESC'
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            const { listPostModeration: newPostsData } = fetchMoreResult
            if (newPostsData?.length < limit) {
              setPostsEndReached(true)
            }
          }
        },

      })

      if (res?.data?.listPostModeration) {
        const { data: { listPostModeration: newPostsData } = {} } = res
        if (newPostsData?.length > 0) {
          const newPosts = await fetchPostsReferenceData(newPostsData)
          setPosts([...posts, ...newPosts])
        }
      }

    } catch (error) {
      handleCatchError(error)

    } finally {
      setLoadingMorePosts(false)
    }

  }

  useEffect(() => {
    if (skipPost !== 0) {
      loadMorePosts()
    }

  }, [skipPost])

  function handlePostListScroll(e) {

    if (scrollPostDebounceJob) {
      scrollPostDebounceJob.cancel()
    }

    const { target } = e
    const { scrollTop, offsetHeight, scrollHeight } = target || {}

    scrollPostDebounceJob = debounce(() => {
      let scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5

      if (scrolledToBottom && !postsEndReached && scrollTop !== 0) {
        setSkipPost(skipPost + 1)
      }
    }, 300)

    scrollPostDebounceJob()

  }

  function showPostHiddenConfirm(id, isHidden) {
    confirm({
      title: `Are you sure, you want to ${!isHidden ? 'hide' : 'unhide'} this post?`,
      okText: !isHidden ? 'Hide' : 'Unhide',
      okType: !isHidden ? 'danger' : 'primary',
      async onOk() {
        try {
          const res = await chatClient.mutate({ mutation: UPDATE_POST, variables: { data: { isDeleted: !isHidden }, where: { id: id } } })
          if (res?.data?.updatePost) {
            let newPosts = cloneDeep(posts)
            let id = newPosts?.findIndex((post) => post?.id === res?.data?.updatePost?.id)
            if (id !== -1) {
              newPosts[id].isDeleted = !isHidden
            }
            setPosts(newPosts)
          }
          openNotification('success', `Post has been ${!isHidden ? 'hidden' : 'Unhidden'}`)
        }
        catch (error) {
          handleCatchError(error)
        }
      },
    })
  }

  if (postsError) return `Error! ${postsError.message}`

  return (
    <div className="post-list-wrapper" onScroll={handlePostListScroll}>
      {postsDataLoading || isPostLoading
        ? <Spinner />
        : posts &&
        <Virtuoso
          className="virtuoso"
          overscan={100}
          totalCount={posts?.length}
          item={index => {
            const post = posts?.[index]
            if (!post) {
              return null
            }
            return <Post
              key={post?.id}
              index={index}
              post={post}
              selectPost={selectPost}
              postForEdit={postForEdit}
              showPostHiddenConfirm={showPostHiddenConfirm}
              handleShowPostComments={handleShowPostComments}
              openEditPost={openEditPost}
              canMutate={canMutate}
              authorPostCanMutate={authorPostCanMutate}
            />
          }}
          footer={() => {
            if (loadingMorePosts) {
              return <Row className="d-flex justify-content-center">
                <Col className="bottom-fetch-more-loader">
                  <Spin indicator={<Icon type="loading" className="moderation-spinner-size" spin />} />
                </Col>
              </Row>
            }
          }}
        />
      }
    </div>
  )
}

export default withRouter(PostList)