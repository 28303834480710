import React, { useContext, useState, useRef, useEffect } from 'react'
import { Row, Select, Col, Divider, notification, DatePicker } from 'antd'
import Filter from '../component/Filter'
import { chatClient } from '../../../apollo'
import { GET_LIST_AUTHOR } from './graphql/Queries'
import { useQuery } from '@apollo/react-hooks'
import { debounce } from 'lodash'
import { withRouter } from 'react-router'
import EditPost from './components/EditPost'
import PostList from './components/PostList'
import CommentList from './components/CommentList'
import { AppContext } from '../../../AppContext'
import { PERMISSION_KEYS } from '../../../common/constants'
import { find, get } from 'lodash'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { handleRequestFailWithNotification } from '../../../common/utility'

const { Option } = Select
const { RangePicker } = DatePicker

let debounceSearchJob;

const FilterByOptions = [
  { name: '# of likes', value: 'likes' },
  { name: '# of comments', value: 'comments' },
  { name: '# of views', value: 'views' },
  { name: '# of plays', value: 'plays' }
]

const SortByOptions = [
  { name: 'Lowest', value: 'Lowest' },
  { name: 'Highest', value: 'Highest' }
]
const dateFormat = 'MM/DD/YYYY';

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

export default withRouter(function (props) {
  const { match: { params: { id } = {} } = {}, } = props
  const [searchInput, setSearchInput] = useState('')
  const [filterBy, setFilterBy] = useState(null)
  const [dateRangeSearch, setDateRangeSearch] = useState(null)
  const [authorSearch, setAuthorSearch] = useState(null)
  const [selectPost, _setSelectPost] = useState(null)
  const [sortBy, setSortBy] = useState(undefined)      //using this value in select due to this for select placeholder it needs to be undefined
  const selectPostRef = React.useRef(selectPost) // not able to access state in pusher event listner
  const [postForEdit, setPostForEdit] = useState(null)
  const { state } = useContext(AppContext)
  const { currentPersonPermission = [] } = state
  const moderationPermission = find(currentPersonPermission, ['key', PERMISSION_KEYS.MODERATION])
  const { canMutate = false } = moderationPermission || {}
  const authorPostPermission = find(currentPersonPermission, ['key', PERMISSION_KEYS.AUTHOR_POSTS])
  const { canMutate: authorPostCanMutate } = authorPostPermission
  const [startTime, setStartTime] = useState(moment())
  const hasFilterApplied = useRef(false)

  useEffect(() => {
    if(searchInput === '' && !filterBy && !dateRangeSearch  && !authorSearch && !sortBy ){
      hasFilterApplied.current = false
    } else {
      hasFilterApplied.current = true
    }
  }, [searchInput, filterBy, dateRangeSearch, authorSearch , sortBy])

  const setSelectPost = data => {
    selectPostRef.current = data
    _setSelectPost(data)
  }


  function openNotification(type, message) {
    notification[type]({
      message
    })
  }

  function handleRequestFail(e) {
    openNotification("error", e || "Something Went Wrong")
  }

  const { data: { listAuthor = [] }, loading: isAuthorLoading } = useQuery(GET_LIST_AUTHOR, { client: chatClient, fetchPolicy: 'network-only', onError:(error)=>{ handleCatchError(error) } })

  const dateRangeChange = (data, dateString) => {
    setStartTime(moment())
    if (data && data.length > 1) {
      let day1 = data[0].startOf('day')
      let day2 = data[1].isSame(new Date(), 'day') ? data[1] : data[1].endOf('day')
      setDateRangeSearch([day1, day2])
    } else {
      setDateRangeSearch(null)
    }
  }

  const filterByChange = (data) => {
    setStartTime(moment())
    if (!data) {
      setSortBy(undefined)
    } else {
      setSortBy('Highest')
    }
    setFilterBy(data)
  }

  const authorChange = (data) => {
    setStartTime(moment())
    setAuthorSearch(data)
  }

  const sortByChange = (data) => {
    setStartTime(moment())
    setSortBy(data)
  }

  const searchInputChange = (e) => {
    if (debounceSearchJob) {
      debounceSearchJob.cancel()
      debounceSearchJob = null
    }

    debounceSearchJob = debounce((searchValue) => {
      setStartTime(moment())
      setSearchInput(searchValue?.trim())
    }, 1000)

    debounceSearchJob(e?.target?.value)
  }

  function handleShowPostComments(id) {
    setSelectPost(id)
    setPostForEdit(null)
  }

  function openEditPost(id) {
    setPostForEdit(id)
    setSelectPost(null)
  }

  function disabledDate(current) {
    return current && current > moment()?.endOf('day');
  }

  return (
    <Row className="moderation-wrapper d-flex flex-direction-column height-100">
      <Filter searchInputChange={searchInputChange}>
        <div className="ml-auto moderation-select-filter">
          <RangePicker onChange={dateRangeChange} format={dateFormat} disabledDate={disabledDate} />
        </div>
        <div>
          <Select allowClear onChange={filterByChange} placeholder="Filter by">
            {FilterByOptions.map((option) => <Option key={option.name} value={option.value}>{option.name}</Option>)}
          </Select>
        </div>
        <div className="moderation-select-filter">
          <Select allowClear onChange={authorChange} placeholder="Select Author">
            {listAuthor && listAuthor.map((author) => <Option key={author?.details?.id} value={author?.details?.id}>{`${author?.details?.firstName} ${author?.details?.lastName}`}</Option>)}
          </Select>
        </div>
        <div>
          <Select allowClear value={filterBy ? sortBy : undefined} onChange={sortByChange} placeholder="Sort by">
            {SortByOptions.map((option) => <Option key={option.name} value={option.value}>{option.name}</Option>)}
          </Select>
        </div>
      </Filter>
      <Divider />
      <Col className="flex-1">
        <Row className="height-100">
          <Col md={12} className="height-100">
            <PostList
              searchInput={searchInput}
              authorSearch={authorSearch}
              dateRangeSearch={dateRangeSearch}
              sortBy={sortBy}
              filterBy={filterBy}
              selectPost={selectPost}
              postForEdit={postForEdit}
              handleShowPostComments={handleShowPostComments}
              openEditPost={openEditPost}
              setSelectPost={setSelectPost}
              canMutate={canMutate}
              authorPostCanMutate={authorPostCanMutate}
              startTime={startTime}
              hasFilterApplied={hasFilterApplied}
            />
          </Col>
          <Col md={12} className="height-100">
            {selectPost && <CommentList selectPost={selectPost} selectPostRef={selectPostRef} canMutate={canMutate} />}
            {postForEdit && <div className="post-edit">
              <EditPost postForEdit={postForEdit} handleShowPostComments={handleShowPostComments} />
            </div>}
          </Col>
        </Row >
      </Col>
    </Row >
  )
})
