import React, { useEffect, useState, useRef } from 'react'
import { Col, Icon, Row, Spin, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { GET_COMMENTS } from '../graphql/Queries'
import { chatClient } from '../../../../apollo'
import { debounce, get, cloneDeep, uniqBy } from 'lodash'
import * as Sentry from '@sentry/browser'
import { handleRequestFailWithNotification, openNotification } from '../../../../common/utility'
import Comment from '../../post/components/Comment'
import LiveComment from './LiveComment'
import { usePusher } from '../../../../pusher'
import { BAN_USER, HIDE_ALL_COMMENTS_BY_USER, UPDATE_COMMENT_MODERATION } from '../graphql/Mutations'
import Spinner from '../../../../components/loaders/Spinner'
import { Virtuoso } from 'react-virtuoso'

const limit = 10
let debounceCommentJob;
let scrollCommentDebounceJob;
const { confirm } = Modal

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

export default function LiveCommentList(props) {
  const { selectPost, selectPostRef, commentSearchInput, canMutate, startTime, commentSearchInputRef } = props
  const pusher = usePusher()
  const [comments, _setComments] = useState([])
  const [commentsEndReached, setCommentsEndReached] = useState(false)
  const [commentsLoading, setCommentsLoading] = useState(false)
  const [loadingMoreComments, setLoadingMoreComments] = useState(false)
  const commentsRef = useRef(comments);     // not able to access state in pusher event listner
  const [firsId, setFirstId] = useState(undefined)
  const virtuoso = useRef(null)
  const [skipComment, setSkipComment] = useState(0)
  const [initialVariable, setInitialVariable] = useState({
    where: {
      post: { id: selectPost },
      ...(startTime && { createdAt_lte: startTime }),
      ...(commentSearchInput !== '' && { searchTerm: commentSearchInput })
    },
    orderBy: 'RECENT',
    first: limit,
  })

  useEffect(() => {        // stop call api twice
    setInitialVariable({
      where: {
        post: { id: selectPost },
        ...(startTime && { createdAt_lte: startTime }),
        ...(commentSearchInput !== '' && { searchTerm: commentSearchInput })
      },
      orderBy: 'RECENT',
      first: limit,
    })
    setSkipComment(0)
    setCommentsEndReached(false)
  }, [commentSearchInput, selectPost])


  const setComments = data => {
    commentsRef.current = data;
    _setComments(data);
  }

  const {
    loading: isCommentLoading,
    data: commentsData,
    error: commentsError,
    fetchMore: fetchMoreComments
  } = useQuery(GET_COMMENTS, {
    variables: initialVariable,
    client: chatClient,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (isCommentLoading) {
      setCommentsLoading(true)
    }
    if (!isCommentLoading && commentsData?.commentThreadModeration) {
      let newComments = [...commentsData?.commentThreadModeration]
      newComments = newComments?.reverse()
      setComments(newComments)
      setCommentsLoading(false)
      if (newComments?.length < limit) {
        setCommentsEndReached(true)
      } else {
        setCommentsEndReached(false)
      }

      setTimeout(() => { //scrollto last when first time comes
        const commentListEl = document.querySelector('.live-comment-list .virtuoso')
        if (commentListEl) {
          commentListEl.scrollTop = commentListEl?.scrollHeight
        }
      }, 200);

    }
    if (!isCommentLoading && commentsError) {
      setCommentsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentLoading])

  async function loadMoreComments() {
    try {
      setLoadingMoreComments(true)
      const res = await fetchMoreComments({
        variables: {
          skip: skipComment * limit,
          first: limit,
          where: {
            post: { id: selectPost },
            ...(startTime && { createdAt_lte: startTime }),
            ...(commentSearchInput !== '' && { searchTerm: commentSearchInput })
          },
          orderBy: 'RECENT',
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            const { commentThreadModeration: newCommentsData } = fetchMoreResult 
            if (newCommentsData?.length < limit) {
              setCommentsEndReached(true)
            }
          }
        },

      })

      const commentListEl = document.querySelector('.live-comment-list .virtuoso')
      let lastScrollHeight
      if (commentListEl) {
        lastScrollHeight = commentListEl?.scrollHeight
      }

      if (res?.data?.commentThreadModeration) {
        const { data: { commentThreadModeration: newCommentsData } = {} } = res
        if (newCommentsData?.length > 0) {
          let newComments = [...newCommentsData]
          newComments = newComments?.reverse()
          setComments([...newComments, ...comments])
        }
      }
      setTimeout(() => { //scrollto middle when new item addded
        if (commentListEl) {
          commentListEl.scrollTop += (commentListEl?.scrollHeight - lastScrollHeight)
        }
      }, 200);

    } catch (error) {
      handleCatchError(error)
    } finally {
      setLoadingMoreComments(false)
    }

  }

  useEffect(() => {
    if (skipComment !== 0) {
      loadMoreComments()
    }

  }, [skipComment])

  function handleCommentListScroll(e) {

    if (isCommentLoading || commentsEndReached) {
      return
    }

    if (scrollCommentDebounceJob) {
      scrollCommentDebounceJob.cancel()
    }

    const { target } = e
    const { scrollTop, offsetHeight, scrollHeight } = target || {}

    scrollCommentDebounceJob = debounce(() => {
      if (scrollTop === 0) {
        setSkipComment(skipComment + 1)
      }
    }, 300)

    scrollCommentDebounceJob()
  }

  useEffect(() => {
    const commentChannel = pusher.subscribe(`COMMENT_MODERATION_EVENT.${selectPost}`);
    commentChannel.bind('UPDATED', commentUpdateHandler);
    commentChannel.bind('CREATED', commentCreateHandler);
    commentChannel.bind('USER_COMMENT_HIDDEN', userCommentHidden)

    return () => {
      commentChannel.unbind('UPDATED', commentUpdateHandler);
      commentChannel.unbind('CREATED', commentCreateHandler);
      commentChannel.unbind('USER_COMMENT_HIDDEN', userCommentHidden)
      pusher.unsubscribe(`COMMENT_MODERATION_EVENT.${selectPost}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPost])

  const commentUpdateHandler = (data, metaData) => {
    if (data?.comment?.node) {
      const id = commentsRef?.current?.findIndex((comment) => comment?.id === data?.comment?.node?.id)

      if (id !== -1) {
        const newComments = cloneDeep(commentsRef?.current)
        let activityLogs = [...newComments[id]?.activityLogs, ...data?.comment?.node?.activityLogs]
        activityLogs = uniqBy(activityLogs, 'id') //removing log if it is already in the list
        newComments[id] = { ...newComments[id], ...data?.comment?.node, activityLogs: activityLogs }
        setComments([...newComments])
      }
    }
  }

  const commentCreateHandler = (data, metaData) => {
    if (commentSearchInputRef?.current === '' && data?.comment?.node?.post) {
      if (selectPostRef?.current === data?.comment?.node?.post?.id) {
        const commentListEl = document.querySelector('.live-comment-list .virtuoso')
        setComments([...commentsRef?.current, data?.comment?.node])
        if (commentListEl) {
          setTimeout(() => { //scrollto bottom when new item addded
            if (commentListEl?.scrollTop + commentListEl?.offsetHeight >= commentListEl?.scrollHeight - 300) {
              commentListEl.scrollTop = commentListEl?.scrollHeight
            }
          }, 300);
        }
      }
    }
  }

  const userCommentHidden = (data, metaData) => {
    if (data?.postId === selectPostRef?.current) {
      let newComments = commentsRef?.current?.map(comment => {
        if (get(comment, 'createdBy.id') === data?.userId) {
          comment.isHidden = true
        }
        return comment
      })
      setComments(newComments)
    }
  }

  function handleCommentHide(e, comment) {
    e.stopPropagation()
    let value = get(comment, 'isHidden')
    let title = `Are you sure, you want to ${value ? 'unhide' : 'hide'} this comment?`
    let okText = value ? 'Unhide' : 'Hide'
    let okType = value ? 'primary' : 'danger'
    let notificationMessage
    let queryVariables = { where: { id: get(comment, 'id') } }
    queryVariables = { ...queryVariables, data: { isHidden: !value } }
    notificationMessage = `Comment has been ${value ? 'unhidden' : 'hidden'}`

    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          await chatClient.mutate({ mutation: UPDATE_COMMENT_MODERATION, variables: queryVariables })
          openNotification('success', notificationMessage)
        }
        catch (error) {
          handleCatchError(error)
        }
      }

    })
  }

  function handleHideAllComment(e, comment) {
    e.stopPropagation()
    let userId = get(comment, 'createdBy.id')
    let title = `Are you sure, you want to hide all comments by this user?`
    let okText = 'Hide'
    let okType = 'danger'
    let notificationMessage
    let queryVariables = { where: { user: { id: userId }, post: { id: selectPost } } }
    notificationMessage = `All Comments has been hidden by this user`

    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          await chatClient.mutate({ mutation: HIDE_ALL_COMMENTS_BY_USER, variables: queryVariables })
          openNotification('success', notificationMessage)
        }
        catch (error) {
          handleCatchError(error)
        }
      }

    })
  }

  function handleBlockUser(e, comment) {
    e.stopPropagation()
    let userId = get(comment, 'createdBy.id')
    let title = `Are you sure, you want to block this user?`
    let okText = 'Block'
    let okType = 'danger'
    let queryVariables = { where: { id: userId } }

    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          const res = await chatClient.mutate({ mutation: BAN_USER, variables: queryVariables })
          if (res && get(res, 'data.banUser.message')) {
            openNotification('success', get(res, 'data.banUser.message'))
          }
        }
        catch (error) {
          handleCatchError(error)
        }
      }

    })
  }

  if (commentsError) return `Error ${commentsError.message}`

  return (
    <Row>
      <Col className="live-comment-list" onScroll={handleCommentListScroll}>
        {loadingMoreComments &&
          <Col className="d-flex justify-content-center">
            <div className="top-fetch-more-loader">
              <Spin indicator={<Icon type="loading" className="moderation-spinner-size" spin />} />
            </div>
          </Col>
        }
        {commentsLoading
          ? <Spinner />
          : comments &&
          <Virtuoso
            ref={virtuoso}
            className={`virtuoso ${comments?.length === 0 ? 'height-0' : ''}`}
            overscan={100}
            totalCount={comments?.length}
            item={index => {
              const comment = comments?.[index]
              if (!comment) {
                return null
              }
              return <LiveComment
                key={comment?.id}
                index={index}
                comment={comment}
                handleCommentHide={handleCommentHide}
                handleHideAllComment={handleHideAllComment}
                handleBlockUser={handleBlockUser}
                canMutate={canMutate}
              />
            }}
          />
        }
      </Col>
    </Row>
  )

}