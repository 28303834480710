import React from 'react'
import { Row, Col, Avatar, Icon, Button, Divider, Tooltip } from 'antd'
import moment from 'moment'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { capitalizeWord } from '../../../../common/utility'

function Comment(props) {
  const { comment, handleCommentReplayShow, handleCommentActions, canMutate, index } = props
  return (
    <Row>
      <Col className={`comment ${comment?.isDeleted ? 'is-deleted' : ''}`}>
        <Row type="flex" gutter={8} align="middle">
          <Col className="align-self-flexstart">
            {comment?.createdBy && <Avatar src={comment?.createdBy?.profileImage ?? ''}>{comment?.createdBy?.firstName?.charAt(0) ?? ''}</Avatar>}
          </Col>
          <Col className="flex-1">
            {comment?.createdBy &&
              <span className="comment-user-name">{comment?.createdBy?.firstName ?? ''} {comment?.createdBy?.lastName ?? ''}</span>
            }
            <span className="comment-create-time"> {comment?.createdAt && <Tooltip title={`${moment(comment?.createdAt).tz('America/Los_Angeles').format('Do MMMM, hh:mm A')} PST`}>{moment(comment?.createdAt).tz('America/Los_Angeles').fromNow()}</Tooltip>}</span>
          </Col>
          <Col className="ml-auto comment-moderation-icons">
            <Icon type="control" /> <span>{comment?.moderationScore && (comment?.moderationScore * 100).toFixed(2)}%</span>
          </Col>
          <Col className="comment-moderation-icons">
            <Icon type="flag" /> <span>{comment?.flagCount}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="comment-text">{comment?.comment}</p>
          </Col>
          {comment?.mediaURL &&
            <Col>
              <img src={comment?.mediaURL} alt={comment?.mediaURL} className="comment-media" />
            </Col>
          }
          {comment?.giphyId &&
            <Col>
              <img src={`https://media1.giphy.com/media/${comment?.giphyId}/200.gif`} alt={comment?.giphyId} className="comment-media" />
            </Col>
          }
          <Col>
            <Row type="flex" gutter={8} align="middle" className="comment-actions-icons">
              {comment?.stars?.length > 0 &&
                <Col>
                  <Icon type="star" theme="filled" className="star-icon-colored" /> <span className="star-wrapper">Starred by {comment?.stars?.[0]?.firstName ?? ''} {comment?.stars?.[0]?.lastName ?? ''} {comment?.stars?.length > 1 && `and ${comment?.starCount - 1} other`} </span>

                  {comment?.stars?.slice(0, 3).map(person => <Avatar key={person?.id} size="small" src={person?.profileImage ?? ''}>{person?.firstName?.charAt(0) ?? ''}</Avatar>)}
                </Col>}
              <Col className="replay-count-wrapper" onClick={(e) => handleCommentReplayShow(comment)}><Icon type="message" />  <span>{comment?.replyCount}</span></Col>
            </Row>
          </Col>
          <Divider className="post-page-divider" />
          <Col>
            <Row type="flex" gutter={8} className="comment-actions-icons">
              <Col> <Icon type="like" /> <span>{comment?.likeCount}</span></Col>
              <Col> <Icon type="star" /> <span>{comment?.starCount}</span></Col>
              <Col>
                <CopyToClipboard text={`${window?.location?.host}/moderation/comments/${comment?.id}`}>
                  <Tooltip title="Copied!" trigger={"click"} onClick={(e)=> e?.stopPropagation()}>
                    <span>
                      <Tooltip title="Click to copy!" placement="bottom">
                        <Icon type="link" /> <span>Copy Link</span>
                      </Tooltip>
                    </span>
                  </Tooltip>
                </CopyToClipboard>
              </Col>
            </Row>
            <Row className="moderator-action-wrapper">
              <Col lg={12} xl={16}>
                <ul className="moderator-action-list">
                  {comment?.activityLogs?.map((log, i) => <li key={log?.id}>{`${log?.updatedBy && capitalizeWord(log?.updatedBy?.role)} ${log?.updatedBy?.firstName ?? ''} ${log?.updatedBy?.lastName ?? ''} ${log?.type && capitalizeWord(log?.type)} ${log?.createdAt && moment(log?.createdAt).tz('America/Los_Angeles').format('MM/DD/YYYY h:mm a')} `}</li>)}
                </ul>
              </Col>
              {canMutate &&
                <Col lg={12} xl={8}>
                  <div className="comment-action-btn-wrapper">
                    <Button id={`btn-comment-hightlight-${index}`} className={comment?.isHighlighted ? 'moderation-action-btn' : ''} onClick={(e) => handleCommentActions(e, comment, 'isHighlighted')}><span ><Icon type="highlight" /> Highlight</span></Button>
                    <Button id={`btn-comment-hidden-${index}`} className={comment?.isHidden ? 'moderation-action-btn' : ''} onClick={e => handleCommentActions(e, comment, 'isHidden')} ><span ><Icon type="eye-invisible" /> Hidden</span></Button>
                    <Button id={`btn-comment-accept-${index}`} className={comment?.isAccepted ? 'moderation-action-btn' : ''} onClick={e => handleCommentActions(e, comment, 'isAccepted')}><span ><Icon type="check" /> Accept</span></Button>
                  </div>
                </Col>
              }
            </Row>
          </Col>
        </Row>
      </Col>
    </Row >
  )
}

export default Comment