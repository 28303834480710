import gql from "graphql-tag"


export const UPDATE_POST = gql`
  mutation updatePost($data: PostUpdateInput!, $where: PostWhereUniqueInput!){
    updatePost(data: $data, where: $where) {
      id
    }
  }
`
export const UPDATE_COMMENT_MODERATION = gql`
  mutation updateCommentModeration($where: CommentWhereUniqueInput!,$data: updateCommentModerationDataInput!){
    updateCommentModeration(where: $where, data: $data) {
      id
      isHidden
      isDeleted
      isHighlighted
      isAccepted
    }
  }
`