import React, { useContext } from 'react'
import { Layout, Menu, Icon, Dropdown, Avatar, Modal, notification } from 'antd'
import { NavLink } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { AppContext } from '../../AppContext'
import client from '../../apollo'
import { CHANGE_PASSWORD } from '../graphql/Queries'

const { Header } = Layout
const confirm = Modal.confirm

export default function (props) {
  const { state } = useContext(AppContext)
  const { collapsed, toggle, pathname } = props //authorChange
  const moderationMenuItem = pathname?.split('/')?.[2]
  const selectedMenu = pathname?.split('/')?.[1]

  function moderationMenu() {
    return (<div>
      <Menu mode="horizontal" selectedKeys={[moderationMenuItem]} className='moderationMenu'>
        <Menu.Item key="comments"
          className={`moderationMenuItem ${moderationMenuItem && moderationMenuItem === 'comments' ? 'ant-menu-item-selected' : ''} `} >
          <NavLink to="/moderation/comments" className="nav-text">
            <span>Comments</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="live"
          className={`moderationMenuItem ${moderationMenuItem && moderationMenuItem === 'live' ? 'ant-menu-item-selected' : ''} `}>
          <NavLink to="/moderation/live" className="nav-text">
            <span>Live</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="posts"
          className={`moderationMenuItem ${moderationMenuItem && moderationMenuItem === 'posts' ? 'ant-menu-item-selected' : ''} `}>
          <NavLink to="/moderation/posts" className="nav-text">
            <span>Posts</span></NavLink>
        </Menu.Item>
      </Menu>
    </div>)

  }

  if (selectedMenu && selectedMenu === 'moderation') {
    return <Header className="header">{moderationMenu()}</Header>
  } else {
    return null;
  }
}
