import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Spinner from '../../components/loaders/Spinner'
import { useAuth0 } from './Auth0'

export default withRouter(function ({ history }) {
  const { loading, isAuthenticated } = useAuth0()
  useEffect(() => {
    if (!loading && isAuthenticated !== undefined && isAuthenticated)
      history.replace('/')
  }, [loading, isAuthenticated, history])
  return (
    <div className="callback-wrapper">
      <Spinner />
    </div>
  )
})
