import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col, Icon, Spin, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { chatClient } from '../../../../apollo'
import { GET_EPISODE, GET_VIDEO, LIST_POST_MODERATION, GET_POST } from '../graphql/Queries'
import * as Sentry from '@sentry/browser'
import { get, debounce, cloneDeep, } from 'lodash'
import LivePost from './LivePost'
import { UPDATE_POST } from '../graphql/Mutations'
import { usePusher } from '../../../../pusher'
import { handleRequestFailWithNotification, openNotification } from '../../../../common/utility'
import Spinner from '../../../../components/loaders/Spinner'
import { Virtuoso } from 'react-virtuoso'

const limit = 10
let scrollPostDebounceJob;

const { confirm } = Modal

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

const orderByString = (category, type) => {
  let orderBy = ''
  switch (category) {
    case 'comments':
      orderBy = 'commentsCount_'
      break;
    case 'views':
      orderBy = 'viewsCount_'
      break;
    case 'plays':
      orderBy = 'playsCount_'
      break;
    default:
      break;
  }

  if (orderBy !== '') {
    if (!type || type === 'Highest') {
      orderBy = `${orderBy}DESC`
    } else {
      orderBy = `${orderBy}ASC`
    }
  }
  return orderBy
}

function LivePostList(props) {
  const { match: { params: { id } = {} } = {}, authorSearch, dateRangeSearch, sortBy, filterBy, handleShowPostComments, openEditPost, selectPost, setSelectPost, postForEdit, startTime, hasFilterApplied } = props
  const pusher = usePusher()
  const [posts, _setPosts] = useState([])
  const [postsDataLoading, setPostsDataLoading] = useState(false)
  const [loadingMorePosts, setLoadingMorePosts] = useState(false)
  const [postsEndReached, setPostsEndReached] = useState(false)
  const postsRef = React.useRef(posts)     // not able to access state in pusher event listner
  const [skipPost, setSkipPost] = useState(0)
  const [initialVariable, setInitialVariable] = useState({
    first: limit,
    where: {
      isLive: true,
      ...(startTime && { createdAt_lte: startTime }),
      ...(!id && authorSearch && { author: { id: authorSearch } }),
      ...(!id && dateRangeSearch && { createdAt_gte: dateRangeSearch[0], createdAt_lte: dateRangeSearch[1] }),
      ...(id && { id: id })
    },
    orderBy: (!id && filterBy) ? orderByString(filterBy, sortBy) : 'createdAt_DESC'
  })

  useEffect(() => {        // stop call api twice
    if (sortBy && !filterBy) {
      return
    }
    setInitialVariable({
      first: limit,
      where: {
        isLive: true,
        ...(startTime && { createdAt_lte: startTime }),
        ...(!id && authorSearch && { author: { id: authorSearch } }),
        ...(!id && dateRangeSearch && { createdAt_gte: dateRangeSearch[0], createdAt_lte: dateRangeSearch[1] }),
        ...(id && { id: id })
      },
      orderBy: (!id && filterBy) ? orderByString(filterBy, sortBy) : 'createdAt_DESC'
    })

  }, [id, authorSearch, dateRangeSearch, filterBy, sortBy])


  const setPosts = data => {
    postsRef.current = data
    _setPosts(data)
  };

  useEffect(() => {
    const postChannel = pusher.subscribe('POST_EVENT');
    postChannel.bind('UPDATED', postUpdateHandler);
    postChannel.bind('CREATED', postCreateHandler);
    return () => {
      postChannel.unbind('UPDATED', postUpdateHandler);
      postChannel.unbind('CREATED', postCreateHandler);
      pusher.unsubscribe('POST_EVENT')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSkipPost(0)
    setPostsEndReached(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, filterBy, dateRangeSearch, sortBy, authorSearch])

  const postCreateHandler = (data, metaData) => {
    if (!id && !hasFilterApplied?.current && data?.post?.node) {
      setPosts([data?.post?.node, ...postsRef?.current])
    }
  }

  const postUpdateHandler = (data, metaData) => {
    if (data?.post?.node) {
      const id = postsRef?.current?.findIndex((post) => post?.id === data?.post?.node?.id)

      if (id !== -1) {
        const newPosts = cloneDeep(postsRef?.current)
        newPosts[id] = data?.post?.node
        setPosts([...newPosts])
      }
    }
  }

  useEffect(() => {
    const commentChannel = pusher.subscribe('COMMENT_MODERATION_EVENT');
    commentChannel.bind('CREATED', commentCreateHandler);

    return () => {
      commentChannel.unbind('CREATED', commentCreateHandler);
      pusher.unsubscribe('COMMENT_MODERATION_EVENT')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const commentCreateHandler = (data, metaData) => {
    if (data?.comment?.node?.post) {
      let index = postsRef?.current?.findIndex(post => post?.id === data?.comment?.node?.post?.id)
      if (index !== -1) {
        const newPosts = cloneDeep(postsRef?.current)
        let newCommentsCount = newPosts[index]?.commentsCount + 1
        newPosts[index] = { ...newPosts[index], commentsCount: newCommentsCount }
        setPosts(newPosts)
      }
    }
  }

  const {
    data,
    loading: isPostLoading,
    error: postsError,
    fetchMore: fetchMorePosts,
    networkStatus: postsNetworkStatus,
  } = useQuery(LIST_POST_MODERATION, {
    client: chatClient,
    variables: initialVariable,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (isPostLoading) {
      setPostsDataLoading(true)
    }
    if (!isPostLoading && postsError) {
      setPostsDataLoading(false)
    }
    if (!isPostLoading && data?.listPostModeration) {
      setPostsDataLoading(true)
      setPosts(data?.listPostModeration)
      if (data?.listPostModeration?.length > 0) {
        setSelectPost(data?.listPostModeration?.[0]?.id)
      } else {
        setSelectPost(null)
      }
      setPostsDataLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostLoading])

  useEffect(() => {
    if (skipPost !== 0) {
      loadMorePosts()
    }
  }, [skipPost])

  async function loadMorePosts() {
    try {
      setLoadingMorePosts(true)
      const res = await fetchMorePosts({
        variables: {
          skip: skipPost * limit,
          first: limit,
          where: {
            isLive: true,
            ...(startTime && { createdAt_lte: startTime }),
            ...(authorSearch && { author: { id: authorSearch } }),
            ...(dateRangeSearch && { createdAt_gte: dateRangeSearch[0], createdAt_lte: dateRangeSearch[1] }),
            ...(id && { id: id })
          },
          orderBy: filterBy ? orderByString(filterBy, sortBy) : 'createdAt_DESC'
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            const { listPostModeration: newPostsData } = fetchMoreResult
            if (newPostsData?.length < limit) {
              setPostsEndReached(true)
            }
          }
        },

      })

      if (res?.data?.listPostModeration) {
        const { data: { listPostModeration: newPostsData } = {} } = res
        if (newPostsData?.length > 0) {
          setPosts([...posts, ...newPostsData])
        }
      }

    } catch (error) {
      handleCatchError(error)

    } finally {
      setLoadingMorePosts(false)
    }

  }

  function handlePostListScroll(e) {

    if (scrollPostDebounceJob) {
      scrollPostDebounceJob.cancel()
    }

    const { target } = e
    const { scrollTop, offsetHeight, scrollHeight } = target || {}

    scrollPostDebounceJob = debounce(() => {
      let scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5

      if (scrolledToBottom && !postsEndReached && scrollTop !== 0) {
        setSkipPost(skipPost + 1)
      }
    }, 300)

    scrollPostDebounceJob()

  }

  function showPostHiddenConfirm(id, isHidden) {
    confirm({
      title: `Are you sure, you want to ${!isHidden ? 'hide' : 'unhide'} this post?`,
      okText: !isHidden ? 'Hide' : 'Unhide',
      okType: !isHidden ? 'danger' : 'primary',
      async onOk() {
        try {
          const res = await chatClient.mutate({ mutation: UPDATE_POST, variables: { data: { isDeleted: !isHidden }, where: { id: id } } })
          if (res?.data?.updatePost) {
            let newPosts = cloneDeep(posts)
            let id = newPosts?.findIndex((post) => post?.id === res?.data?.updatePost?.id)
            if (id !== -1) {
              newPosts[id].isDeleted = !isHidden
            }
            setPosts(newPosts)
          }
          openNotification('success', `Post has been ${!isHidden ? 'hidden' : 'Unhidden'}`)
        }
        catch (error) {
          handleCatchError(error)
        }
      },
    })
  }

  if (postsError) return `Error! ${postsError.message}`


  return (
    <Row className="post-list-wrapper height-100" onScroll={handlePostListScroll}>
      {
        postsDataLoading || isPostLoading
          ? <Spinner />
          : posts &&
          <Virtuoso
            className="virtuoso"
            overscan={100}
            totalCount={posts?.length}
            item={index => {
              const post = posts?.[index]
              if (!post) {
                return null
              }
              return <LivePost
                key={post?.id}
                index={index}
                post={post}
                selectPost={selectPost}
                postForEdit={postForEdit}
                showPostHiddenConfirm={showPostHiddenConfirm}
                handleShowPostComments={handleShowPostComments}
                openEditPost={openEditPost} />
            }}
            footer={() => {
              if (loadingMorePosts) {
                return <Row className="d-flex justify-content-center">
                  <Col className="bottom-fetch-more-loader">
                    <Spin indicator={<Icon type="loading" className="moderation-spinner-size" spin />} />
                  </Col>
                </Row>
              }
            }}
          />
      }
    </Row>
  )
}

export default withRouter(LivePostList)