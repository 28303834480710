import gql from 'graphql-tag'

export const GET_USERS_SUBSCRIPTION = gql`
subscription user {
  user {
    mutation
    node{
      id
      firstName
      lastName
      email
      role
      isActive
      profileImage
    }
  }
}
`

export const GET_PERSONS_SUBSCRIPTION = gql`
subscription person {
  person {
    mutation
    node{
      id
      email
      firstName
      userName
      profileImage
      lastName
      authZeroID
      recurlyId
      recurlyPlan
      promoPlan
      promoPlanExpiration
      role
      isActive
      createdAt
      updatedAt
    }
  }
}
`
