import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Layout, Menu, Icon, Avatar, Dropdown, Modal, notification } from 'antd'
import { find } from 'lodash';
import { AppContext } from '../../AppContext'
import client from '../../apollo'
import { CHANGE_PASSWORD } from '../graphql/Queries'
import * as Sentry from '@sentry/browser'
import { get } from 'lodash'
import { PERMISSION_KEYS, TEMPLATES_NAME } from '../../common/constants';

const { Sider } = Layout

const confirm = Modal.confirm
const { SubMenu } = Menu;

function openNotification(type, message) {
  notification[type]({
    message
  })
}

function handleRequestFail() {
  openNotification("error", "Something Went Wrong")
}
export default class LayoutSider extends Component {
  constructor(props) {
    super(props);
    this.changePassword = this.changePassword.bind(this);
  }

  changePassword = async (state) => {
    confirm({
      title: 'Are you sure you want to change your password?',
      okText: 'Change Password',
      async onOk() {
        try {
          await client.mutate({
            mutation: CHANGE_PASSWORD,
            variables: { email: state?.currentUser?.email }
          }).then((result) => {
            if (result?.data?.changePassword?.message) {
              openNotification('success', result?.data?.changePassword?.message)
            }
          }).catch((e) => {
            handleRequestFail()
          })
        }
        catch (e) {
          Sentry.captureException(e)
          handleRequestFail()
        }
      },
    });
  };

  render() {
    const { collapsed, pathname, role, toggle } = this.props
    const selectedItem = pathname?.split('/')?.[1]
    const { state } = this.context;

    const { currentPersonPermission = [] } = state;
    const userPermission = find(currentPersonPermission, ['key', PERMISSION_KEYS.USER_MANAGMENT]);
    const moderationPermission = find(currentPersonPermission, ['key', PERMISSION_KEYS.MODERATION]);

    return (
      <Sider
        trigger={<Trigger collapsed={collapsed} toggle={toggle} />}
        width={250}
        collapsible
        collapsed={collapsed}
        theme="light"
      >
        <NavLink to="/" className="nav-text">
          <div className="logo">
            <img src={collapsed ? "https://via.placeholder.com/51x25.png" : "https://via.placeholder.com/212x34.png"} alt="Demo" title="Demo" />
          </div >
        </NavLink >
        <Menu mode="vertical" selectable={false}>
          {role && role in TEMPLATES_NAME && userPermission?.canRead && (
            <Menu.Item
              key="user-management"
              className={`menu-list ${selectedItem === 'user-management'
                ? 'ant-menu-item-selected'
                : ''
                }`}

            >
              <NavLink to="/user-management" className="nav-text">
                <Icon type="user" />
                <span>User Management</span>
              </NavLink>
            </Menu.Item>
          )}
          {
            role && role in TEMPLATES_NAME && moderationPermission?.canRead && (
              <Menu.Item
                key="moderation"
                className={`menu-list ${selectedItem === 'moderation' ? 'ant-menu-item-selected' : ''}`}
              >
                <NavLink to="/moderation/comments" className="nav-text">
                  <Icon type="filter" />
                  <span>Moderation</span>
                </NavLink>
              </Menu.Item>
            )
          }
          <Menu.SubMenu
            key="user"
            className={`menu-list`}
            title={
              <div>
                <Avatar src={state?.currentUser?.profileImage}>{get(state, 'currentUser.firstName', '')?.charAt(0)}</Avatar>
                {!collapsed && get(state, 'currentUser.firstName', '')} {!collapsed && get(state, 'currentUser.lastName', '')}
              </div>}
          >
            {
              state?.currentUser?.email && (
                <Menu.Item>
                  {/* eslint-disable-next-line */}
                  <a rel="noopener noreferrer" onClick={() => this.changePassword(state)}>Change Password</a>
                </Menu.Item>
              )}
            <Menu.Item>
              <NavLink to="/logout" className="nav-text">
                Logout
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu >
      </Sider >
    );
  }
}

function Trigger({ collapsed, toggle }) {
  return (<Icon
    className="trigger"
    type={collapsed ? 'menu-unfold' : 'menu-fold'}
    onClick={toggle}
  />)
}

LayoutSider.contextType = AppContext;
