import gql from 'graphql-tag'

export const LIST_COMMENTS_MODERATION = gql`
  query listCommentsModeration(
    $where: listCommentsModerationWhereInput, 
    $orderBy: listCommentsModerationOrderBy,
    $skip: Int,
    $first: Int,
    $after: String,
    $before: String
    ) {
    listCommentsModeration(where: $where,orderBy: $orderBy,skip: $skip,first: $first,after: $after,before: $before) {
      id
      comment
      isHidden
      isDeleted
      isAccepted
      isHighlighted
      likeCount
      starCount
      flagCount
      replyCount
      createdBy {
        id
        userName
        firstName
        lastName
        profileImage
      }
      moderationResponse
      moderationScore
      moderationLabel
      activityLogs {
        id
        referenceId
        referenceType
        action
        title
        content
        type
        createdAt
        updatedBy {
          id
          userName
          firstName
          lastName
          role
        }
      }
      createdAt
      post {
        id
      }
      parent {
        id
      }
      mediaType
      mediaURL
      giphyId
      mediaModerationResponse
      mediaModerationError
    }
  }
`;

export const GET_POST = gql`
  query getPostModeration($id: ID!) {
    getPostModeration(where: { id: $id }) {
      id
      isLive
      image
      video {
        id
        thumbnail
        status
        videoState
        videoURL
        duration
        videoGIF
      }
      text
      referenceType
      referenceId
      referenceSlug
      createdBy {
        id
        firstName
        lastName
        profileImage
      }
      createdAt
    }
  }
`
export const GET_VIDEO = gql`
  query video($id: ID!) {
    video(where: { id: $id }) {
      id
      name
      description
      videoNumber
      image
      logoImage
      thumbnail
      videoURL
      createdBy {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`
export const GET_EPISODE = gql`
  query episode($id: ID!) {
    episode(where: { id: $id }) {
      id
      title
      image
      show {
        id
        name
      }
      createdBy {
        id
        firstName
        lastName
      }
      scheduleAt
      createdAt
    }
  }
`

export const COMMENT_THREAD_MODERATION = gql`
  query commentThreadModeration(
    $where: commentThreadModerationWhereInput!,
    $orderBy: commentThreadModerationOrderBy,
    $skip: Int,
    $first: Int,
    $after: String,
    $before: String) {
    commentThreadModeration(
      where: $where,
      orderBy: $orderBy,
      skip: $skip,
      first: $first,
      after: $after,
      before: $before) {
      id
      comment
      isHidden
      isDeleted
      isAccepted
      isHighlighted
      likeCount
      starCount
      flagCount
      replyCount
      stars {
        id
        firstName
        lastName
        profileImage
      }
      createdBy {
        id
        userName
        firstName
        lastName
        profileImage
      }
      moderationResponse
      moderationScore
      moderationLabel
      activityLogs {
        id
        referenceId
        referenceType
        action
        title
        content
        type
        createdAt
        updatedBy {
          id
          userName
          firstName
          lastName
          role
        }
      }
      createdAt
      parent {
        id
      }
      mediaType
      mediaURL
      giphyId
    }
  }
`