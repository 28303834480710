import React, { Component } from "react"
import { Modal, Button, Form } from "antd"
import EditEmailForm from "./EditEmailForm"

const CollectionCreateForm = Form.create({ name: 'edit_update_email' })(
  // eslint-disable-next-line
  class extends React.Component {
    isChangeEmail = false

    changeEmail = (flag) => {
      this.isChangeEmail = flag
    }

    render() {
      const { showModal, isSubmit, handleCancelEmail, handleOkEmail, form } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={showModal}
          maskClosable={false}
          title={"Change User Email"}
          onCancel={handleCancelEmail}
          onOk={handleOkEmail}
          footer={[
            <Button id="btn-email-cancel" key="back" onClick={handleCancelEmail}>Cancel</Button>,
            <Button id="btn-email-save" disabled={!this.isChangeEmail} key="submit" type="primary"
              loading={isSubmit} onClick={handleOkEmail}>
              Update Email
            </Button>
          ]}
        >
          <EditEmailForm
            getFieldDecorator={getFieldDecorator}
            onGetNewEmail={this.changeEmail}
            {...this.props}
          />
        </Modal>
      )
    }
  }
)

export default class EditEmailModal extends Component {
  render() {
    const { saveFormRef } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        {...this.props}
      />
    );
  }
}
