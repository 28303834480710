import React, { useState } from 'react'
import { Row, Col, Avatar, Button, Icon, Divider, Tooltip } from 'antd'
import moment from 'moment'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReferenceDataRender from './ReferenceDataRender'
import PlayerModal from './PlayerModal'

function Post(props) {
  const {
    post,
    selectPost,
    showPostHiddenConfirm,
    handleShowPostComments,
    openEditPost,
    postForEdit,
    canMutate,
    authorPostCanMutate,
    shared,
    index } = props
  const [showPlayerModal, setShowPlayerModal] = useState(false)

  function handleOk(e) {
    setShowPlayerModal(false)
  }

  function handleModal() {
    setShowPlayerModal(true)
  }

  return (
    <Row>
      <Col
        id = {`div-show-post-${index}`}
        className={`post  ${(selectPost && selectPost === post?.id) || (postForEdit && postForEdit === post?.id) ? 'selected-post' : ''}`} 
        onClick={() => handleShowPostComments ? handleShowPostComments(post?.id): null}
      >
        <Row type="flex" gutter={16} className="flex-nowrap">
          <Col>
            {post?.createdBy && <Avatar size="large" src={post?.createdBy?.profileImage ?? ''}>{post?.createdBy?.firstName?.charAt(0) ?? ''}</Avatar>}
          </Col>
          <Col>
            {post?.createdBy && <div className="post-user-name" >{post?.createdBy?.firstName ?? ''} {post?.createdBy?.lastName ?? ''}</div>}
            <div className="post-create-time">
              {post?.createdAt &&
                <Tooltip
                  title={
                    `${moment(post?.createdAt).tz('America/Los_Angeles').format('Do MMMM, hh:mm A')} PST`
                  }
                >
                  {moment(post?.createdAt).tz('America/Los_Angeles').fromNow()}
                </Tooltip>
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="post-text">{post?.text}</p>
          </Col>
        </Row>
        {post?.video &&
          <Row>
            <Col className="video-container">
              <PlayerModal
                showPlayerModal={showPlayerModal}
                handleOk={handleOk}
                post={post}
              />
              <img src={post?.video?.videoGIF} alt={post?.video?.id} className="video-gif" />
              {(post?.video?.videoGIF || post?.video?.thumbnail) && <Button id={`btn-post-video-play-${index}`} icon="play-circle" shape="circle" className="video-play-button" size="large" onClick={handleModal}  ></Button>}
            </Col>
          </Row>
        }
        {post?.image && <Row>
          <Col>
            <div className="post-page-media-container">
              <img src={post?.image} alt={post?.image} />
            </div>
          </Col>
        </Row>}
        {post?.referenceType && <ReferenceDataRender type={post?.referenceType} data={post?.referenceData} />}
        {!shared &&
          (<>
            <Divider className="post-page-divider" />
            <Row type="flex" gutter={16}>
              <Col className="post-user-action-icons">
                <Icon type="like" /> <span>{post?.likesCount}</span>
              </Col>
              <Col className="post-user-action-icons">
                <span><Icon type="message" /> <span>{post?.commentsCount}</span></span>
              </Col>
              <Col className="post-user-action-icons">
                <Icon type="flag" /> <span>{post?.totalFlagCount || 0}</span>
              </Col>
              <Col className="ml-auto post-action-icons">
                <CopyToClipboard text={`${window?.location?.href}/${post?.id}`}>
                  <Tooltip title="Copied!" trigger={"click"} onClick={(e)=> e?.stopPropagation()}>
                    <span id={`post-copylink-${index}`}>
                      <Tooltip title="Click to copy!" placement="bottom">
                        <Icon type="link" /> <span>Copy Link</span>
                      </Tooltip>
                    </span>
                  </Tooltip>
                </CopyToClipboard>
              </Col>
            </Row>
            <Divider className="post-page-divider" />
            <Row type="flex" gutter={8} justify="center">
              <Col className="post-action-icons">
                <Icon type="eye" /> <span>{post?.viewsCount}</span>
              </Col>
              {post.video && <Col className="post-action-icons">
                <Icon type="play-circle" /> <span>{post?.playsCount}</span>
              </Col>}
              <Col className="ml-auto">
                {canMutate && authorPostCanMutate &&
                  <Button
                    id={`btn-post-edit-${index}`}
                    onClick={(e) => {
                      e.stopPropagation()
                      openEditPost(post?.id)
                    }}
                  >
                    <Icon type="edit" />Edit
                  </Button>
                }
              </Col>
              <Col>
                {canMutate && authorPostCanMutate &&
                  <Button
                    id={`btn-post-hidden-${index}`}
                    className={post?.isDeleted ? 'post-hidden-action-btn' : ''}
                    onClick={(e) => {
                      e.stopPropagation()
                      showPostHiddenConfirm(post?.id, post?.isDeleted)
                    }}
                  >
                    <Icon type="eye-invisible" /> Hidden
                  </Button>
                }
              </Col>
            </Row>
          </>)
        }
      </Col>
    </Row>
  )
}

export default Post