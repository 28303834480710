import React from 'react'
import { Route } from 'react-router-dom'
import Login from './'
import Callback from './Callback'
import { useAuth0 } from './Auth0';

function GuardedRoute(props) {
  const { component: Component, path } = props
  const { loading, isAuthenticated } = useAuth0()
  return (
    loading ?
      <Route component={Callback} />
      :
      <Route path={path} render={(props) => {
        if (isAuthenticated !== undefined && !isAuthenticated) return <Login />
        return <Component {...props} />
      }} />
  )
}

export default GuardedRoute;
