import { notification } from 'antd'
import { truncate } from 'lodash'
import * as Sentry from '@sentry/browser'

export function openNotification(type, message) {
  notification[type]({
    message,
  })
}

export function titleCase(str = '') {
  let string = str.toLowerCase().split(' ');
  for (var i = 0; i < string.length; i++) {
    string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
  }
  return string.join(' ');
}

export function truncateName(name, option) {
  return truncate(name, option || { 'length': 90 })

}

export function handleRequestFailWithNotification(message) {
  openNotification("error", message || "Something Went Wrong")
}

export function capitalizeWord(str) {
  const lower = str.toLowerCase()
  return str.charAt(0).toUpperCase() + lower.slice(1)
}

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader?.result));
  reader.readAsDataURL(img);
};


export const UniqueArray = (array) => {
  let uniqueArray = [...new Set(array)];
  return uniqueArray
}
