import gql from "graphql-tag"


export const UPDATE_POST = gql`
  mutation updatePost($data: PostUpdateInput!, $where: PostWhereUniqueInput!){
    updatePost(data: $data, where: $where) {
      id
    }
  }
`

export const UPDATE_COMMENT_MODERATION = gql`
  mutation updateCommentModeration($where: CommentWhereUniqueInput!,$data: updateCommentModerationDataInput!){
    updateCommentModeration(where: $where, data: $data) {
      id
      isHidden
      isDeleted
      isHighlighted
      isAccepted
    }
  }
`

export const HIDE_ALL_COMMENTS_BY_USER = gql`
  mutation hideAllCommentsByUser($where: hideAllCommentsByUserWhereInput!){
    hideAllCommentsByUser(where: $where) {
      message
    }
  }
`

export const BAN_USER = gql`
  mutation banUser($where: PersonWhereUniqueInput!){
    banUser(where: $where) {
      message
    }
  }
`

export const UNBAN_USER = gql`
  mutation unbanUser($where: PersonWhereUniqueInput!){
    unbanUser(where: $where) {
      message
    }
  }
`


export const CREATE_POST = gql`
  mutation createPost($data: PostCreateInput!){
    createPost(data: $data) {
      id
      isLive
      video {
        id
        streamKey
        videoURL
      }
    }
  }
`