import React, { Component } from "react"
import { Modal, Button, Form, Row, Col, Avatar } from "antd"
import AddUserForm from "./AddUserForm"
import UserPermissionForm from "./UserPermissionForm"

const CollectionCreateForm = Form.create({ name: 'user_permissions' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { showModal, isSubmit, handleOk, form, isUpdate, handleCancelUserPermission, person } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={showModal}
          maskClosable={false}
          width="50%"
          title={
            <Row type="flex" align="bottom" className="permission-modal-header">
              <Col className="title">{isUpdate ? 'Edit User Permission' : 'Set User Permission'}</Col>
              {isUpdate && 
              <Col className="d-flex ml-auto justify-content-between user-details"> 
                <Avatar src={person?.profileImage} /> 
                <div className="user-name">{person?.firstName} {person?.lastName}</div> 
                <div> 
                  <Button disabled type="default" size="small" className="user-role">{person?.role}</Button> 
                </div>
              </Col>}
            </Row>
          }
          onCancel={() => handleCancelUserPermission(isUpdate)}
          onOk={handleOk}
          footer={[
            <Button id="btn-user-permission-cancel" key="back" onClick={() => handleCancelUserPermission(isUpdate)}>Cancel</Button>,
            <Button id="btn-user-permission-save" key="submit" type="primary" loading={isSubmit} onClick={handleOk}>
              {"Save Changes"}
            </Button>
          ]}
        >
          <UserPermissionForm
            getFieldDecorator={getFieldDecorator}
            {...this.props}
          />
        </Modal>
      )
    }
  }
)

export default class UserPermissionModal extends Component {
  render() {
    const { saveFormRef } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        {...this.props}
      />
    );
  }
}
