import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Avatar, Divider, Button, Tooltip, Spin } from 'antd'
import { GET_POST, GET_VIDEO, GET_EPISODE } from '../graphql/Queries'
import { useQuery } from '@apollo/react-hooks'
import client, { chatClient } from '../../../../apollo'
import axios from 'axios'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { get, cloneDeep } from 'lodash'
import { formatWpPost } from '../../post/FetchNewsArticle'
import ReferenceDataRender from '../../post/components/ReferenceDataRender'
import { handleRequestFailWithNotification } from '../../../../common/utility'
import PlayerModal from './PlayerModal'
import { usePusher } from '../../../../pusher'
import Spinner from '../../../../components/loaders/Spinner'

const referenceTypeQuery = {
  VIDEO: GET_VIDEO,
  EPISODE: GET_EPISODE,
  POST: GET_POST,
}

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

function Post(props) {
  const { selectComment: { post: { id } } } = props
  let image = "https://via.placeholder.com/1024x512.png"
  const pusher = usePusher()
  const [postDataLoading, setPostDataLoading] = useState(false)
  const [post, _setPost] = useState(null)
  const [showPlayerModal, setShowPlayerModal] = useState(false)
  const postRef = useRef(post)

  const setPost = (value) => {
    postRef.current = value
    _setPost(value)
  }

  function handleOk(e) {
    setShowPlayerModal(false)
  }

  function handleModal() {
    setShowPlayerModal(true)
  }

  const {
    loading: isPostLoading,
    data,
    error: postError
  } = useQuery(GET_POST, { variables: { id: id }, client: chatClient, fetchPolicy: 'network-only', skip: !id })

  useEffect(() => {
    if (isPostLoading) {
      setPostDataLoading(true)
    }
    if (!isPostLoading && data?.getPostModeration) {
      setPostDataLoading(true)
      async function setPostData() {
        try {
          const post = data?.getPostModeration
          await fetchReferenceData(post)
          setPost(post)

        } catch (error) {
          handleCatchError(error)
        } finally {
          setPostDataLoading(false)
        }
      }
      setPostData()
    }
    if (!isPostLoading && postError) {
      setPostDataLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostLoading])

  async function fetchReferenceData(post) {
    if (post?.referenceType) {

      if (post?.referenceType !== 'NEWS_ARTICLE') {
        let query = referenceTypeQuery[post?.referenceType]
        let apolloClient = post?.referenceType === 'POST' ? chatClient : client

        try {
          const res = await apolloClient.query({
            query: query,
            fetchPolicy: 'network-only',
            variables: { id: post?.referenceId },
          })

          if (res?.data) {
            if (post?.referenceType === 'POST' && res?.data?.getPostModeration) {
              let newPostModerationData = cloneDeep(res?.data?.getPostModeration)
              await fetchReferenceData(newPostModerationData)
              post.referenceData = newPostModerationData
            } else {
              post.referenceData = res?.data?.[post?.referenceType?.toLowerCase()]
            }
          }

        } catch (e) {
          handleCatchError(e)
        }
      }

      if (post?.referenceType === 'NEWS_ARTICLE' && post?.referenceSlug) {
        // NEWS_ARTICLE is not the case for now.
        // try {
        //   const response = await axios.get(`${process?.env?.REACT_APP_NEWS_ARTICLE_API_URL}?slug=${post?.referenceSlug}&_embed=1`)
        //   if (response?.status === 200) {
        //     if (response?.data?.[0]) {
        //       post.referenceData = formatWpPost(response?.data?.[0])
        //     }
        //   }

        // } catch (error) {
        //   handleCatchError(error)
        // }
      }
    }
  }

  useEffect(() => {
    const postChannel = pusher.subscribe(`POST_EVENT.${id}`);
    postChannel.bind('UPDATED', postUpdateHandler);

    return () => {
      postChannel.unbind('UPDATED', postUpdateHandler);
      pusher.unsubscribe(`POST_EVENT.${id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const postUpdateHandler = async (data, metaData) => {
    if (data?.post?.node) {
      let newData = null
      const prevPost = cloneDeep(postRef?.current)
      if (data?.post?.node?.referenceType) {
        let newPostModerationData = cloneDeep(data?.post?.node)
        await fetchReferenceData(newPostModerationData)
        newData = newPostModerationData
      } else if (
        get(prevPost, 'video.id') && get(data, 'post.node.video.id') &&
        get(prevPost, 'video.id') === get(data, 'post.node.video.id') &&
        get(prevPost, 'video.videoState') && get(data, 'post.node.video.videoState') &&
        get(prevPost, 'video.videoState') === get(data, 'post.node.video.videoState') &&
        get(prevPost, 'isLive') === false
      ) {
        //checking for same video
        let video = prevPost.video
        newData = { ...data?.post?.node, video: video }
      } else {
        newData = data?.post?.node
      }
      setPost(newData)
    }
  }

  function liveVideoRender() {
    return (get(post, 'video.videoState') === 'RECORDING' || get(post, 'video.videoState') === 'DISCONNECTED') && get(post, 'video.duration') ?
      <>
        <img src={get(post, 'video.videoGIF') ? get(post, 'video.videoGIF') : image} alt={post?.video?.id} className="video-gif" />
        <Button icon="play-circle" shape="circle" className="video-play-button" size="large" onClick={handleModal}></Button>
      </>
      : <div className="d-flex justify-content-center">
        {
          get(post, 'video.status') === 'LIVE' && get(post, 'video.videoState') !== 'DISCONNECTED'
            ?
            <Spin tip="Please Wait...">
              <img src={image} alt="live-post-video" className="video-gif" />
            </Spin>
            :
            <img src={image} alt="live-post-video" className="video-gif" />
        }
      </div>
  }

  if (postDataLoading || isPostLoading) return <Spinner />;

  if (postError) return `Error! ${postError.message}`

  return (
    <>
      <div className="post">
        <Row type="flex" gutter={16} className="flex-nowrap">
          <Col>
            {post?.createdBy && <Avatar size="large" src={post?.createdBy?.profileImage ?? ''}>{post?.createdBy?.firstName?.charAt(0) ?? ''}</Avatar>}
          </Col>
          <Col>
            {post?.createdBy && <div className="post-user-name" >{post?.createdBy?.firstName ?? ''} {post?.createdBy?.lastName ?? ''}</div>}
            {post?.createdAt &&
              <div className="post-create-time">
                <Tooltip
                  title={
                    `${moment(post?.createdAt).tz('America/Los_Angeles').format('Do MMMM, hh:mm A')} PST`
                  }
                >
                  <span>{moment(post?.createdAt).tz('America/Los_Angeles').fromNow()}</span>
                </Tooltip>
              </div>}
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="post-text">{post?.text}</p>
          </Col>
          {post?.video &&
            <Col className="video-container">
              <PlayerModal
                showPlayerModal={showPlayerModal}
                handleOk={handleOk}
                post={post}
              />
              {
                post?.isLive
                  ? liveVideoRender()
                  :
                  <>
                    <img src={get(post, 'video.videoGIF') ? get(post, 'video.videoGIF') : image} alt={post?.video?.id} className="video-gif" />
                    {(post?.video?.videoGIF || post?.video?.thumbnail) && <Button icon="play-circle" shape="circle" className="video-play-button" size="large" onClick={handleModal}></Button>}
                  </>
              }
            </Col>
          }
          {post?.image &&
            <Col>
              <div className="post-page-media-container">
                {post?.image && <img src={post?.image} alt={post?.image} />}
              </div>
            </Col>
          }
          {post?.referenceType && <ReferenceDataRender type={post?.referenceType} data={post?.referenceData} />}
        </Row>
      </div >
      <Divider className="post-page-divider" />
    </>
  )
}

export default Post