import React, { useContext } from 'react'
import { Route, Switch } from 'react-router'
import Comments from './comment'
import Posts from './post'
import Live from './live'
import './moderation.css'
import Page404 from '../../components/Page404'
import { Redirect } from 'react-router-dom'
import { AppContext } from '../../AppContext'
import { TEMPLATES_NAME } from '../../common/constants'

export default function (props) {
  const { state } = useContext(AppContext)
  const role = state?.currentUser?.role
  return (
    <Switch>
      {role && role in TEMPLATES_NAME && <Route exact path="/moderation" render={() => <Redirect to="/moderation/comments" />} /> }
      {role && role in TEMPLATES_NAME && <Route exact path="/moderation/comments" component={Comments} /> }
      {role && role in TEMPLATES_NAME && <Route exact path="/moderation/comments/:id" component={Comments} /> }
      {role && role in TEMPLATES_NAME && <Route exact path="/moderation/live" component={Live} /> }
      {role && role in TEMPLATES_NAME && <Route exact path="/moderation/live/:id" component={Live} /> }
      {role && role in TEMPLATES_NAME && <Route exact path="/moderation/posts" component={Posts} /> }
      {role && role in TEMPLATES_NAME && <Route exact path="/moderation/posts/:id" component={Posts} /> }
      <Route component={Page404} />
    </Switch>
  )
}