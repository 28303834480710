import React, { useContext, useState, useEffect, useRef } from 'react'
import { Row, Select, Col, Divider, DatePicker, Button, Modal, Tooltip, Icon } from 'antd'
import { debounce, get, truncate, find } from 'lodash'
import { withRouter } from 'react-router'
import LivePostList from './components/LivePostList'
import LiveVideo from './components/LiveVideo'
import LiveCommentList from './components/LiveCommentList'
import { chatClient } from '../../../apollo'
import { GET_LIST_AUTHOR } from './graphql/Queries'
import { useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import { CREATE_POST } from './graphql/Mutations'
import { handleRequestFailWithNotification, openNotification } from '../../../common/utility'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { AppContext } from '../../../AppContext'
import { PERMISSION_KEYS } from '../../../common/constants'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
const { confirm } = Modal

let debounceCommentSearchJob;

const FilterByOptions = [
  { name: '# of comments', value: 'comments' },
  { name: '# of views', value: 'views' },
]

const SortByOptions = [
  { name: 'Lowest', value: 'Lowest' },
  { name: 'Highest', value: 'Highest' }
]
const dateFormat = 'MM/DD/YYYY';

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

export default withRouter(function (props) {
  const { match: { params: { id } = {} } = {}, } = props
  const [filterBy, setFilterBy] = useState(null)
  const [dateRangeSearch, setDateRangeSearch] = useState(null)
  const [selectPost, _setSelectPost] = useState(null)
  const selectPostRef = React.useRef(selectPost) // not able to access state in pusher event listner
  const [sortBy, setSortBy] = useState(undefined)      // using this value in select due to this for select placeholder it needs to be undefined
  const [commentSearchInput, _setCommentSearchInput] = useState('')
  const [authorSearch, setAuthorSearch] = useState(null)
  const { state } = useContext(AppContext)
  const { currentPersonPermission = [] } = state
  const moderationPermission = find(currentPersonPermission, ['key', PERMISSION_KEYS.MODERATION])
  const { canMutate = false } = moderationPermission || {}
  const authorPostPermission = find(currentPersonPermission, ['key', PERMISSION_KEYS.AUTHOR_POSTS])
  const { canMutate: authorPostCanMutate } = authorPostPermission
  const [startTime, setStartTime] = useState(moment())
  const hasFilterApplied = useRef(false)
  const commentSearchInputRef = useRef(commentSearchInput) 

  useEffect(() => {
    if(!filterBy && !dateRangeSearch  && !authorSearch && !sortBy ){
      hasFilterApplied.current = false
    } else {
      hasFilterApplied.current = true
    }
  }, [filterBy, dateRangeSearch, authorSearch , sortBy])

  const { data: { listAuthor = [] }, loading: isAuthorLoading } = useQuery(GET_LIST_AUTHOR, { client: chatClient, fetchPolicy: 'network-only', onError:(error)=>{ handleCatchError(error) } })

  const setSelectPost = data => {
    selectPostRef.current = data
    _setSelectPost(data)
  }

  const setCommentSearchInput = data => {
    commentSearchInputRef.current = data
    _setCommentSearchInput(data)
  }

  const dateRangeChange = (data, dateString) => {
    setStartTime(moment())
    if (data && data.length > 1) {
      let day1 = data?.[0].startOf('day')
      let day2 = data?.[1].isSame(new Date(), 'day') ? data[1] : data[1].endOf('day')
      setDateRangeSearch([day1, day2])
    } else {
      setDateRangeSearch(null)
    }
  }

  const authorChange = (data) => {
    setStartTime(moment())
    setAuthorSearch(data)
  }

  const filterByChange = (data) => {
    setStartTime(moment())
    if (!data) {
      setSortBy(undefined)
    } else {
      setSortBy('Highest')
    }
    setFilterBy(data)
  }

  const sortByChange = (data) => {
    setStartTime(moment())
    setSortBy(data)
  }

  const commentSearchInputChange = (e) => {
    if (debounceCommentSearchJob) {
      debounceCommentSearchJob.cancel()
      debounceCommentSearchJob = null
    }

    debounceCommentSearchJob = debounce((searchValue) => {
      setStartTime(moment())
      setCommentSearchInput(searchValue?.trim())
    }, 1000)

    debounceCommentSearchJob(e?.target?.value)
  }

  function handleShowPostComments(id) {
    setSelectPost(id)
    setCommentSearchInput('')
  }

  function handleCreateGoLive() {
    confirm({
      title: 'Are you sure you want to go Live?',
      okText: 'Go Live',
      okType: 'primary',
      async onOk() {
        try {
          const response = await chatClient.mutate({ mutation: CREATE_POST, variables: { data: { isLive: true } } })
          openNotification('success', 'Live has been created successfully')
          Modal.success({
            title: 'Live Created',
            content: (
              <Row>
                <Col className="mb-2">RTMP URL
                  <CopyToClipboard text={'rtmp://live.mux.com/app'}>
                    <Tooltip title="Copied!" trigger={"click"} onClick={(e)=> e?.stopPropagation()}>
                      <span>
                        <Tooltip title="Click to copy!" placement="bottom">
                          <Icon type="copy" title="Click to copy!" />
                        </Tooltip>
                      </span>
                    </Tooltip>
                  </CopyToClipboard>
                  <span>rtmp://live.mux.com/app</span>
                </Col>
                <Col className="mb-2"> Video URl
                  <CopyToClipboard text={get(response, 'data.createPost.video.videoURL', '')}>
                    <Tooltip title="Copied!" trigger={"click"} onClick={(e)=> e?.stopPropagation()}>
                      <span>
                        <Tooltip title="Click to copy!" placement="bottom">
                          <Icon type="copy" title="Click to copy!" />
                        </Tooltip>
                      </span>
                    </Tooltip>
                  </CopyToClipboard>
                  <span>{truncate(get(response, 'data.createPost.video.videoURL', ''), { length: 30 })}</span>
                </Col>
                <Col> Stream key
                  <CopyToClipboard text={get(response, 'data.createPost.video.streamKey', '')}>
                    <Tooltip title="Copied!" trigger={"click"} onClick={(e)=> e?.stopPropagation()}>
                      <span>
                        <Tooltip title="Click to copy!" placement="bottom">
                          <Icon type="copy" title="Click to copy!" />
                        </Tooltip>
                      </span>
                    </Tooltip>
                  </CopyToClipboard>
                  <span>{truncate(get(response, 'data.createPost.video.streamKey', ''), { length: 30 })}</span>
                </Col>
              </Row>
            )
          });
        }
        catch (error) {
          handleCatchError(error)
        }
      }
    })
  }

  function disabledDate(current) {
    return current && current > moment()?.endOf('day');
  }

  return (
    <Row className="moderation-wrapper d-flex flex-direction-column height-100" >
      <Col>
        <Row type="flex" className="moderation-filter-wrapper">
          <Col className="ml-auto moderation-select-filter">
            <RangePicker onChange={dateRangeChange} format={dateFormat} disabledDate={disabledDate} />
          </Col>
          <Col>
            <Select allowClear onChange={authorChange} placeholder="Select Author">
              {listAuthor && listAuthor.map((author) => <Option key={author?.details?.id} value={author?.details?.id}>{`${author?.details?.firstName} ${author?.details?.lastName}`}</Option>)}
            </Select>
          </Col>
          <Col>
            <Select allowClear onChange={filterByChange} placeholder="Filter by">
              {FilterByOptions.map((option) => <Option key={option.name} value={option.value}>{option.name}</Option>)}
            </Select>
          </Col>
          <Col>
            <Select allowClear value={filterBy ? sortBy : undefined} onChange={sortByChange} placeholder="Sort by">
              {SortByOptions.map((option) => <Option key={option.name} value={option.value}>{option.name}</Option>)}
            </Select>
          </Col>
          {canMutate && authorPostCanMutate &&
            <Col>
              <Button id="btn-create-live-post" className="go-live-header-btn" onClick={handleCreateGoLive}>Go Live</Button>
            </Col>
          }
        </Row>
      </Col>
      <Divider />
      <Col className="flex-1">
        <Row className="height-100">
          <Col md={8} className="height-100">
            <LivePostList
              dateRangeSearch={dateRangeSearch}
              sortBy={sortBy}
              filterBy={filterBy}
              authorSearch={authorSearch}
              selectPost={selectPost}
              handleShowPostComments={handleShowPostComments}
              setSelectPost={setSelectPost}
              startTime={startTime}
              hasFilterApplied={hasFilterApplied}
            />
          </Col>
          <Col md={16} className="height-100">
            <Row className="height-100">
              <Col className="live-post-wrapper">
                {selectPost && <LiveVideo selectPost={selectPost} commentSearchInputChange={commentSearchInputChange} canMutate={canMutate} authorPostCanMutate={authorPostCanMutate} />}
                {selectPost && <LiveCommentList selectPost={selectPost} selectPostRef={selectPostRef} commentSearchInput={commentSearchInput} canMutate={canMutate} startTime={startTime} commentSearchInputRef={commentSearchInputRef} />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row >
  )
})
