import React, { Component } from 'react'
import Routes from './Routes'
import './App.css'
import "../node_modules/video-react/dist/video-react.css"

export default class App extends Component {
  render() {
    return (
      <div>
        <Routes />
      </div>
    )
  }
}
