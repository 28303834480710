import React, { useState, useEffect, useRef } from 'react'
import { Divider, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { chatClient } from '../../../../apollo'
import { LIST_COMMENTS_MODERATION } from '../graphql/Queries'
import { get, } from 'lodash'
import SideComment from './SideComment'
import { usePusher } from '../../../../pusher'
import { UPDATE_COMMENT_MODERATION } from '../graphql/Mutations'
import { handleRequestFailWithNotification, openNotification } from '../../../../common/utility'
import * as Sentry from '@sentry/browser'
import { uniqBy } from 'lodash'
import Spinner from '../../../../components/loaders/Spinner'

const { confirm } = Modal

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

const ParentComment = (props, ref) => {
  const { id, canMutate } = props
  const pusher = usePusher()
  const [comment, _setComment] = useState(null)
  const [commentLoading, setCommentLoading] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const commentRef = useRef(comment)

  const setComment = (value) => {
    commentRef.current = value
    _setComment(value)
  }

  const {
    data,
    loading: isCommentLoading,
    error: commentError,
  } = useQuery(LIST_COMMENTS_MODERATION, {
    client: chatClient,
    variables: {
      where: { ...(id && { id: id }) },
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (isCommentLoading) {
      setCommentLoading(true)
    }

    if (!isCommentLoading && data?.listCommentsModeration) {
      if (data?.listCommentsModeration?.length > 0) {
        setComment(data?.listCommentsModeration?.[0])
        setCommentLoading(false)
      }
    }

    if (!isCommentLoading && commentError) {
      setCommentLoading(false)
    }

  }, [isCommentLoading])

  const handleShowAction = () => {
    setShowActions(!showActions)
  }

  useEffect(() => {
    if (id) {
      const commentChannel = pusher.subscribe(`COMMENT_MODERATION_EVENT.${id}`);
      commentChannel.bind('UPDATED', commentUpdateHandler);

      return () => {
        commentChannel.unbind('UPDATED', commentUpdateHandler);
        pusher.unsubscribe(`COMMENT_MODERATION_EVENT.${id}`)

      }
    }
  }, [id])

  const commentUpdateHandler = async (data, metaData) => {
    if (data?.comment?.node) {
      let activityLogs = [...commentRef?.current?.activityLogs, ...data?.comment?.node?.activityLogs]
      activityLogs = uniqBy(activityLogs, 'id') //removing log if it is already in the list
      setComment({ ...data?.comment?.node, activityLogs })
    }
  }

  function handleCommentActions(e, comment, fieldName) {
    e.stopPropagation()
    let value
    let title
    let okText
    let okType
    let notificationMessage
    let queryVariables = { where: { id: get(comment, 'id') } }

    switch (fieldName) {
      case 'isHighlighted':
        value = get(comment, 'isHighlighted')
        title = `Are you sure, you want to ${value ? 'unhighlight' : 'highlight'} this comment?`
        okText = value ? 'Unhighlight' : 'Highlight'
        okType = value ? 'danger' : 'primary'
        queryVariables = { ...queryVariables, data: { isHighlighted: !value } }
        notificationMessage = `Comment has been ${value ? 'unhighlighted' : 'highlighted'}`
        break;
      case 'isHidden':
        value = get(comment, 'isHidden')
        title = `Are you sure, you want to ${value ? 'unhide' : 'hide'} this comment?`
        okText = value ? 'Unhide' : 'Hide'
        okType = value ? 'primary' : 'danger'
        queryVariables = { ...queryVariables, data: { isHidden: !value } }
        notificationMessage = `Comment has been ${value ? 'unhidden' : 'hidden'}`
        break;
      case 'isAccepted':
        value = get(comment, 'isAccepted')
        title = `Are you sure, you want to ${value ? 'unaccept' : 'accept'} this comment ? `
        okText = value ? 'Unaccept' : 'Accept'
        okType = value ? 'danger' : 'primary'
        queryVariables = { ...queryVariables, data: { isAccepted: !value } }
        notificationMessage = `Comment has been ${value ? 'accepted' : 'unaccepted'} `
        break;
      default:
        break;
    }

    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          const res = await chatClient.mutate({ mutation: UPDATE_COMMENT_MODERATION, variables: queryVariables })
          openNotification('success', notificationMessage)
        }
        catch (error) {
          handleCatchError(error)
        }
      }

    })
  }

  if (commentLoading || isCommentLoading) return <Spinner />

  if (commentError) return `Error! ${commentError.message}`

  return (comment && <SideComment index={ref ? 'self':'parent'} ref={ref} comment={comment} showActions={showActions} isParentWrapper={true} handleShowAction={handleShowAction} handleCommentActions={handleCommentActions} canMutate={canMutate} />)
}

export default React.forwardRef(ParentComment)