import React, { useState, useEffect } from "react"
import {
  Form,
  Input
} from "antd"

export default function (props) {
  const {
    getFieldDecorator,
    email,
    onGetNewEmail
  } = props
  const [currentEmail, setCurrentEmail] = useState()

  useEffect(() => {
    setCurrentEmail(email)
  })

  function onChangeEmail(e) {
    if (e.target.value !== currentEmail) {
      onGetNewEmail(true)
    } else {
      onGetNewEmail(false)
    }
  }

  return (
    <Form layout="vertical" className="add-show-form">
      <Form.Item label="Email">
        {getFieldDecorator("email", {
          initialValue: email ? email : "",
          rules: [
            {
              type: "email",
              message: "The input is not valid Email!",
            },
            {
              required: true,
              message: "Please input your Email!",
            },
          ],
        })(<Input onChange={onChangeEmail} />)}
      </Form.Item>
    </Form>
  )
}
