import React from 'react'
import { Row, Col, Icon, Divider, Avatar, Button } from 'antd'
import { get, startCase } from 'lodash'
import moment from 'moment'

function UserCard(props) {
  const { person, index, handleEditButton, handleActive, handleResetPassword, canMutate } = props
  return (
    <Row className={`user-card ${!person?.isActive ? 'is-inactive' : ''}`}>
      <Col><Avatar size="large" src={person?.profileImage} className="user-avatar" /></Col>
      <Col>
        <span className="user-name">{person?.firstName}  {person?.lastName}</span>
        <Button disabled type="default" size="small" className="user-role">
          {person?.role && startCase(person.role?.split('_').join(' '))}
        </Button>
      </Col >
      <Col className="d-flex align-items-center user-activity-details">
        <Row>
          <Col>Last Active On</Col>
          <Col className="d-flex justify-content-center status-date">{person?.lastActiveOn ? moment(person.lastActiveOn).tz('America/Los_Angeles').format('MM/DD/YYYY [at] HH:mm') : '-'}</Col>
        </Row>
        <Divider type="vertical" />
        <Row>
          <Col>Last Posted On</Col>
          <Col className="d-flex justify-content-center status-date">{get(person, 'details.lastPostedOn') ? moment(get(person, 'details.lastPostedOn')).tz('America/Los_Angeles').format('MM/DD/YYYY [at] HH:mm') : '-'}</Col>
        </Row>
      </Col>
      <Col className="user-action-details">
        <Row type="flex" justify="space-around" align="middle">
          <Col><Icon type="idcard" /> <span>{get(person, 'details.postCount')}</span> </Col>
          <Divider type="vertical" className="user-action-divider" />
          <Col><Icon type="like" /> <span>{get(person, 'details.likeCount')}</span> </Col>
          <Divider type="vertical" className="user-action-divider" />
          <Col><Icon type="star" /> <span>{get(person, 'details.starCount')}</span> </Col>
          <Divider type="vertical" className="user-action-divider" />
          <Col><Icon type="message" /> <span>{get(person, 'details.commentCount')}</span></Col>
        </Row>
      </Col>
      {
        canMutate &&
        <>
          <Divider />
          <Col>
            <Row type="flex" gutter={8} >
              <Col><Button id={`btn-user-edit-${index}`} onClick={() => handleEditButton(person)}>Edit</Button></Col>
              <Col><Button id={`btn-user-status-${index}`} onClick={() => handleActive(person?.email, person?.isActive)}>{person?.isActive ? 'Deactivate' : 'Activate'}</Button></Col>
              <Col><Button id={`btn-user-reset-password-${index}`} onClick={() => handleResetPassword(person?.authZeroID)}>Reset Password</Button></Col>
            </Row>
          </Col>
        </>
      }
    </Row >
  )

}

export default UserCard