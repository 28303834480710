import React from 'react'
import { Row, Col, Avatar } from 'antd'
import moment from 'moment'
import { truncate } from 'lodash'
import Post from './Post'

function ReferenceDataRender(props) {
  const { type, data } = props
  let image = "https://via.placeholder.com/1024x512.png"
  let title = null
  let creater = null
  let createdAt = null
  let show = null

  if (data) {
    switch (type) {
      case 'VIDEO':
        image = data.image
        title = data.name
        creater = (data?.firstName || data?.lastName) ? `${data?.firstName ?? ''} ${data?.lastName ?? ''}` : null
        createdAt = data.createdAt
        break;

      case 'EPISODE':
        image = data.image
        title = data.title
        creater = (data?.firstName || data?.lastName) ? `${data?.firstName ?? ''} ${data?.lastName ?? ''}` : null
        createdAt = data.createdAt
        show = data?.show?.name
        break;

      case 'NEWS_ARTICLE':
        image = data?.image?.url
        title = data.title
        creater = data?.author?.name
        createdAt = data.date
        break;

      default:
        break;
    }
  }

  if (!data) {
    return ''
  }

  if (type === 'POST') {
    return (
      <Post
        key={data?.id}
        post={data}
        shared={true}
      />
    )
  }

  return (
    <Row>
      <Col>
        <div className="reference-data-container">
          {type === 'POST' &&
            <div className="post-share-details">
              <Row type="flex" gutter={16} >
                <Col>
                  {data?.createdBy && <Avatar src={data?.createdBy?.profileImage ?? ''}>{data?.createdBy?.firstName?.charAt(0) ?? ''}</Avatar>}
                </Col>
                <Col>
                  {creater && <div className="post-user-name" >{creater}</div>}
                  {createdAt && <div className="post-create-time">{moment(createdAt).fromNow(true)} ago</div>}
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="post-text">{title}</p>
                </Col>
              </Row>
            </div>
          }
          <div className="post-page-media-container">
            <img src={image} alt={title} />
          </div>
          <div className="reference-data-details">
            <p className="reference-data-title">{type !== 'POST' && title && truncate(title, { length: 60 })}</p>
            <Row type="flex" gutter={8}>
              {type === 'EPISODE' && <Col><p> {show}</p></Col>}
              {type !== 'POST' && creater && <Col><p>{creater}</p></Col>}
              <Col><p>{type !== 'POST' && createdAt && moment(createdAt).tz('America/Los_Angeles').format('MMMM Do, YYYY')}</p></Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default ReferenceDataRender