import React, { useState } from 'react'
import { Row, Col, Avatar, Button, Icon, Divider, Tooltip, Tag } from 'antd'
import moment from 'moment'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const formateLiveDate = {
  sameDay: '[Today at] hh:mm A [went live]',
  lastDay: '[Yesterday at] hh:mm A [went live]',
  nextDay: 'MMM D,YYYY [at] hh:mm A [PST] [went live]',
  nextWeek: 'MMM D,YYYY [at] hh:mm A [PST] [went live]',
  lastWeek: 'MMM D,YYYY [at] hh:mm A [PST] [went live]',
  sameElse: 'MMM D,YYYY [at] hh:mm A [PST] [went live]',
}

function LivePost(props) {
  const { post, selectPost, handleShowPostComments, index } = props

  return (
    <Row>
      <div
        id={`div-show-live-post-${index}`}
        className={`post  ${(selectPost && selectPost === post.id) ? 'selected-post' : ''}`}
        onClick={() => handleShowPostComments(post.id)}
      >
        <Row type="flex" gutter={8} align="middle">
          <Col className="align-self-flexstart">
            {post?.createdBy && <Avatar size="large" src={post?.createdBy?.profileImage ?? ''}>{post?.createdBy?.firstName?.charAt(0) ?? ''}</Avatar>}
          </Col>
          <Col className="flex-1">
            <Row type="flex" justify="space-between" align="middle">
              {post?.createdBy && <Col className="post-user-name">{post?.createdBy?.firstName ?? ''} {post?.createdBy?.lastName ?? ''}</Col>}
              <Col className="ml-auto live-time align-self-center">
                {post?.video?.status === 'LIVE' && post?.video?.videoState === 'RECORDING' && <Tag color="#FF1F1F" >Live</Tag>}
                {post?.video?.videoState === 'DISCONNECTED' && post?.createdAt &&
                  moment(post?.createdAt).tz('America/Los_Angeles').calendar(null, formateLiveDate)
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider className="post-page-divider" />
        <Row type="flex" gutter={16} className="live-post-action-icon">
          <Col>
            <span><Icon type="message" /> <span>{post?.commentsCount}</span></span>
          </Col>
          <Col>
            <Icon type="eye" /> <span>{post?.viewsCount}</span>
          </Col>
          <Col className="ml-auto">
            <CopyToClipboard text={`${window?.location?.href}/${post?.id}`}>
              <Tooltip title="Copied!" trigger={"click"} onClick={(e)=> e?.stopPropagation()}>
                <span id={`live-post-copylink-${index}`}>
                  <Tooltip title="Click to copy!" placement="bottom">
                    <Icon type="link" /> <span>Copy Link</span>
                  </Tooltip>
                </span>
              </Tooltip>
            </CopyToClipboard>
          </Col>
        </Row>
      </div>
    </Row>
  )
}

export default LivePost