import React from 'react'
import { withRouter } from 'react-router-dom'
import { useAuth0 } from '../modules/auth/Auth0'
import Spinner from '../components/loaders/Spinner'

export default withRouter(function () {
  const { logout } = useAuth0();
  logout()
  return <Spinner />
})
