import React from 'react'
import { Col, Input, Row, Icon } from 'antd'

function Filter(props) {
  const { searchInputChange } = props

  return (
    <Col className="moderation-filter-wrapper">
      <div className="search-input">
        <Input placeholder="Search" prefix={<Icon type='search' />} onChange={searchInputChange} />
      </div>
      {props.children}
    </Col>
  )

}

export default Filter