import React, { useState, useEffect } from 'react'
import { Row, Col, Icon, Switch, Tag, Divider, Input, Modal, Tooltip, Spin } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { chatClient } from '../../../../apollo'
import { GET_POST } from '../graphql/Queries'
import { Player, ControlBar, BigPlayButton } from 'video-react'
import HLSSource from '../../../../HLSSource'
import { get, truncate } from 'lodash'
import { handleRequestFailWithNotification, openNotification, truncateName } from '../../../../common/utility'
import * as Sentry from '@sentry/browser'
import { UPDATE_POST } from '../graphql/Mutations'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { usePusher } from '../../../../pusher'
import Spinner from '../../../../components/loaders/Spinner'

const { confirm } = Modal

function handleCatchError(error) {
  let errorMessage
  if (get(error, 'message')) {
    errorMessage = error.message
  } else if (get(error, 'graphQLErrors[0].message')) {
    errorMessage = error.graphQLErrors[0].message
  } else {
    errorMessage = "Something Went Wrong"
  }
  handleRequestFailWithNotification(errorMessage)
  Sentry.captureException(error)
}

function LiveVideo(props) {
  let image = "https://via.placeholder.com/1024x512.png"
  const { selectPost, commentSearchInputChange, canMutate, authorPostCanMutate } = props
  const [post, _setPost] = useState({})
  const [loadingPost, setLoadingPost] = useState(false)
  const postRef = React.useRef(post)
  const pusher = usePusher()

  const setPost = (value) => {
    postRef.current = value
    _setPost(value)
  }

  const {
    loading,
    data,
    error,
  } = useQuery(GET_POST, {
    variables: {
      id: selectPost
    }, client: chatClient,
    fetchPolicy: 'network-only',
    skip: !selectPost
  })

  useEffect(() => {
    if (loading) {
      setLoadingPost(true)
    }
    if (!loading && get(data, 'getPostModeration')) {
      setPost(data?.getPostModeration)
      setLoadingPost(false)
    }
    if (!loading && error) {
      setLoadingPost(false)
    }
  }, [loading])

  const handleHiddenSwitch = (checked) => {
    let title = `Are you sure, you want to ${checked ? 'hide' : 'unhide'} this live post?`
    let okText = checked ? 'Hide' : 'Unhide'
    let okType = checked ? 'danger' : 'primary'

    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          const res = await chatClient.mutate(
            {
              mutation: UPDATE_POST,
              variables: { data: { isDeleted: checked }, where: { id: selectPost } },
              refetchQueries: [{ query: GET_POST, variables: { id: selectPost }, fetchPolicy: 'network-only' }]
            }
          )
          if (res && get(res, 'data.updatePost')) {
            openNotification('success', `Live Post has been ${checked ? 'hidden' : 'unhidden'}`)
          }
        }
        catch (error) {
          handleCatchError(error)
        }
      }
    })
  }

  useEffect(() => {
    const postChannel = pusher.subscribe(`POST_EVENT.${selectPost}`);
    postChannel.bind('UPDATED', postUpdateHandler);
    return () => {
      postChannel.unbind('UPDATED', postUpdateHandler);
      pusher.unsubscribe(`POST_EVENT.${selectPost}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPost])

  const postUpdateHandler = (data, metaData) => {
    if (get(data, 'post.node')) {
      setPost(data?.post?.node)
    }
  }

  if (loadingPost) return <Spinner />

  if (error) return `Error ${error.message}`

  return (
    <>
      <Row>
        <Col className="live-comments-search-input">
          <Input placeholder="Search" prefix={<Icon type='search' />} onChange={commentSearchInputChange} />
        </Col>
        <Col className="live-post-video-wrapper">
          {
            (get(post, 'video.videoState') === 'RECORDING' || get(post, 'video.videoState') === 'DISCONNECTED') && get(post, 'video.duration') ?
              <Player playsInline fluid={false} width={'100%'} height={350} >
                <BigPlayButton position="center" />
                <ControlBar />
                {get(data, 'getPostModeration.video.videoURL') && (
                  <HLSSource isVideoChild
                    src={get(data, 'getPostModeration.video.videoURL')}
                  />)
                }
              </Player>
              : <div className="d-flex justify-content-center">
                {
                  get(post, 'video.status') === 'LIVE' && get(post, 'video.videoState') !== 'DISCONNECTED'
                    ?
                    <Spin tip="Please Wait...">
                      <img src={image} alt="live-post-video" />
                    </Spin>
                    :
                    <img src={image} alt="live-post-video" />
                }
              </div>
          }
          {get(post, 'video.videoState') === 'RECORDING' && get(post, 'video.duration') && <Tag color="#FF1F1F" className="live-post-video-tag">Live</Tag>}
        </Col>
        <Col className="live-video-action-wrapper">
          <Row type="flex" gutter={16}>
            {canMutate && authorPostCanMutate &&
              <Col>
                <span><Switch id="btn-livepost-hidden" size="small" onChange={handleHiddenSwitch} checked={get(post, 'isDeleted')} /> <Icon type="eye-invisible" /> <span> Hidden</span></span>
              </Col>
            }
            <Col>
              <div>
                RMTP URL
                <CopyToClipboard text={'rtmp://live.mux.com/app'}>
                  <Tooltip title="Copied!" trigger={"click"} onClick={(e) => e?.stopPropagation()}>
                    <span id="livepost-rmtp-copylink">
                      <Tooltip title="Click to copy!" placement="bottom">
                        <Icon type="copy" title="Click to copy!" />
                      </Tooltip>
                    </span>
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <div>rtmp://live.mux.com/app</div>
            </Col>
            <Col>
              <div>
                Video URL
                <CopyToClipboard text={get(post, 'video.videoURL', '')}>
                  <Tooltip title="Copied!" trigger={"click"} onClick={(e) => e?.stopPropagation()}>
                    <span id="livepost-videourl-copylink">
                      <Tooltip title="Click to copy!" placement="bottom">
                        <Icon type="copy" title="Click to copy!" />
                      </Tooltip>
                    </span>
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <div>
                {truncate(get(post, 'video.videoURL', ''), { length: 30 })}
              </div>
            </Col>
            <Col>
              <div>
                Stream Key
                <CopyToClipboard text={get(post, 'video.streamKey', '')}>
                  <Tooltip title="Copied!" trigger={"click"} onClick={(e) => e?.stopPropagation()}>
                    <span id="livepost-streamkey-copylink">
                      <Tooltip title="Click to copy!" placement="bottom">
                        <Icon type="copy" title="Click to copy!" />
                      </Tooltip>
                    </span>
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <div>
                {truncate(get(post, 'video.streamKey', ''), { length: 30 })}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider className="post-page-divider" />
    </>
  )

}

export default LiveVideo