import React, { useState, useContext, useEffect } from 'react'
import { Layout, notification } from 'antd'
import { Switch, Route, Redirect } from 'react-router-dom'
import { find } from 'lodash';
import { GET_CURRENT_USER, GET_CURRENT_PERSON, GET_CURRENT_PERSON_PERMISSION } from './graphql/Queries'
import LayoutHeader from './components/Header'
import LayoutSider from './components/Sider'
import Page404 from '../components/Page404'
import { AppContext } from '../AppContext'
import client, { chatClient } from '../apollo'
import Spinner from '../components/loaders/Spinner'
import './layout.css'
import UserManagement from '../modules/userManagement'
import Moderation from '../modules/moderation'
import { PERMISSION_KEYS, TEMPLATES_NAME } from '../common/constants';

const { Content } = Layout

export default function LayoutWrapper(props) {
  const [collapsed, setCollapsed] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(true)

  const { currentPersonPermission = [] } = state;
  const userPermission = find(currentPersonPermission, ['key', PERMISSION_KEYS.USER_MANAGMENT]);
  const moderationPermission = find(currentPersonPermission, ['key', PERMISSION_KEYS.MODERATION]);

  useEffect(() => {
    client
      .query({
        query: GET_CURRENT_USER,
      })
      .then((data) => {
        dispatch({ type: 'CURRENT_USER', data: data?.data?.currentUser })
        return chatClient.query({
          query: GET_CURRENT_PERSON,
        })
      })
      .then((data) => {
        dispatch({ type: 'CURRENT_PERSON', data: data?.data?.currentPerson })
        return chatClient.query({
          query: GET_CURRENT_PERSON_PERMISSION,
        })
      }).then((data) => {
        setIsLoading(false)
        dispatch({ type: 'CURRENT_PERSON_PERMISSION', data: data?.data?.getCurrentPersonPermission })
        redirectOnLogin(data?.data?.getCurrentPersonPermission)
      })
      .catch((error) => {
        setIsLoading(false)
        notification['error']({
          message: error.message,
        })
      })
  }, [dispatch])
  function authorChange(value) {
    console.log(`selected ${value}`)
  }

  function redirectOnLogin(permission) {   // This will redirect to first path from user has permission   
    if (pathname === '/') {    // only redirect when login
      const userPermission = find(permission, ['key', PERMISSION_KEYS.USER_MANAGMENT])
      const moderationPermission = find(permission, ['key', PERMISSION_KEYS.MODERATION])

      if (userPermission && userPermission?.canRead) {
        history.push('/user-management')
      }
      else if (moderationPermission && moderationPermission?.canRead) {
        history.push('/moderation')
      } else {
        history.push('/user-management')
      }
    }
  }

  const {
    location: { pathname }, history
  } = props
  const role = state?.currentUser?.role
  return isLoading ? (
    <div className="callback-wrapper">
      <Spinner />
    </div>
  ) : (
    state && state.currentUser && (
      <Layout>
        <LayoutSider
          collapsed={collapsed}
          pathname={pathname}
          role={state?.currentUser?.role}
          toggle={() => setCollapsed(!collapsed)}
        />
        <Layout>
          <LayoutHeader
            authorChange={authorChange}
            collapsed={collapsed}
            pathname={pathname}
            toggle={() => setCollapsed(!collapsed)}
          />
          <Content className="content-wrapper">
            <Switch>
                {role && role in TEMPLATES_NAME && userPermission?.canRead && (
                  <Route
                    exact
                    path="/user-management"
                    component={UserManagement}
                  />
                )}
              {
                role && role in TEMPLATES_NAME && moderationPermission?.canRead &&
                <Route path="/moderation" component={Moderation} />
              }
              <Route component={Page404} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    )
  )
}
